import { Button, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getTeamId, joinTeamAccept, joinTeamReject } from '../../../../store/actions/team/teamActions';

const Joiners = ({ teamId, joiners }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const teamJoinAcceptHandler = async (id) => {
    const response = await dispatch(joinTeamAccept(id));
    if (response) {
      dispatch(getTeamId(teamId));
    }
  };
  const teamJoinRejectHandler = async (id) => {
    const response = await dispatch(joinTeamReject(id));
    if (response) {
      dispatch(getTeamId(teamId));
    }
  };
  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '220px',
      render: (_, record) => (
        <div>
          {record.firstName} {record.lastName}
        </div>
      ),
    },
    {
      title: t('tableColumns.status'),
      dataIndex: 'Status',
      key: 'Status',
      width: '220px',
      render: (_, record) => <div>Joiner</div>,
    },
    {
      title: t('tableColumns.actions'),
      dataIndex: 'Actions',
      key: 'Actions',
      render: (_, record) => (
        <div className="circle-table__actions">
          <Button className="approve-btn" onClick={() => teamJoinAcceptHandler(record.id)}>
            {t('buttons.approve')}
          </Button>
          <Button className="reject-btn" onClick={() => teamJoinRejectHandler(record.id)}>
            {t('buttons.reject')}
          </Button>
        </div>
      ),
    },
  ];
  return (
    <>
      <Table columns={columns} dataSource={joiners} rowKey={'stravaId'} />
    </>
  );
};

export default Joiners;
