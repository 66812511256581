import { Button, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Invites = ({ invites }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '220px',
      render: (_, record) => (
        <div>
          {record?.user?.firstName} {record?.user?.lastName}
        </div>
      ),
    },
    {
      title: t('tableColumns.status'),
      dataIndex: 'Status',
      key: 'Status',
      width: '220px',
      render: (_, record) => <div>Invites</div>,
    },
  ];
  return (
    <>
      <Table columns={columns} dataSource={invites} rowKey={'stravaId'} />{' '}
    </>
  );
};

export default Invites;
