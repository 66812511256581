import React, { useContext, useRef, useState } from 'react';
import { ReactComponent as Logo } from '../../../assets/logor.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from '../../ChangeLanguage/ChangeLanguage';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../../../appContext';
import { Avatar, Popover } from 'antd';
import { LogoutOutlined, DownOutlined, QuestionCircleOutlined, TrophyOutlined, UserOutlined } from '@ant-design/icons';
import { logoutUser } from '../../../store/actions/user/userActions';
import { useOnClickOutside } from '../../../utils/hooks/useOnClickOutside';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import './styles.scss';

const Navbar = () => {
  const ref = useRef();
  const width = useResizeWindows();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, isAdmin, isRaceOrganazier } = useContext(AppContext);
  const { t } = useTranslation();
  const user = useSelector((state) => state.user?.profile?.profile);

  const [opened, setOpened] = useState(false);
  useOnClickOutside(ref, () => setOpened(false));
  const url = process.env.REACT_APP_REDIRECT_URL;
  const key = process.env.REACT_APP_STRAVA_KEY;

  const handleLogin = () => {
    window.location = `http://www.strava.com/oauth/authorize?client_id=${key}&response_type=code&redirect_uri=${url}/exchange_token&approval_prompt=auto&scope=read,activity:read_all,activity:write`;
  };
  const logoutHandler = () => {
    dispatch(logoutUser());
    navigate('/');
  };

  const userContent = () => {
    return (
      <div className="userContent" style={{ width: '150px' }}>
        <h4 className="">
          {user.given_name} {user.family_name}
        </h4>

        <div className="lala">
          <TrophyOutlined className="m-r-10" />

          <Link className="link-btn profile-btn" to="/my-team">
            {t('myTeam')}
          </Link>
        </div>

        <div className="lala">
          <UserOutlined className="m-r-10" />

          <Link className="link-btn profile-btn" to="/profile">
            {t('profile')}
          </Link>
        </div>

        <div className="lala">
          <QuestionCircleOutlined className="m-r-10" />
          <Link className="link-btn profile-btn" to="/about">
            {t('navigation.aboutUs')}
          </Link>
        </div>

        <div className="lala">
          <LogoutOutlined className="m-r-10" />
          <button className="link-btn profile-btn" onClick={logoutHandler}>
            {t('logout')}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="navigation">
      {isLoggedIn && (
        <div className="hamburger-menu" ref={ref}>
          <input
            id="menu__toggle"
            type="checkbox"
            onChange={() => setOpened((prev) => !prev)}
            value={opened}
            checked={opened}
          />
          <label className="menu__btn" htmlFor="menu__toggle">
            {!opened ? (
              <svg
                width="35"
                height="35"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#222222"
                strokeWidth="2"
                strokeLinecap="butt"
                strokeLinejoin="arcs"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            ) : (
              <svg
                width="35"
                height="35"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="butt"
                strokeLinejoin="arcs"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            )}
          </label>

          <ul className="menu__box">
            {width < 451 && (
              <div className="burger__logo">
                <Link to="/">
                  <Logo width={'60%'} style={{ marginLeft: '55px' }} />
                </Link>
              </div>
            )}
            <li>
              <Link to="/cups" className="link-items" onClick={() => setOpened(false)}>
                {t('navigation.cups')}
              </Link>
            </li>
            <li>
              <Link to="/races" className="link-items" onClick={() => setOpened(false)}>
                {t('navigation.races')}
              </Link>
            </li>
            <li>
              <Link to="/teams" className="link-items" onClick={() => setOpened(false)}>
                {t('navigation.teams')}
              </Link>
            </li>
            <li>
              <Link to="/circles" className="link-items" onClick={() => setOpened(false)}>
                {t('navigation.circles')}
              </Link>
            </li>
            {(isAdmin || isRaceOrganazier) && (
              <li>
                <Link to="/manage/cups" className="link-items" onClick={() => setOpened(false)}>
                  {t('navigation.manageCups')}
                </Link>
              </li>
            )}
            {(isAdmin || isRaceOrganazier) && (
              <li>
                <Link to="/manage/races" className="link-items" onClick={() => setOpened(false)}>
                  {t('navigation.manageRaces')}
                </Link>
              </li>
            )}

            {isAdmin && (
              <li>
                <Link to="/manage/users" className="link-items" onClick={() => setOpened(false)}>
                  {t('navigation.manageUsers')}
                </Link>
              </li>
            )}
            {(isAdmin || isRaceOrganazier) && (
              <li>
                <Link to="/manage/circles" className="link-items" onClick={() => setOpened(false)}>
                  {t('navigation.manageCircle')}
                </Link>
              </li>
            )}
            {/* {(isAdmin || isRaceOrganazier) && (
              <li>
                <Link to="/circles-map" className="link-items" onClick={() => setOpened(false)}>
                  Circles Map
                </Link>
              </li>
            )} */}
          </ul>
        </div>
      )}

      {width >= 451 && (
        <Link to="/">
          <Logo width={'75%'} style={{ marginTop: '7px' }} />
        </Link>
      )}

      <div className="login-item">
        {isLoggedIn ? (
          <div className="show-popover">
            <Avatar src={user.avatar} />
            <Popover content={userContent} trigger="click" placement="bottom">
              <DownOutlined style={{ fontSize: '16px' }} />
            </Popover>
          </div>
        ) : (
          <button className="link-btn" onClick={handleLogin}>
            {t('login')}
          </button>
        )}

        <div className="changelang-item">
          <ChangeLanguage />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
