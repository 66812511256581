import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCircles } from '../../../store/actions/circle/circleActions';
import '../styles.scss';
import { createCup } from '../../../store/actions/cup/cupActions';
import moment from 'moment';
const { TextArea } = Input;
const { Option } = Select;

const CreateCup = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [close, setClose] = useState('');
  const [cupName, setCupName] = useState(null);
  const [description, setDescription] = useState(null);
  const [circleId, setCircleId] = useState(null);
  const [teamMembersForRanking, setTeamMembersForRanking] = useState(null);
  const [isPublished, setIsPublished] = useState(false);

  const circles = useSelector((state) => state.circle?.managedCircles?.items);
  const circlesLoading = useSelector((state) => state.circle.managedCirclesLoading);

  const createCupHandler = async () => {
    const response = await dispatch(
      createCup({
        name: cupName,
        description,
        circleId,
        isPublished,
        teamMembersForRanking,
        defaultStartDate: start,
        defaultEndDate: end,
        defaultCloseDate: close,
      })
    );

    if (response) {
      setDescription(null);
      setCupName(null);
      setCircleId(null);
      form.resetFields(['cupName']);
      form.resetFields(['description']);
    }
  };

  useEffect(() => {
    dispatch(getCircles(true));
  }, [dispatch]);
  return (
    <div className="content">
      <div className="addrace-form">
        <h2 className="title">{t('buttons.createCup')}</h2>

        <Form onFinish={createCupHandler}>
          <div className="main-information">
            <div className="race-name__block">
              <Form.Item
                name="cupName"
                className="m-b-15"
                rules={[
                  {
                    required: true,
                    message: t('errors.inputCupName'),
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={t('cupName')}
                  value={cupName}
                  onChange={(e) => setCupName(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="race-name__block">
              <Form.Item
                name="description"
                className="m-b-15"
                rules={[
                  {
                    required: true,
                    message: t('errors.inputDescription'),
                  },
                ]}
              >
                <TextArea
                  size="large"
                  placeholder={t('Description')}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={4}
                />
              </Form.Item>
            </div>
            <div className="first-row cup-row__wrapper">
              <InputNumber
                className="strava-input route-input"
                placeholder={t('buttons.teamRanking')}
                style={{ width: '33%' }}
                size="large"
                value={teamMembersForRanking}
                onChange={(value) => setTeamMembersForRanking(value)}
                min={0}
                max={100}
              />
              <Checkbox onChange={() => setIsPublished((prev) => !prev)} value={isPublished} checked={isPublished}>
                Publish cup
              </Checkbox>
              <div>
                <Select
                  allowClear
                  style={{ width: '250px', height: '43px !important' }}
                  onChange={(e) => setCircleId(e)}
                  placeholder={t('filterRace.circle')}
                  size="large"
                  value={circleId}
                >
                  {circles?.map((elem) => (
                    <>{!circlesLoading && <Option key={elem.id}>{elem.name}</Option>}</>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="first-row m-b-20">
            <DatePicker
              size="large"
              format="YYYY-MM-DD"
              placeholder="start activity date"
              defaultValue={null}
              value={start !== '' ? moment(start) : ''}
              onChange={(date, dateString) => setStart(dateString)}
              style={{ width: '33%' }}
            />
            <DatePicker
              size="large"
              format="YYYY-MM-DD"
              placeholder="end activity date"
              defaultValue={null}
              value={end !== '' ? moment(end) : ''}
              onChange={(date, dateString) => setEnd(dateString)}
              style={{ width: '33%' }}
            />
            <DatePicker
              size="large"
              format="YYYY-MM-DD"
              placeholder="close activity date"
              defaultValue={null}
              value={close !== '' ? moment(close) : ''}
              onChange={(date, dateString) => setClose(dateString)}
              style={{ width: '33%' }}
            />
          </div>
          <div className="addrace-footer">
            <Button type="primary" htmlType="submit" size="large">
              {t('buttons.createCup')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateCup;
