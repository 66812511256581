import Map, { Layer, Source, Marker, Popup } from 'react-map-gl';
import { Spin, Collapse } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import CupRacesTab from '../../../components/Tabs/CupRacesTab';
import CupTeamsTab from '../../../components/Tabs/CupTeamsTab';
import CupUsersTab from '../../../components/Tabs/CupUsersTab';
import { calculateCup, getCupByID, registerOnCup, unregisterOnCup } from '../../../store/actions/cup/cupActions';
import { daysRemaining } from '../../../utils/functions';
import { ReactComponent as TimeIC } from '../../../assets/clock-ic.svg';
import { AppContext } from '../../../appContext';
import polyline from '@mapbox/polyline';
import mapboxgl from 'mapbox-gl';
import LocaleDate from '../../../components/LocaleDate/LocaleDate';
import PinsMarker from '../../MapForCircles/PinsMarker';
import './styles.scss';
const { Panel } = Collapse;

const CupDetails = () => {
  const mapRef = React.createRef();
  const { isAdmin } = useContext(AppContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const cupById = useSelector((state) => state.cup?.cupByID);
  const loading = useSelector((state) => state.cup?.cupByIDLoading);
  const [canRegister, setCanRegister] = useState(null);
  const [canUnregister, setCanUnregister] = useState(null);
  const [registerRequestLoading, setRegisterRequestLoading] = useState(false);
  const today = moment(new Date()).format('YYYY-MM-DD');
  const end = moment(cupById?.endDate).format('YYYY-MM-DD');
  // data for map
  const [filteredRaceSegments, setFilteredRaceSegments] = useState([]);
  const [filteredRaceRoutes, setFilteredRaceRoutes] = useState([]);
  const [cupRaces, setCupRaces] = useState([]);
  const [segmentCoords, setSegmentCoords] = useState([]);
  const [routeCoords, setRouteCoords] = useState([]);
  const [initialFitBounds, setInitialFitBounds] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [markerCoords, setMarkerCoords] = useState(null);

  const routeCoordsObject = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: routeCoords,
        },
      },
    ],
  };
  // data for map

  const decodeMarkerCoords = useCallback(() => {
    let tmp;

    tmp = filteredRaceSegments.map((item) => {
      let { coordinates } = polyline.toGeoJSON(item.raceSegments[0].segment.polyline);
      let obj = {
        ...item,
        longitude: coordinates[0][0],
        latitude: coordinates[0][1],
      };
      return obj;
    });

    return tmp;
  }, [filteredRaceSegments]);

  useEffect(() => {
    const info = decodeMarkerCoords();
    if (info) setMarkerCoords(info);
  }, [decodeMarkerCoords]);

  // pins
  const pins = useMemo(() => {
    return markerCoords?.map((item, idx) => {
      return (
        <>
          {item && (
            <>
              <Marker
                style={{ cursor: 'pointer' }}
                longitude={item?.longitude}
                latitude={item?.latitude}
                onClick={(e) => {
                  e.originalEvent.stopPropagation();
                  setPopupInfo(item);
                }}
              >
                <PinsMarker />
              </Marker>
            </>
          )}
        </>
      );
    });
  }, [[markerCoords]]);
  // pins

  const registeronCupHandler = async () => {
    setRegisterRequestLoading(true);
    const res = await dispatch(registerOnCup(id));
    if (res) {
      setRegisterRequestLoading(false);
      setCanRegister((prev) => !prev);
      setCanUnregister((prev) => !prev);
    }
  };
  const unregisteronCupHandler = async () => {
    setRegisterRequestLoading(true);
    const res = await dispatch(unregisterOnCup(id));
    if (res) {
      setRegisterRequestLoading(false);
      setCanRegister((prev) => !prev);
      setCanUnregister((prev) => !prev);
    }
  };

  const calculateCupHandler = async () => {
    const response = await dispatch(calculateCup(id));
    if (response) dispatch(getCupByID(id));
  };

  // functions for map

  const handleMapLoad = () => {
    const bounds = new mapboxgl.LngLatBounds();
    const mergedArray = routeCoords.concat(initialFitBounds?.features[0].geometry.coordinates);
    mergedArray.forEach((coord) => {
      bounds.extend(coord);
    });

    mapRef.current.fitBounds(bounds, {
      padding: 100,
      maxZoom: 14,
    });
  };

  const decodePolyLineSegments = useCallback(() => {
    let tmp = [];

    for (let i = 0; i < filteredRaceSegments.length; i++) {
      filteredRaceSegments[i].raceSegments.map((item) => {
        let { coordinates } = polyline.toGeoJSON(item.segment.polyline);
        tmp.push(coordinates);
      });
    }
    return tmp;
  }, [filteredRaceSegments]);

  const decodePolyLineRoutes = useCallback(() => {
    let tmp;
    for (let i = 0; i < filteredRaceRoutes.length; i++) {
      tmp = filteredRaceRoutes[i].raceRoutes.map((item) => {
        let { coordinates } = polyline.toGeoJSON(item.route.polyline);
        return coordinates;
      });
    }

    return tmp;
  }, [filteredRaceRoutes]);

  useEffect(() => {
    const info = decodePolyLineSegments();

    if (info) {
      let obj = info.map((item) => {
        return {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: item,
              },
            },
          ],
        };
      });
      const merged = _.flatten(info);
      setInitialFitBounds({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: merged,
            },
          },
        ],
      });
      setSegmentCoords(obj);
    }
  }, [decodePolyLineSegments]);

  useEffect(() => {
    const info = decodePolyLineRoutes();
    if (info) {
      const merged = _.flatten(info);
      setRouteCoords(merged);
    }
  }, [decodePolyLineRoutes]);

  // functions for map

  //  useEffects
  useEffect(() => {
    if (cupById) {
      setCanRegister(cupById?.allowToRegister);
      setCanUnregister(cupById?.allowToUnregister);
    }
  }, [cupById]);

  useEffect(() => {
    dispatch(getCupByID(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (cupById?.races) setCupRaces(cupById?.races);
  }, [cupById?.races]);

  useEffect(() => {
    let raceSegments = cupRaces.filter((item) => item.raceSegments.length > 0);
    setFilteredRaceSegments(raceSegments);
    let raceRoutes = cupRaces.filter((item) => item.raceRoutes.length > 0);
    setFilteredRaceRoutes(raceRoutes);
  }, [cupRaces]);

  //  useEffects

  return (
    <div className="content">
      <Spin spinning={loading || registerRequestLoading} size="large">
        <div className="cupDetails__container">
          {!loading && cupById && (
            <>
              <h1 className="title edit-text__title">{cupById?.name}</h1>
              <div className="locale-date__container m-b-30">
                {cupById?.startDate && !cupById?.endDate ? (
                  <LocaleDate date={new Date(cupById?.startDate)} />
                ) : (
                  <>
                    {cupById?.endDate && (
                      <>
                        <LocaleDate date={new Date(cupById?.startDate)} /> <div style={{ fontSize: '15px' }}>/</div>
                        <LocaleDate date={new Date(cupById?.endDate)} />
                      </>
                    )}
                  </>
                )}
              </div>

              {cupById?.endDate && (
                <div className="date__remaining m-b-15">
                  {today > end ? (
                    <p className="days">{t('finishedRace')}</p>
                  ) : (
                    <div className="hours">
                      <TimeIC className="time-IC" />
                      <p>
                        {t('remaining')} {daysRemaining(new Date(cupById?.endDate), t('days'), t('hours'))}
                      </p>
                    </div>
                  )}
                </div>
              )}

              <div className="object-details__buttons">
                {canRegister && (
                  <button onClick={registeronCupHandler} className="btn race-register__btn">
                    {t('buttons.registerCupRace')}
                  </button>
                )}
                {canUnregister && (
                  <button onClick={unregisteronCupHandler} className="btn race-unregistern__btn">
                    {t('buttons.unregisterCupRace')}
                  </button>
                )}

                {isAdmin && (
                  <button className="btn calculate-button" onClick={calculateCupHandler}>
                    calculate cup
                  </button>
                )}
              </div>

              <div className="race-oncircle__info m-b-30">
                <div className="detail-race-info m-b-10">
                  <div className="table-element">
                    <div className="table__item">
                      <h3>Segments</h3>
                      <span>{cupById?.races.length}</span>
                    </div>
                    <div className="table__item">
                      <h3>{t('tableColumns.numberOfRacers')}</h3>
                      <span>{cupById?.cupUsers.length}</span>
                    </div>
                  </div>
                </div>
                <div className="detail-race-border m-b-30"></div>
              </div>
              {cupById?.circle && (
                <div className="race-oncircle__info m-b-30">
                  <div className="table-element">
                    <div className="table__item" style={{ width: '100%' }}>
                      <h3 style={{ fontSize: '22px' }}>{t('circleName')}</h3>
                      <Link to={`/details/circle/${cupById.circle.id}`}>
                        <span>{cupById.circle.name}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ width: '100%', margin: '0 auto' }}>
                {cupById?.races.length > 0 && (
                  // img was here
                  <div className="map__container">
                    <Map
                      onLoad={handleMapLoad}
                      mapboxAccessToken="pk.eyJ1IjoiY3ljbG9jY2lubyIsImEiOiJjbGI0cWhjbHQwY3YwM3Zxc2EyNjA5OTJnIn0.5RM8MEXQQLl6iNTpSeqokg"
                      style={{
                        width: '100%',
                        height: 'inherit',
                        borderRadius: '15px',
                        border: '2px solid #ccc',
                      }}
                      initialViewState={{
                        latitude: 45.14676,
                        longitude: 5.76512,
                        zoom: 7,
                      }}
                      mapStyle="mapbox://styles/mapbox/outdoors-v12"
                      ref={mapRef}
                    >
                      {pins}
                      {popupInfo && (
                        <Popup
                          anchor="top"
                          longitude={Number(popupInfo?.longitude)}
                          latitude={Number(popupInfo?.latitude)}
                          onClose={() => setPopupInfo(null)}
                        >
                          <div className="popup__marker">
                            <p className="popup__title">{popupInfo?.name}</p>
                            <p className="popup__subinfo">
                              {t('tableColumns.distance')}: <>{Number(popupInfo?.distance / 1000).toFixed(2)} km</>
                            </p>
                            <p className="popup__subinfo">
                              {t('tableColumns.elevation')}: <>{Number(popupInfo?.elevation).toFixed(2)} m</>
                            </p>
                            <Link to={`/details/race/${popupInfo?.id}`} className="circle-details details-btn">
                              {t('buttons.details')}
                            </Link>
                          </div>
                        </Popup>
                      )}
                      <Source id="route" type="geojson" data={routeCoordsObject}>
                        <Layer
                          id="route-layer"
                          type="line"
                          paint={{
                            'line-color': '#FF0000',
                            'line-width': 4,
                            'line-opacity': 0.6,
                          }}
                        />
                      </Source>

                      {segmentCoords && (
                        <>
                          {segmentCoords.map((item, idx) => {
                            return (
                              <Source id={`segments${idx + 1}`} type="geojson" data={item}>
                                <Layer
                                  id={`segments-layer${idx + 1}`}
                                  type="line"
                                  paint={{
                                    'line-color': '#FB6BC7',
                                    'line-width': 5,
                                    'line-opacity': 1,
                                  }}
                                />
                              </Source>
                            );
                          })}
                        </>
                      )}
                    </Map>
                  </div>
                )}
                <h2 className="title m-b-15 m-t-30">{t('titles.raceResult')}</h2>
                <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
                  <Panel header={t('titles.cupUsersTab')} key={1}>
                    {!_.isEmpty(cupById?.cupUsers) ? (
                      <CupUsersTab users={cupById?.cupUsers} isAllowed={false} details={cupById} />
                    ) : (
                      <div className="no-users__result">{t('noUsersOnCup')}</div>
                    )}
                  </Panel>
                </Collapse>
                <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
                  <Panel header={t('titles.cupRacesTab')} key={1}>
                    {!_.isEmpty(cupById?.races) ? (
                      <CupRacesTab races={cupById?.races} />
                    ) : (
                      <div className="no-users__result">{t('noRacesOnCup')}</div>
                    )}
                  </Panel>
                </Collapse>
                <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
                  <Panel header={t('navigation.teams')} key={1}>
                    {!_.isEmpty(cupById?.cupTeams) ? (
                      <CupTeamsTab teams={cupById?.cupTeams} data={cupById?.cupUsers} />
                    ) : (
                      <div className="no-users__result">{t('noTeamsOnCup')}</div>
                    )}
                  </Panel>
                </Collapse>
              </div>
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default CupDetails;
