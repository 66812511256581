import {
  GET_ACTIVITY_FAILURE,
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
  GET_AVAILABLE_RACES_FAILURE,
  GET_AVAILABLE_RACES_REQUEST,
  GET_AVAILABLE_RACES_SUCCESS,
} from '../actions/availableRaces/actionTypes';

const initialState = {
  availableRaces: [],
  activity: [],
  activityLoading: false,
  availableRacesLoading: false,
  pages: 1,
};

const racesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AVAILABLE_RACES_REQUEST:
      return { ...state, availableRacesLoading: true };
    case GET_AVAILABLE_RACES_SUCCESS:
      return { ...state, availableRacesLoading: false, availableRaces: action.availableRaces };
    case GET_AVAILABLE_RACES_FAILURE:
      return { ...state, availableRacesLoading: false };
    //
    case GET_ACTIVITY_FAILURE:
      return { ...state, activityLoading: true };
    case GET_ACTIVITY_REQUEST:
      return { ...state, activityLoading: false, activity: action.activity };
    case GET_ACTIVITY_SUCCESS:
      return { ...state, activityLoading: false };
    default:
      return { ...state };
  }
};
export default racesReducer;
