import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const TeamOnCupInfo = ({ teamId, data }) => {
  const { t } = useTranslation();

  const [pointsByUser, setPointsByUser] = useState(null);
  // const calculatePointsByUser = useCallback(() => {
  //   const arr = [];
  //   for (let i = 0; i < usersFromCup.length; i++) {
  //     usersFromCup[i].raceUsers.filter((item) => {
  //       if (item.activity !== null && item.user.currentTeam !== null) {
  //         if (item.user.currentTeam.id === teamId) {
  //           let name = item.user.firstName + item.user.lastName;
  //           const findedUser = {
  //             name,
  //             points: item.points,
  //           };
  //           arr.push(findedUser);
  //         }
  //       }
  //     });

  //     const uniqueElements = array.filter((v, i, a) => {
  //       return a.findIndex((v2) => v2.name === v.name) === i;
  //     });

  //     const newArray = uniqueElements.map((uItem) => {
  //       array.forEach((item) => {
  //         if (uItem.name === item.name) {
  //           uItem.points += item.points;
  //         }
  //       });

  //       return uItem;
  //     });
  //   }
  //   return arr;
  // }, [usersFromCup, teamId]);

  const calculatePointsByUser = useCallback(() => {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].user.currentTeam !== null && data[i].user.currentTeam.id === teamId) {
        const name = `${data[i].user.firstName.trim()} ${data[i].user.lastName}`;
        const findedUser = {
          name,
          points: data[i].totalPoints,
        };
        arr.push(findedUser);
      }
    }

    return arr;
  }, [data, teamId]);

  useEffect(() => {
    const info = calculatePointsByUser();

    if (info) {
      setPointsByUser(_.sortBy(info, 'points').reverse());
    }
  }, [calculatePointsByUser]);

  return (
    <>
      {pointsByUser?.length && (
        <div>
          <div className="cup-points__container cup-points__container-header">
            <p>{t('tableColumns.name')}</p>
            <p>Points</p>
          </div>
          {pointsByUser.map((item) => {
            return (
              <div className="cup-points__container">
                <p>{item.name}</p>
                <p>{item.points}</p>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default TeamOnCupInfo;
