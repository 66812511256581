import axios from 'axios';

export const cleanUpAuthToken = (str) => {
  return str.split('&')[1].slice(5);
};

export const stravaAuthGetter = async (authTok) => {
  const REACT_APP_CLIENT_SECRET = '3a4ddcdcdbff6226d00586cdccd7ba4f39ad7aa9';
  try {
    const response = await axios.post(
      `https://www.strava.com/api/v3/oauth/token?client_id=93423&client_secret=${REACT_APP_CLIENT_SECRET}&code=${authTok}&grant_type=authorization_code`
    );
    return response.data;
  } catch (error) {
    //
  }
};

// export const daysRemaining = (endDate) => {
//   let eventdate, todaysdate;
//   if (endDate) {
//     eventdate = moment(endDate);
//     todaysdate = moment();
//     return eventdate.diff(todaysdate, 'days');
//   }
//   return;
// };

export const daysRemaining = (date, daysText, hoursText) => {
  const now = new Date();
  const delta = date - now;
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
  if (delta > oneDay) {
    const days = Math.floor(delta / oneDay);
    return `${days} ${daysText}`;
  } else {
    const hours = Math.floor(delta / (60 * 60 * 1000));
    const minutes = Math.floor((delta / (60 * 1000)) % 60);
    if (minutes < 10) {
      return `${hours}:0${minutes} ${hoursText}`;
    } else {
      return `${hours}:${minutes} ${hoursText}`;
    }
  }
};
