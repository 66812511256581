import { Button, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppContext } from '../../appContext';
import { getTeams } from '../../store/actions/team/teamActions';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import TeamItem from '../../components/TeamItem/TeamItem';

const Teams = () => {
  const { isAdmin, isRaceOrganazier } = useContext(AppContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const teams = useSelector((state) => state.team?.teams);
  const loading = useSelector((state) => state.team?.teamsLoading);
  const userId = useSelector((state) => state?.user?.profile?.profile?.nameid);

  useEffect(() => {
    dispatch(getTeams());
  }, [dispatch]);

  return (
    <div className="content">
      <h2 className="title">{t('navigation.teams')}</h2>
      {(isAdmin || isRaceOrganazier) && (
        <Button>
          <Link to="/team/new">{t('buttons.createTeam')}</Link>
        </Button>
      )}
      <Spin spinning={loading}>
        <div className="teams__container m-t-50">
          {teams && (
            <>
              {teams.map((item) => {
                return (
                  <TeamItem
                    name={item?.name}
                    shortName={item?.shortName}
                    key={item?.id}
                    id={item?.id}
                    membersCount={item?.membersCount}
                    allowJoin={item?.allowToJoin}
                    allowLeave={item?.allowToLeave}
                    firstName={item.owner?.firstName}
                    lastName={item.owner?.lastName}
                    allowtoInvite={item?.allowToAcceptInvite}
                    showEditGroup={isAdmin || userId === item?.owner?.id}
                  />
                );
              })}
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default Teams;
