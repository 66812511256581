import { Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersForInvite, teamInvite } from '../../store/actions/team/teamActions';
const { Option } = Select;
const InviteUsers = () => {
  const dispatch = useDispatch();
  const [invitedUserId, setInvitedUserId] = useState(null);
  const usersForInvite = useSelector((state) => state.team?.usersInvited);
  const loading = useSelector((state) => state.team?.usersInvitedLoading);

  const inviteUserHandler = async () => {
    const response = await dispatch(teamInvite(invitedUserId));
    if (response) setInvitedUserId(null);
  };

  useEffect(() => {
    dispatch(getUsersForInvite());
  }, [dispatch]);

  return (
    <div className="m-t-40 invite-user__container">
      <h2 className="title m-b-40">Invite Users</h2>
      {/* <Input placeholder="User Id" value={invitedUserId} onChange={(e) => setInvitedUserId(e.target.value)} /> */}
      <Select
        allowClear
        style={{ width: '100%', height: '43px !important' }}
        onChange={(e) => setInvitedUserId(e)}
        placeholder="Circle"
        size="large"
        value={invitedUserId}
      >
        {usersForInvite?.map((elem) => (
          <>
            {!loading && (
              <Option key={elem.id}>
                {elem.firstName} {elem.lastName}
              </Option>
            )}
          </>
        ))}
      </Select>
      <div className="m-t-10 invite-block">
        <Button onClick={inviteUserHandler}>Send invite</Button>
      </div>
    </div>
  );
};

export default InviteUsers;
