import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
const UserCupsTab = ({ myCups }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '50%',
      render: (_, record) => <Link to={`/details/cup/${record.id}`}>{record?.name}</Link>,
    },
    {
      title: t('tableColumns.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        const today = moment(new Date()).format('YYYY-MM-DD');
        const end = moment(record?.endDate).format('YYYY-MM-DD');
        return <>{today > end ? <>{t('titles.finished')}</> : <>{t('titles.inProgress')}</>}</>;
      },
    },
    {
      title: t('tableColumns.rank'),
      dataIndex: 'rank',
      key: 'rank',
      render: (_, record) => <>{record?.rank}</>,
    },
  ];
  return (
    <>
      <Table dataSource={_.sortBy(myCups, 'rank')} columns={columns} />;
    </>
  );
};

export default UserCupsTab;
