import { Button, DatePicker, Input, Modal, Table, Select, Checkbox, InputNumber } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewStravaSegment,
  editRace,
  getActivity,
  getSingleRace,
  getSingleRoute,
} from '../../../store/actions/manageRaces/manageRaces';
import { showNotification } from '../../../utils/showNotification';
import { DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';
import EditActivityItem from './EditActivityItem';
import './styles.scss';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import StravaLink from '../../../components/StravaLink/StravaLink';
import { getCircles } from '../../../store/actions/circle/circleActions';
import { useTranslation } from 'react-i18next';
import { getAllCups } from '../../../store/actions/cup/cupActions';
const { Option } = Select;

const EditRace = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [stravaId, setStravaId] = useState(null);

  const [name, setName] = useState(null);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [close, setClose] = useState(new Date());
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [circleId, setCircleId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [activityId, setActivityId] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [cupId, setCupId] = useState(null);
  const [coefficient, setCoefficient] = useState(null);
  const [bonusPoint, setBonusPoint] = useState(null);
  const [bonusWinner, setBonusWinner] = useState(null);
  const [segments, setSegments] = useState([]);
  const [segmentsForRequest, setSegmentsForRequest] = useState([]);
  //
  const [routeId, setRouteId] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [defaultRouteStravaId, setDefaultRouteStravaId] = useState(null);
  const [raceType, setRaceType] = useState(null);
  //
  const singleRoute = useSelector((state) => state.managedRaces?.singleRoute);
  const circles = useSelector((state) => state.circle?.managedCircles?.items);
  const circlesLoading = useSelector((state) => state.circle.managedCirclesLoading);
  const race = useSelector((state) => state.managedRaces?.singleRace);
  const loading = useSelector((state) => state.managedRaces?.singleRaceLoading);
  const activityLoading = useSelector((state) => state.managedRaces?.activityLoading);
  const activity = useSelector((state) => state.managedRaces?.activity);
  const cups = useSelector((state) => state.cup?.cups);
  const cupsLoading = useSelector((state) => state.cup?.cupsLoading);
  //

  const today = moment(new Date()).format('YYYY-MM-DD');
  const endDate = moment(race?.endDate).format('YYYY-MM-DD');

  const raceTypeObj = [
    {
      value: 0,
      label: 'RacePresso',
    },
    {
      value: 1,
      label: 'Cyclosportive',
    },
  ];

  const isExpired = useMemo(() => {
    return today > endDate;
  }, [today, endDate]);

  const addSegmentsToRace = (item) => {
    dispatch({ type: 'EDIT_SEGMENTS_TO_RACE', segment: item });
    showNotification('success', 'Successfully added segments');
  };

  const loadStravaSegment = async () => {
    if (stravaId) {
      const response = await dispatch(addNewStravaSegment(stravaId));
      const obj = {
        segment: {
          name: response.name,
          stravaId: response.stravaId,
          distance: response.distance,
          elevation: response.elevation,
        },
        coefficient: 1,
      };

      addSegmentsToRace([obj]);
      if (response) {
        setStravaId('');
        showNotification('success', 'Successfully added race segment');
      }
    }
  };

  const editRaceHandler = () => {
    dispatch(
      editRace({
        id,
        name,
        startDate: start,
        endDate: raceType === 0 ? end : null,
        closeDate: raceType === 0 ? close : null,
        raceSegments: segmentsForRequest,
        circleId: !cupId ? circleId : null,
        raceRoutes: routes /*? routeId?.replace(/\D/g, '') : null,*/,
        isPublished,
        cupId: raceType === 0 ? cupId : null,
        cupCoefficient: cupId && coefficient,
        cupCompletedBonus: cupId && bonusPoint,
        cupWinnerBonus: cupId && bonusWinner,
        defaultRouteStravaId: defaultRouteStravaId,
        type: raceType,
      })
    );
  };

  const updateCoefficients = (stravaId) => (e) => {
    const newArray = segmentsForRequest.map((item) => {
      if (stravaId === item.stravaId) {
        return { ...item, [e.target.name]: Number(e.target.value) };
      } else {
        return item;
      }
    });
    const newArrayToDisplay = segments.map((item) => {
      if (stravaId === item.segment.stravaId) {
        return { ...item, [e.target.name]: Number(e.target.value) };
      } else {
        return item;
      }
    });
    setSegments(newArrayToDisplay);
    setSegmentsForRequest(newArray);
  };

  const removeRotues = (id) => {
    setRoutes((element) => element.filter((item) => item.stravaId !== id));
  };
  const updateLapsCount = (id) => (e) => {
    const newArray = routes.map((item) => {
      if (id === item.stravaId) {
        return { ...item, [e.target.name]: Number(e.target.value) };
      } else {
        return item;
      }
    });
    setRoutes(newArray);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <>{record?.segment.name}</>,
    },
    {
      title: 'Coefficient',
      dataIndex: 'coefficient',
      key: 'coefficient',
      render: (_, record) => (
        <input
          min={0}
          className="coefficient__input"
          type="number"
          value={record?.coefficient}
          name="coefficient"
          onChange={updateCoefficients(record?.segment.stravaId)}
          step={0.1}
        />
      ),
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
      render: (_, record) => <>{Number(record?.segment.distance / 1000).toFixed(2)} km</>,
    },
    {
      title: 'Elevation',
      dataIndex: 'elevation',
      key: 'distance',
      render: (_, record) => <>{record?.segment.elevation.toFixed(0)} m</>,
    },
    {
      title: 'StravaId',
      dataIndex: 'distance',
      key: 'distance',
      render: (_, record) => <StravaLink id={record?.segment.stravaId} type="segments" />,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'actions',
      render: (_, record) => (
        <>
          {!isExpired && (
            <DeleteOutlined onClick={() => removeHandler(record?.segment.stravaId)} className="ic-antd delete" />
          )}
        </>
      ),
    },
  ];
  const routescolumns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
        return (
          <>
            <a
              href={`https://www.strava.com/routes/${record?.stravaId}`}
              target="_blank"
              style={{ fontSize: '19px' }}
              rel="noreferrer"
            >
              {record.name}
            </a>
          </>
        );
      },
    },
    {
      title: 'Laps',
      dataIndex: 'laps',
      key: 'laps',
      render: (_, record) => (
        <input
          className="coefficient__input"
          type="number"
          value={record?.lapsCount}
          name="lapsCount"
          min={1}
          onChange={updateLapsCount(record?.stravaId)}
          step={1}
        />
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => <>{record?.stravaId}</>,
    },
    {
      title: t('tableColumns.distance'),
      dataIndex: 'elevation',
      key: 'elevation',
      render: (_, record) => <>{Number(record.distance / 1000).toFixed(2)} km</>,
    },
    {
      title: t('tableColumns.elevation'),
      dataIndex: 'elevation',
      key: 'elevation',
      render: (_, record) => {
        return <>{Number(record.elevation).toFixed(0)} m </>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'actions',
      render: (_, record) => {
        return (
          <>
            <Checkbox
              onClick={() => setDefaultRouteStravaId(Number(record?.stravaId))}
              checked={Number(record?.stravaId) === Number(defaultRouteStravaId)}
              style={{ marginRight: '10px' }}
            >
              Set default
            </Checkbox>
            <DeleteOutlined onClick={() => removeRotues(record.stravaId)} />
          </>
        );
      },
    },
  ];

  const convertRaceSegment = useCallback((seg) => {
    let arr = [];
    if (!seg) return;
    seg?.map((elem) => {
      arr.push({ coefficient: elem?.coefficient, stravaId: elem?.segment?.stravaId });
      setSegmentsForRequest(arr);
      return arr;
    });
  }, []);

  const removeHandler = (id) => {
    convertRaceSegment(segments);
    setSegments((element) => element.filter((item) => item?.segment.stravaId !== id));
    setSegmentsForRequest((element) => element.filter((item) => item.stravaId !== id));
    dispatch({ type: 'EDIT_SEGMENT', id });
  };

  const addRoutes = async () => {
    if (routeId) {
      const response = await dispatch(getSingleRoute(routeId));
      if (response) {
        setRouteId(null);
      }
    }
  };

  useEffect(() => {
    if (!_.isEmpty(singleRoute)) {
      setRoutes((prev) => [
        ...prev,
        {
          distance: singleRoute.distance,
          elevation: singleRoute.elevation,
          lapsCount: 1,
          name: singleRoute.name,
          stravaId: singleRoute.stravaId,
        },
      ]);
    }
  }, [singleRoute]);

  useEffect(() => {
    if (race) {
      setSegments(race?.raceSegments);
      convertRaceSegment(race?.raceSegments);
    }
  }, [race, convertRaceSegment]);

  useEffect(() => {
    dispatch(getActivity(from, to));
  }, [dispatch, from, to]);

  useEffect(() => {
    dispatch(getCircles());
    dispatch(getAllCups());
  }, [dispatch]);

  const openModalHandler = (id) => {
    setActivityId(id);
    setShowModal((prev) => !prev);
  };
  const closeModalHandler = () => {
    setActivityId(null);
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(getSingleRace(id));
  }, [dispatch, id]);

  // useEffect(() => {
  //   if (cupId) {
  //     setCircleId(null);
  //   }
  // }, [cupId]);

  useEffect(() => {
    if (race) {
      setName(race?.name);
      setStart(race?.startDate);
      setEnd(race?.endDate);
      setClose(race?.closeDate);
      convertRaceSegment(race?.raceSegments);
      setCircleId(race.cup?.id ? null : race?.circle?.id);
      setRouteId(race?.route?.stravaId);
      setIsPublished(race?.isPublished);
      setCoefficient(race.cupCoefficient);
      setBonusPoint(race.cupCompletedBonus);
      setBonusWinner(race.cupWinnerBonus);
      setCupId(race.cup?.id);
      setDefaultRouteStravaId(race?.defaultRoute?.stravaId);
      setRoutes(
        race?.raceRoutes.map((item) => {
          return {
            stravaId: item.route.stravaId,
            lapsCount: item.lapsCount,
            distance: item.route.distance,
            elevation: item.route.elevation,
            name: item.route.name,
            isDefault: item.selected,
          };
        })
      );
      setRaceType(race?.type.value);
    }
  }, [race, convertRaceSegment]);

  return (
    <div className="content">
      <div className="addrace-form">
        <h2 className="title">EDIT RACE</h2>

        {!loading && (
          <>
            {race && (
              <Select
                style={{ width: '33%', height: '43px !important' }}
                size="large"
                className="m-b-20"
                placeholder="Type of race"
                defaultValue={raceType === 0 ? 'RacePresso' : 'Cyclosportive'}
                disabled
              >
                {raceTypeObj.map((item) => (
                  <Option key={item.label}>{item.label}</Option>
                ))}
                {/* {circles?.map((elem) => (
                <>{!circlesLoading && <Option key={elem.id}>{elem.name}</Option>}</>
              ))} */}
              </Select>
            )}

            <div className="main-information">
              <div className="race-name__block">
                <Input size="large" placeholder="race name" value={name} onChange={(e) => setName(e.target.value)} />
              </div>
              <div className="first-row">
                <Input
                  className="strava-input route-input"
                  placeholder="route id"
                  value={routeId}
                  onChange={(e) => setRouteId(e.target.value)}
                  style={{ width: '33%' }}
                  size="large"
                />
                <Button onClick={addRoutes} size="large">
                  add
                </Button>
                <Select
                  disabled={cupId}
                  size="large"
                  allowClear
                  style={{ width: '33%', height: '43px !important' }}
                  onChange={(e) => setCircleId(e)}
                  value={circleId}
                  placeholder="Circle"
                >
                  {circles?.map((elem) => (
                    <>{!circlesLoading && <Option key={elem.id}>{elem.name}</Option>}</>
                  ))}
                </Select>
                {raceType === 0 && (
                  <Select
                    allowClear
                    disabled={circleId}
                    style={{ width: '33%', height: '43px !important' }}
                    onChange={(e) => setCupId(e)}
                    placeholder={t('cupName')}
                    size="large"
                    value={cupId}
                  >
                    {cups?.map((elem) => (
                      <>{!cupsLoading && <Option key={elem.id}>{elem.name}</Option>}</>
                    ))}
                  </Select>
                )}
              </div>
              <div className="first-row">
                <DatePicker
                  style={{ width: '33%' }}
                  size="large"
                  format="YYYY-MM-DD"
                  placeholder="start activity date"
                  value={start !== '' ? moment(start) : ''}
                  onChange={(date, dateString) => setStart(dateString)}
                  disabled={isExpired}
                />
                {raceType === 0 && (
                  <DatePicker
                    style={{ width: '33%' }}
                    size="large"
                    format="YYYY-MM-DD"
                    placeholder="end activity date"
                    value={end !== '' ? moment(end) : ''}
                    onChange={(date, dateString) => setEnd(dateString)}
                    disabled={isExpired}
                  />
                )}
                {raceType === 0 && (
                  <DatePicker
                    style={{ width: '33%' }}
                    size="large"
                    format="YYYY-MM-DD"
                    placeholder="close date"
                    value={close !== '' ? moment(close) : ''}
                    onChange={(date, dateString) => setClose(dateString)}
                    disabled={isExpired}
                  />
                )}
              </div>
              <Checkbox onChange={() => setIsPublished((prev) => !prev)} value={isPublished} checked={isPublished}>
                Publish Race
              </Checkbox>
              {cupId && (
                <div className="first-row">
                  <InputNumber
                    className="strava-input route-input"
                    placeholder={t('tableColumns.coefficient')}
                    style={{ width: '33%' }}
                    size="large"
                    value={coefficient}
                    onChange={(value) => setCoefficient(value)}
                    defaultValue={coefficient}
                    min={1}
                    max={10}
                  />
                  <InputNumber
                    className="strava-input route-input"
                    placeholder="Bonus point"
                    style={{ width: '33%' }}
                    value={bonusPoint}
                    onChange={(value) => setBonusPoint(value)}
                    size="large"
                    defaultValue={bonusPoint}
                    min={1}
                    max={10}
                  />
                  <InputNumber
                    className="strava-input route-input"
                    placeholder="Bonus winner"
                    style={{ width: '33%' }}
                    value={bonusWinner}
                    onChange={(value) => setBonusWinner(value)}
                    size="large"
                    defaultValue={bonusWinner}
                    min={1}
                    max={10}
                  />
                </div>
              )}
            </div>

            {raceType === 0 && (
              <>
                <h3>Segments</h3>
                <div className="choose-type">
                  <div className="choose-type-item strava-type">
                    <div>
                      <div>
                        <p className="custom__label">Load segments from strava</p>
                        <Input
                          size="large"
                          className="strava-input"
                          placeholder="strava id"
                          disabled={isExpired}
                          value={stravaId}
                          onChange={(e) => setStravaId(e.target.value)}
                        />
                        <Button size="large" onClick={loadStravaSegment} disabled={isExpired}>
                          add
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="choose-type-item activity-type">
                    <div>
                      <p className="custom__label">From</p>
                      <DatePicker
                        size="large"
                        format="YYYY-MM-DD"
                        placeholder="from"
                        onChange={(date, dateString) => setFrom(dateString)}
                        disabled={isExpired}
                      />
                    </div>
                    <div>
                      <p className="custom__label">To</p>
                      <DatePicker
                        size="large"
                        format="YYYY-MM-DD"
                        placeholder="to"
                        onChange={(date, dateString) => setTo(dateString)}
                        disabled={isExpired}
                      />
                    </div>
                    <div>
                      <p className="custom__label">Select a Strava activity</p>
                      <Select
                        size="large"
                        allowClear
                        style={{ width: '250px', height: '43px !important' }}
                        onChange={(e) => openModalHandler(e)}
                        placeholder="Activities"
                        disabled={isExpired}
                      >
                        {activity.map((elem) => (
                          <>{!activityLoading && <Option key={elem.stravaId}>{elem.name}</Option>}</>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </>
            )}

            {!_.isEmpty(race?.raceSegments) && <Table columns={columns} dataSource={segments} rowKey={'stravaId'} />}
            {!_.isEmpty(routes) && (
              <Table columns={routescolumns} dataSource={routes} key={routes.routeId} rowKey={'id'} />
            )}
            <div className="addrace-footer">
              <Button type="primary" onClick={editRaceHandler}>
                Save
              </Button>
            </div>
          </>
        )}
      </div>
      {showModal && (
        <Modal open={showModal} onCancel={closeModalHandler} width={'990px'} footer={null} destroyOnClose={true}>
          <EditActivityItem id={activityId} close={closeModalHandler} />
        </Modal>
      )}
    </div>
  );
};

export default EditRace;
