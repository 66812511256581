import { Collapse, Modal, Select, Spin } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as TimeIC } from '../../assets/clock-ic.svg';
import {
  calculateRace,
  completeRace,
  getActivity,
  getSingleRace,
  registerRace,
  unregisterRace,
} from '../../store/actions/manageRaces/manageRaces';
import CyclosportiveUsersTab from '../../components/Tabs/UsersTab/CyclosportiveUsersTab';
import RacePressoUsersTab from '../../components/Tabs/UsersTab/RacePressoUsersTab';
import SegmentTab from '../../components/Tabs/SegmentsTab';
import _ from 'lodash';
import moment from 'moment';
import { daysRemaining } from '../../utils/functions';
import { useTranslation } from 'react-i18next';
import RoutesTab from '../../components/Tabs/RoutesTab';
import { AppContext } from '../../appContext';
import './styles.scss';
import LocaleDate from '../../components/LocaleDate/LocaleDate';
import Map, { Layer, Source } from 'react-map-gl';
import polyline from '@mapbox/polyline';
import mapboxgl from 'mapbox-gl';

const { Panel } = Collapse;
const { Option } = Select;

const RaceDetails = () => {
  const { isAdmin } = useContext(AppContext);
  const mapRef = React.createRef();
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const race = useSelector((state) => state.managedRaces?.singleRace);
  const loading = useSelector((state) => state.managedRaces?.singleRaceLoading);
  const activityLoading = useSelector((state) => state.managedRaces?.activityLoading);
  const activity = useSelector((state) => state.managedRaces?.activity);
  const missingSegment = useSelector((state) => state.managedRaces?.missingSegment);
  const missingSegmentLoading = useSelector((state) => state.managedRaces?.missingSegmentLoading);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [completeByActivity, setCompleteByActivity] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [canRegister, setCanRegister] = useState(null);
  const [canUnregister, setCanUnregister] = useState(null);
  const [canComplete, setCanComplete] = useState(null);
  const [registerRequestLoading, setRegisterRequestLoading] = useState(false);
  const [usersTabInfo, setUsersTabInfo] = useState(null);
  const [raceRoutes, setRaceRoutes] = useState(null);
  const [segmentCoords, setSegmentCoords] = useState([]);
  const [routeCoords, setRouteCoords] = useState([]);
  const [initialFitBounds, setInitialFitBounds] = useState(null);

  const decodePolylineArr = useCallback(() => {
    let tmp;
    if (race?.raceSegments) {
      tmp = race?.raceSegments.map((item) => {
        let { coordinates } = polyline.toGeoJSON(item.segment.polyline);
        return coordinates;
      });

      return tmp;
    }
  }, [race?.raceSegments]);

  const decodePolylineArrRoute = useCallback(() => {
    let tmp;
    if (race?.raceRoutes) {
      tmp = race?.raceRoutes.map((item) => {
        let { coordinates } = polyline.toGeoJSON(item.route.polyline);
        return coordinates;
      });

      return tmp;
    }
  }, [race?.raceRoutes]);

  const registerOnRaceHandler = async () => {
    setRegisterRequestLoading(true);
    const response = await dispatch(registerRace(id));
    if (response) {
      setRegisterRequestLoading(false);
      setCanRegister((prev) => !prev);
      setCanUnregister((prev) => !prev);
      setCanComplete((prev) => !prev);
      dispatch(getSingleRace(id));
    }
  };
  const unregisterOnRaceHandler = async () => {
    setRegisterRequestLoading(true);

    const response = await dispatch(unregisterRace(id));
    if (response) {
      setCanUnregister((prev) => !prev);
      setCanRegister((prev) => !prev);
      setRegisterRequestLoading(false);
      setCanComplete((prev) => !prev);
      dispatch(getSingleRace(id));
    }
  };
  const completeRaceHandler = async () => {
    const response = await dispatch(completeRace(id, completeByActivity));
    if (response) dispatch(getSingleRace(id));
    else setShowModal((prev) => !prev);
  };

  const calculateRaceHandler = async () => {
    const response = await dispatch(calculateRace(id));
    if (response) dispatch(getSingleRace(id));
  };

  useEffect(() => {
    let info = decodePolylineArr();
    if (info) {
      let obj = info.map((item) => {
        return {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: item,
              },
            },
          ],
        };
      });
      const merged = _.flatten(info);
      setInitialFitBounds({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: merged,
            },
          },
        ],
      });
      setSegmentCoords(obj);
    }
  }, [decodePolylineArr]);

  useEffect(() => {
    let routeInfo = decodePolylineArrRoute();
    if (routeInfo) {
      const merged = _.flatten(routeInfo);
      setRouteCoords(merged);
    }
  }, [decodePolylineArrRoute]);

  const routeCoordsObject = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: routeCoords,
        },
      },
    ],
  };

  const handleMapLoad = () => {
    const bounds = new mapboxgl.LngLatBounds();
    const mergedArray = routeCoords.concat(initialFitBounds?.features[0].geometry.coordinates);
    mergedArray.forEach((coord) => {
      bounds.extend(coord);
    });

    mapRef.current.fitBounds(bounds, {
      padding: 100,
      maxZoom: 14,
    });
  };
  useEffect(() => {
    if (race) {
      setFrom(moment(race?.startDate).format('YYYY-MM-DD'));
      setTo(moment(race?.endDate).format('YYYY-MM-DD'));
      setCanRegister(race?.allowToRegister);
      setCanUnregister(race?.allowToUnregister);
      setCanComplete(race?.allowToComplete);
      setUsersTabInfo(_.sortBy(race?.raceUsers, 'rank'));
      setRaceRoutes(race?.raceRoutes);
    }
  }, [race]);

  useEffect(() => {
    if (race && race?.startDate && race?.endDate) {
      dispatch(getActivity(from, to));
    }
  }, [dispatch, race, from, to]);

  useEffect(() => {
    dispatch(getSingleRace(id));
  }, [dispatch, id]);

  const today = moment(new Date()).format('YYYY-MM-DD');
  const end = moment(race?.endDate).format('YYYY-MM-DD');

  return (
    <div className="content">
      <Spin spinning={loading || registerRequestLoading} size="large">
        {!loading && (
          <div className="cupDetails__container">
            <h1 className="title edit-text__title">{race?.name}</h1>
            <div className="locale-date__container m-b-30">
              {race?.startDate && (
                <>
                  <LocaleDate date={new Date(race?.startDate)} /> /{' '}
                  <> {race?.endDate && <LocaleDate date={new Date(race?.endDate)} />}</>
                </>
              )}
            </div>

            {race?.startDate && race?.endDate && (
              <div className="date__remaining">
                {today > end ? (
                  <p className="days">{t('finishedRace')}</p>
                ) : (
                  <div className="hours">
                    <TimeIC className="time-IC" />
                    <p>
                      {t('remaining')} {daysRemaining(new Date(race?.endDate), t('days'), t('hours'))}
                    </p>
                  </div>
                )}
              </div>
            )}

            <div className="race-action__form">
              {canRegister && (
                <button onClick={registerOnRaceHandler} className="btn race-register__btn">
                  {t('buttons.registerCupRace')}
                </button>
              )}

              {!loading && (
                <>
                  {canUnregister && (
                    <div className="object-details__buttons">
                      <button onClick={unregisterOnRaceHandler} className="btn race-unregistern__btn">
                        {t('buttons.unregisterCupRace')}
                      </button>
                      {isAdmin && (
                        <button className="btn calculate-button" onClick={calculateRaceHandler}>
                          calculate race
                        </button>
                      )}
                    </div>
                  )}
                  {canComplete && race?.raceSegments?.length > 0 && (
                    <Spin spinning={activityLoading}>
                      <div className="complete__container">
                        <span className="strava-acitvity_label">{t('titles.selectStravaActivity')}</span>
                        <div>
                          <Select
                            allowClear
                            style={{ width: '170px', height: '43px !important' }}
                            onChange={(e) => setCompleteByActivity(e)}
                            placeholder={t('titles.activities')}
                          >
                            {activity.map((elem) => (
                              <>{!activityLoading && <Option key={elem.stravaId}>{elem.name}</Option>}</>
                            ))}
                          </Select>
                          <button
                            onClick={completeRaceHandler}
                            className="btn race-complete__btn"
                            disabled={Boolean(!completeByActivity)}
                          >
                            {t('buttons.apply')}
                          </button>
                        </div>
                      </div>
                    </Spin>
                  )}
                </>
              )}
            </div>

            <div style={{ width: '100%', margin: '0 auto' }}>
              <div className="race-oncircle__info m-b-30">
                <div className="table-element">
                  <div className="table__item">
                    <h3>{t('tableColumns.distance')}</h3>
                    <span>{Number(race?.distance / 1000).toFixed(2)} km</span>
                  </div>
                  <div className="table__item">
                    <h3>{t('tableColumns.elevation')}</h3>
                    <span>{Number(race?.elevation).toFixed(0)} m</span>
                  </div>
                  <div className="table__item">
                    <h3>{t('tableColumns.segments')}</h3>
                    <span>{race?.raceSegments ? race?.raceSegments?.length : 'not specified'}</span>
                  </div>
                  <div className="table__item">
                    <h3>{t('tableColumns.racers')}</h3>
                    <span>{race?.raceUsers ? race?.raceUsers?.length : 'Not registered yet'}</span>
                  </div>
                </div>
              </div>
              {race?.circle && (
                <div className="race-oncircle__info m-b-30">
                  <div className="table-element">
                    <div className="table__item" style={{ width: '100%' }}>
                      <h3 style={{ fontSize: '22px' }}>{t('circleName')}</h3>
                      <Link to={`/details/circle/${race.circle.id}`}>
                        <span>{race?.circle?.name}</span>
                      </Link>

                      {race?.route?.name && (
                        <>
                          <h3 style={{ fontSize: '22px' }}>{t('routeName')}</h3>
                          <a
                            href={`https://www.strava.com/routes/${race?.route?.stravaId}`}
                            target="_blank"
                            style={{ fontSize: '19px' }}
                            rel="noreferrer"
                          >
                            {race?.route?.name}
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="map__container">
                <Map
                  onLoad={handleMapLoad}
                  mapboxAccessToken="pk.eyJ1IjoiY3ljbG9jY2lubyIsImEiOiJjbGI0cWhjbHQwY3YwM3Zxc2EyNjA5OTJnIn0.5RM8MEXQQLl6iNTpSeqokg"
                  style={{
                    width: '100%',
                    height: 'inherit',
                    borderRadius: '15px',
                    border: '2px solid #ccc',
                  }}
                  initialViewState={{
                    latitude: 45.14676,
                    longitude: 5.76512,
                    zoom: 7,
                  }}
                  mapStyle="mapbox://styles/mapbox/outdoors-v12"
                  ref={mapRef}
                >
                  <Source id="route" type="geojson" data={routeCoordsObject}>
                    <Layer
                      id="route-layer"
                      type="line"
                      paint={{
                        'line-color': '#FF0000',
                        'line-width': 4,
                        'line-opacity': 0.6,
                      }}
                    />
                  </Source>

                  {segmentCoords && (
                    <>
                      {segmentCoords.map((item, idx) => (
                        <Source id={`segments${idx + 1}`} type="geojson" data={item}>
                          <Layer
                            id={`segments-layer${idx + 1}`}
                            type="line"
                            paint={{
                              'line-color': '#FB6BC7',
                              'line-width': 5,
                              'line-opacity': 1,
                            }}
                          />
                        </Source>
                      ))}
                    </>
                  )}
                </Map>
              </div>

              {race?.cup && (
                <div className="race-oncircle__info m-b-30">
                  <div className="table-element">
                    <div className="table__item" style={{ width: '100%' }}>
                      <h3 style={{ fontSize: '22px' }}>{t('cupName')}</h3>
                      <Link to={`/details/cup/${race?.cup?.id}`}>
                        <span>{race?.cup?.name}</span>
                      </Link>

                      {race?.route?.name && (
                        <>
                          <h3 style={{ fontSize: '22px' }}>{t('routeName')}</h3>
                          <a
                            href={`https://www.strava.com/routes/${race?.route?.stravaId}`}
                            target="_blank"
                            style={{ fontSize: '19px' }}
                            rel="noreferrer"
                          >
                            {race?.route?.name}
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {(race?.raceUsers || race?.raceSegments) && (
                <h2 className="title m-b-15 m-t-30">{t('titles.raceResult')}</h2>
              )}

              {!_.isEmpty(race?.raceSegments) && (
                <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
                  <Panel header={t('segmentsOnRace')} key={1}>
                    <SegmentTab segments={race?.raceSegments} detail={race} />
                  </Panel>
                </Collapse>
              )}

              <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
                <Panel header={t('usersOnRace')} key={1}>
                  {!_.isEmpty(race?.raceUsers) ? (
                    <>
                      {race?.type.value === 0 ? (
                        <RacePressoUsersTab users={usersTabInfo} />
                      ) : (
                        <CyclosportiveUsersTab users={usersTabInfo} />
                      )}
                    </>
                  ) : (
                    <div className="no-users__result">No registered users on this race</div>
                  )}
                </Panel>
              </Collapse>
              {race?.type.value !== 0 && (
                <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
                  <Panel header={t('tableColumns.route')} key={1}>
                    {!_.isEmpty(raceRoutes) ? (
                      <RoutesTab routes={raceRoutes} id={id} allowToChooseRoute={!canRegister} />
                    ) : (
                      <div className="no-users__result">No registered users on this race</div>
                    )}
                  </Panel>
                </Collapse>
              )}
            </div>
          </div>
        )}
      </Spin>
      <Modal open={showModal} onCancel={() => setShowModal(false)} footer={null}>
        <Spin spinning={missingSegmentLoading}>
          <h2>Missing Segments</h2>
          {missingSegment.map((item) => {
            return <p>{item.value.STRAVA_NAME}</p>;
          })}
        </Spin>
      </Modal>
    </div>
  );
};

export default RaceDetails;
