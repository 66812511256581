import React, { useEffect } from 'react';
import _ from 'lodash';
import history from '../../utils/history';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axiosApi from '../../utils/axiosApi';
import { cleanUpAuthToken } from '../../utils/functions';
import { loginUser } from '../../store/actions/user/userActions';
import jwtDecode from 'jwt-decode';
import { SmileOutlined } from '@ant-design/icons';
import { getAdditionalUserInfo } from '../../store/actions/user/userActions';
import { Result } from 'antd';
import './styles.scss';

const StravaRedirect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const authenticate = async () => {
      try {
        if (_.isEmpty(window.location)) {
          return history.push('/');
        }
        const stravaAuthToken = cleanUpAuthToken(window.location.search);

        const token = await axiosApi.post('/oauth/token', { code: stravaAuthToken });

        const userInfo = jwtDecode(token?.data?.data.accessToken.value);
        dispatch(getAdditionalUserInfo());

        dispatch(
          loginUser({
            profile: userInfo,
            team: '',
            token: token?.data?.data.accessToken.value,
            refreshToken: token.data?.data?.refreshToken.value,
          })
        );
        navigate('/');
      } catch (error) {}
    };
    authenticate();
  }, [dispatch]);

  return <Result icon={<SmileOutlined />} title="Great, we have done login!" className="welcome-screen" />;
};

export default StravaRedirect;
