import { Button, Checkbox, Form, Input, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTeamId, updateTeam } from '../../../store/actions/team/teamActions';
import './styles.scss';
const { TextArea } = Input;

const EditTeam = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const singleTeam = useSelector((state) => state?.team?.teamId);
  const loading = useSelector((state) => state?.team?.teamIdLoading);
  const [teamName, setTeamName] = useState(null);
  const [shortName, setShortName] = useState(null);
  const [description, setDescription] = useState(null);
  const [isRecruiting, setIsRecruiting] = useState(null);

  const editTeamHandler = () => {
    dispatch(updateTeam({ name: teamName, shortName, description, id, isRecruiting }));
  };

  useEffect(() => {
    if (id) dispatch(getTeamId(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (singleTeam) {
      setTeamName(singleTeam?.name);
      setShortName(singleTeam?.shortName);
      setDescription(singleTeam?.description);
      setIsRecruiting(singleTeam?.isRecruiting);
    }
  }, [singleTeam]);
  return (
    <div className="content">
      <h2 className="title m-b-30">EDIT Team</h2>
      <Spin spinning={loading} size="large">
        <div className="create-team__container">
          {singleTeam && (
            <Form onFinish={editTeamHandler}>
              <div className="m-b-10">
                <Form.Item
                  initialValue={singleTeam?.name}
                  name="teamName"
                  className="m-b-15"
                  rules={[
                    {
                      required: true,
                      message: t('errors.inputTeam'),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t('teamName')}
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                  />
                </Form.Item>
              </div>

              <div className="m-b-10">
                <Form.Item
                  initialValue={singleTeam?.shortName}
                  name="shortName"
                  className="m-b-15"
                  rules={[
                    {
                      required: true,
                      message: t('errors.inputShortName'),
                    },
                    {
                      max: 10,
                      message: t('errors.maxChars'),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t('shortName')}
                    value={shortName}
                    onChange={(e) => setShortName(e.target.value)}
                  />
                </Form.Item>
              </div>

              <div className="m-b-10">
                <Form.Item
                  name="description"
                  initialValue={singleTeam?.description}
                  className="m-b-15"
                  rules={[
                    {
                      required: true,
                      message: t('errors.inputDescription'),
                    },
                  ]}
                >
                  <TextArea
                    size="large"
                    autoSize
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows={4}
                  />
                </Form.Item>
              </div>
              <div>
                <Checkbox
                  style={{ fontSize: '13px', textTransform: 'uppercase' }}
                  value={isRecruiting}
                  checked={isRecruiting}
                  onChange={() => setIsRecruiting((prev) => !prev)}
                >
                  Is Recruiting
                </Checkbox>
              </div>
              <div style={{ textAlign: 'right' }}>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  {/* <Button onClick={createTeamHandler} type="primary" size="large">
                Create Team
              </Button> */}
                  <Button htmlType="submit" type="primary" size="large">
                    Edit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default EditTeam;
