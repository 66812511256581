import {
  GET_GENERAL_INFO_REQUEST,
  GET_GENERAL_INFO_SUCCESS,
  GET_GENERAL_INFO_FAILURE,
  GET_MYRACES_REQUEST,
  GET_MYRACES_SUCCESS,
  GET_MYRACES_FAILURE,
  GET_FAV_CIRCLE_REQUEST,
  GET_FAV_CIRCLE_SUCCESS,
  GET_FAV_CIRCLE_FAILURE,
  GET_MY_CUPS_REQUEST,
  GET_MY_CUPS_SUCCESS,
  GET_MY_CUPS_FAILURE,
} from '../actions/dashboard/actionTypes';
const initialState = {
  generalInfo: [],
  generalInfoLoading: false,
  myRaces: [],
  myCups: [],
  myCupsLoading: false,
  myRacesLoading: false,
  favCircles: null,
  favCirclesLoading: false,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GENERAL_INFO_REQUEST:
      return { ...state, generalInfoLoading: true };
    case GET_GENERAL_INFO_SUCCESS:
      return { ...state, generalInfoLoading: false, generalInfo: action.generalInfo };
    case GET_GENERAL_INFO_FAILURE:
      return { ...state, generalInfoLoading: false };
    case GET_MYRACES_REQUEST:
      return { ...state, myRacesLoading: true };
    case GET_MYRACES_SUCCESS:
      return { ...state, myRacesLoading: false, myRaces: action.myRaces };
    case GET_MYRACES_FAILURE:
      return { ...state, myRacesLoading: false };
    case GET_FAV_CIRCLE_REQUEST:
      return { ...state, favCirclesLoading: true };
    case GET_FAV_CIRCLE_SUCCESS:
      return { ...state, favCirclesLoading: false, favCircles: action.favCircles };
    case GET_FAV_CIRCLE_FAILURE:
      return { ...state, favCirclesLoading: false };
    case GET_MY_CUPS_REQUEST:
      return { ...state, myCups: [], myCupsLoading: true };
    case GET_MY_CUPS_SUCCESS:
      return { ...state, myCups: action.myCups, myCupsLoading: false };
    case GET_MY_CUPS_FAILURE:
      return { ...state, myCupsLoading: false };
    default:
      return { ...state };
  }
};

export default dashboardReducer;
