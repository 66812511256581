import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { loadFromLocalStorage, saveToLocalStorage } from '../utils/localStorage';
import athletesReducer from './reducers/athletesReducer';
import userReducer from './reducers/userReducer';
import axiosApi from '../utils/axiosApi';
import managedRacesReducer from './reducers/managedRacesReducer';
import circleReducer from './reducers/circleReducer';
import racesReducer from './reducers/racesReducer';
import dashboardReducer from './reducers/dashboardReducer';
import teamsReducer from './reducers/teamsReducer';
import cupsReducer from './reducers/cupReducer';
const url = process.env.REACT_APP_REDIRECT_URL;
const key = process.env.REACT_APP_STRAVA_KEY;

const rootReducer = combineReducers({
  races: racesReducer,
  athletes: athletesReducer,
  user: userReducer,
  managedRaces: managedRacesReducer,
  circle: circleReducer,
  dashboard: dashboardReducer,
  team: teamsReducer,
  cup: cupsReducer,
});

const preloadedState = loadFromLocalStorage('user') || {};
const store = createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(thunk)));

store.subscribe(() => {
  saveToLocalStorage('user', {
    user: store.getState()?.user,
  });
});

axiosApi.interceptors.request.use((config) => {
  if (config.url !== '/oauth/token') {
    config.headers['Authorization'] =
      store.getState().user?.profile.token && `Bearer ${store.getState().user?.profile.token}`;
  }
  return config;
});

axiosApi.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalConfig = error.config;
    if (error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        window.location = `http://www.strava.com/oauth/authorize?client_id=${key}&response_type=code&redirect_uri=${url}/exchange_token&approval_prompt=auto&scope=read,activity:read_all`;
        // const token = store.getState().user.profile;
        // const res = await axios.post('https://api.racepresso.cc/oauth/refresh', {
        //   accessToken: token.token,
        //   refreshToken: token.refreshToken,
        // });
        // store.dispatch(
        //   loginUser({
        //     profile: jwtDecode(res.data?.data?.accessToken.value),
        //     token: res.data?.data?.accessToken.value,
        //     refreshToken: res.data?.data?.refreshToken.value,
        //   })
        // );
        // window.location.reload();
      } catch (error) {
        return Promise.reject(error);
      }
    }
    throw error;
  }
);

export default store;
