import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const MembersTab = ({ members }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <>
          {record?.user?.firstName} {record?.user?.lastName}
        </>
      ),
    },

    {
      title: t('tableColumns.team'),
      dataIndex: 'team',
      key: 'team',
      render: (_, record) => {
        return (
          <>
            {record?.user?.currentTeam ? (
              <Link to={`/team/details/${record?.user?.currentTeam.id}`}>{record?.user?.currentTeam?.shortName}</Link>
            ) : (
              '-'
            )}
          </>
        );
      },
    },

    {
      title: t('tableColumns.role'),
      dataIndex: 'Role',
      key: 'role',
      render: (_, record) => {
        return (
          <>
            {record?.role?.localizationKey === 'REQUESTER'
              ? t('requester')
              : record?.role?.localizationKey === 'SUBSCRIBER'
              ? t('subscriber')
              : t('owner')}
          </>
        );
      },
    },
    {
      title: t('tableColumns.enteredDate'),
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => <>{moment(record?.enteredAt).format('YYYY-MM-DD')} </>,
    },
  ];
  return <Table dataSource={members} columns={columns} rowKey={'id'} />;
};

export default MembersTab;
