import {
  GET_ALL_CUPS_REQUEST,
  GET_ALL_CUPS_SUCCESS,
  GET_ALL_CUPS_FAILURE,
  GET_CUP_BYID_REQUEST,
  GET_CUP_BYID_SUCCESS,
  GET_CUP_BYID_FAILURE,
} from '../actions/cup/actionTypes';
const initialState = {
  cups: null,
  cupRaces: null,
  cupsLoading: false,
  cupByID: null,
  cupByIDLoading: false,
};

const cupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CUPS_REQUEST:
      return { ...state, cupsLoading: true, cups: [] };
    case GET_ALL_CUPS_SUCCESS:
      return { ...state, cupsLoading: false, cups: action.cups };
    case GET_ALL_CUPS_FAILURE:
      return { ...state, cupsLoading: false };
    case GET_CUP_BYID_REQUEST:
      return { ...state, cupByIDLoading: true, cupByID: null };
    case GET_CUP_BYID_SUCCESS:
      return { ...state, cupByIDLoading: false, cupByID: action.cupByID };
    case GET_CUP_BYID_FAILURE:
      return { ...state, cupByIDLoading: false };
    case 'EDIT_CUP_RACES':
      return {
        ...state,
        cupByID: {
          ...state.cupByID,
          races: state.cupByID.races.filter((item) => item.id !== action.id),
        },
      };

    // singleRace: {
    //   ...state.singleRace,
    //   raceSegments: [...state.singleRace.raceSegments, ...action.segment],
    // },
    default:
      return { ...state };
  }
};

export default cupReducer;
