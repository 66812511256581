import React, { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/UI/Layout/Layout';
import MainPage from './pages/MainPage/MainPage';
import ManageRaces from './pages/Admin/ManageRaces/ManageRaces';
import ManageUsers from './pages/Admin/ManageUsers/ManageUsers';
import Races from './pages/Races/Races';
import Profile from './pages/Profile/Profile';
import CreateRace from './components/CreateRace/CreateRace';
import StravaRedirect from './pages/StravaRedirect/StravaRedirect';
import { AppContextProvider } from './appContext';
import { useDispatch, useSelector } from 'react-redux';
import EditUser from './pages/Admin/EditUser/EditUser';
import EditRace from './pages/Admin/EditRace/EditRace';
import RaceDetails from './pages/RaceDetails/RaceDetails';
import CircleDetails from './pages/CircleDetails/CircleDetails';
import ManageCircles from './pages/Circles/ManageCircles/ManageCircles';
import Circles from './pages/Circles/Circles';
import PrivateRoute from './utils/PrivateRoute';
import './styles/main.scss';
import './index.css';
import CreateCircle from './pages/Circles/CreateCircle/CreateCircle';
import CreateTeam from './pages/Admin/CreateTeam/CreateTeam';
import EditCircle from './components/EditCircle/EditCircle';
import CircleMembers from './components/CircleMembers/CircleMembers/CircleMembers';
// import ManageTeams from './pages/Admin/ManageTeams/ManageTeams';
import { getAdditionalUserInfo } from './store/actions/user/userActions';
import Teams from './pages/Teams/Teams';
import TeamMembers from './pages/Admin/TeamMembers/TeamMembers';
import TeamDetail from './pages/TeamDetail/TeamDetail';
import MyTeam from './pages/Profile/MyTeam';
import EditTeam from './pages/Admin/EditTeam/EditTeam';
import CreateCup from './pages/Cups/CreateCup/CreateCup';
import Cups from './pages/Cups/Cups';
import AboutUs from './pages/AboutUs/AboutUs';
import LoadingTranslations from './components/UI/LoadingTranslations/LoadingTranslations';
import ManageCups from './pages/Cups/ManageCups/ManageCups';
import CupDetails from './pages/Cups/CupDetails/CupDetails';
import EditCup from './pages/Cups/EditCup/EditCup';
import DataProtectionPage from './pages/DataProtectionPage/DataProtectionPage';
import MapForCircles from './pages/MapForCircles/MapForCircles';
import UserDetail from './pages/Admin/UserDetail/UserDetail';

const App = () => {
  const dispatch = useDispatch();
  const userExist = useSelector((state) => state.user?.profile);
  const userEmail = useSelector((state) => state.user.email);
  const userRole = useSelector((state) => state.user?.profile?.profile.role);
  //context
  const isAdmin = userRole === 'Administrator';
  const isRaceOrganazier = userRole === 'RaceOrganizer';
  const isRacer = userRole === 'Racer';
  const hasEmail = userEmail && userEmail.length !== 0;
  const hasRole = Boolean(userRole);
  const isLoggedIn = Boolean(userExist);

  useEffect(() => {
    if (!hasEmail) {
      dispatch(getAdditionalUserInfo());
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <AppContextProvider
      isLoggedIn={isLoggedIn}
      isAdmin={isAdmin}
      hasRole={hasRole}
      hasEmail={hasEmail}
      isRaceOrganazier={isRaceOrganazier}
      isRacer={isRacer}
    >
      <Suspense fallback={LoadingTranslations}>
        <Layout>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/exchange_token" element={<StravaRedirect />} />
            <Route element={<PrivateRoute isLogged={isLoggedIn} />}>
              <Route path="/about" element={<AboutUs />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/races" element={<Races />} />
            </Route>
            <Route
              element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole) && (isAdmin || isRaceOrganazier)} />}
            >
              <Route path="/manage/races" element={<ManageRaces />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/teams" element={<Teams />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/team/new" element={<CreateTeam />} />
            </Route>
            <Route
              element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole) && (isAdmin || isRaceOrganazier)} />}
            >
              <Route path="/races/new" element={<CreateRace />} />
            </Route>
            <Route
              element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole) && (isAdmin || isRaceOrganazier)} />}
            >
              <Route path="/add-race/:id" element={<CreateRace />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && isAdmin && Boolean(userRole)} />}>
              <Route path="/manage/users" element={<ManageUsers />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && isAdmin && Boolean(userRole)} />}>
              <Route path="/edit/user/:id" element={<EditUser />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && isAdmin && Boolean(userRole)} />}>
              <Route path="/edit/race/:id" element={<EditRace />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/details/race/:id" element={<RaceDetails />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/circles" element={<Circles />} />
            </Route>

            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/details/circle/:id" element={<CircleDetails />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/details/cup/:id" element={<CupDetails />} />
            </Route>

            <Route element={<PrivateRoute isLogged={isLoggedIn && isAdmin && Boolean(userRole)} />}>
              <Route path="/details/user/:id" element={<UserDetail />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/manage/circles" element={<ManageCircles />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/manage/cups" element={<ManageCups />} />
            </Route>
            <Route
              element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole) && (isAdmin || isRaceOrganazier)} />}
            >
              <Route path="/circles/new" element={<CreateCircle />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/circle/edit/:id" element={<EditCircle />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/cup/edit/:id" element={<EditCup />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/circle/members/:id" element={<CircleMembers />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/team/users/:id" element={<TeamMembers />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/team/details/:id" element={<TeamDetail />} />
            </Route>
            <Route
              element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole) && (isAdmin || isRaceOrganazier)} />}
            >
              <Route path="/team/edit/:id" element={<EditTeam />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/cups" element={<Cups />} />
            </Route>
            <Route
              element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole) && (isAdmin || isRaceOrganazier)} />}
            >
              <Route path="/cup/new" element={<CreateCup />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/my-team" element={<MyTeam />} />
            </Route>
            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/profile" element={<Profile />} />
            </Route>

            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/data-protection" element={<DataProtectionPage />} />
            </Route>

            <Route element={<PrivateRoute isLogged={isLoggedIn && Boolean(userRole)} />}>
              <Route path="/circles-map" element={<MapForCircles />} />
            </Route>
            <Route path="/rgpd" element={<DataProtectionPage />} />
            <Route
              path="*"
              element={<div style={{ textAlign: 'center', marginTop: '20%', fontSize: '46px' }}>Not Found</div>}
            />
          </Routes>
        </Layout>
      </Suspense>
    </AppContextProvider>
  );
};

export default App;
