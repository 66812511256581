import { Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CircleCupsTab = ({ circleCups }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <Link to={`/details/cup/${record.id}`}>{record?.name}</Link>,
    },
    {
      title: t('tableColumns.creator'),
      dataIndex: 'creator',
      key: 'creator',
      render: (_, record) => <>{record?.creator}</>,
    },
    {
      title: t('tableColumns.numberOfRaces'),
      dataIndex: 'numberOfRaces',
      key: 'numberOfRaces',
      render: (_, record) => <>{record?.racesCount}</>,
    },
    {
      title: t('tableColumns.numberOfRacers'),
      dataIndex: 'numberOfRacers',
      key: 'numberOfRacers',
      render: (_, record) => <>{record?.registeredCount}</>,
    },
  ];
  return <Table columns={columns} dataSource={circleCups} rowKey={'id'} />;
};

export default CircleCupsTab;
