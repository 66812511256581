import { Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import Map, { Marker, NavigationControl, Popup, FullscreenControl, GeolocateControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCircles } from '../../store/actions/circle/circleActions';
import { useTranslation } from 'react-i18next';
import PinsMarker from './PinsMarker';
import { Link } from 'react-router-dom';
const MapForCircles = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allCircles = useSelector((state) => state.circle?.allCircles);
  const allCirclesLoading = useSelector((state) => state.circle?.allCirclesLoading);
  const key = 'pk.eyJ1IjoibWlyYWZhbCIsImEiOiJjbGRjcmw5b2UwMTN5M29udHo5YjQyeG05In0.WwbPL55DtsGnoriCXqA6-w';
  const [lng, setLng] = useState(54.37585762735543);
  const [lat, setLat] = useState(24.45677614934833);
  const [popupInfo, setPopupInfo] = useState(null);

  useEffect(() => {
    dispatch(getAllCircles());
  }, [dispatch]);

  const pins = useMemo(() => {
    return allCircles.map((item, idx) => {
      return (
        <>
          {item?.location && (
            <>
              <Marker
                style={{ cursor: 'pointer' }}
                longitude={item?.location.longitude}
                latitude={item?.location.latitude}
                onClick={(e) => {
                  e.originalEvent.stopPropagation();
                  setPopupInfo(item);
                }}
              >
                <PinsMarker />
              </Marker>
            </>
          )}
        </>
      );
    });
  }, [allCircles]);

  return (
    <div className="content">
      <Spin spinning={allCirclesLoading}>
        <div style={{ width: '100%' }}>
          <Map
            mapboxAccessToken="pk.eyJ1IjoiY3ljbG9jY2lubyIsImEiOiJjbGI0cWhjbHQwY3YwM3Zxc2EyNjA5OTJnIn0.5RM8MEXQQLl6iNTpSeqokg"
            style={{
              width: '100%',
              height: '500px',
              borderRadius: '15px',
              border: '2px solid #ccc',
            }}
            initialViewState={{
              longitude: 2.2137,
              latitude: 46.2276,
              zoom: 4,
            }}
            // mapStyle="mapbox://styles/mapbox/streets-v9"
            mapStyle="mapbox://styles/cycloccino/clbnd7ulk001g14nwetcitvcp"
          >
            {pins}
            {popupInfo && (
              <Popup
                anchor="top"
                longitude={Number(popupInfo?.location.longitude)}
                latitude={Number(popupInfo?.location.latitude)}
                onClose={() => setPopupInfo(null)}
              >
                <div style={{ padding: '10px 25px' }}>
                  <p>{popupInfo?.name}</p>
                  <p>
                    {t('tableColumns.numberOfRaces')}: {popupInfo?.racesCount}
                  </p>
                  <Link to={`/details/circle/${popupInfo?.id}`} className="circle-details details-btn">
                    {t('buttons.details')}
                  </Link>
                </div>
              </Popup>
            )}

            <NavigationControl position="bottom-right" />
            <FullscreenControl />
            <GeolocateControl />
          </Map>
        </div>
      </Spin>
    </div>
  );
};

export default MapForCircles;
