import React from 'react';
import { ReactComponent as RouteSvg } from '../../assets/route.svg';
import { ReactComponent as AscendingSvg } from '../../assets/ascending.svg';
import { Card, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { UnorderedListOutlined } from '@ant-design/icons';
import { daysRemaining } from '../../utils/functions';
import { ReactComponent as CupSVG } from '../../assets/ranking-cup.svg';
import { useTranslation } from 'react-i18next';
import { apiUrl } from '../../utils/config';
import './styles.scss';
import LocaleDate from '../LocaleDate/LocaleDate';

const SingleRace = ({
  name,
  startDate,
  endDate,
  distance,
  elevation,
  id,
  segments,
  topRaces,
  totalRacers,
  finished,
  circleName,
  circleId,
  raceType,
  isCupRace,
}) => {
  const { t } = useTranslation();

  const text = () => (
    <div className="race-header__info">
      <div className="col-1">
        <div className="names">
          {name?.length >= 34 ? (
            <Tooltip placement="bottom" title={name}>
              <Link className="m-b-10 race-name" to={`/details/race/${id}`}>
                {name}
              </Link>
            </Tooltip>
          ) : (
            <Link className="m-b-10 race-name" to={`/details/race/${id}`}>
              {name}
            </Link>
          )}
          <Link className="circle-name" to={`/details/circle/${circleId}`}>
            {circleName}
          </Link>
        </div>
        {isCupRace && <CupSVG width={45} />}
      </div>
      <div className="col-2">
        <p>
          {finished ? (
            t('finishedRace')
          ) : (
            <>
              {endDate && (
                <>
                  {t('remaining')} {daysRemaining(new Date(endDate), t('days'), t('hours'))}
                </>
              )}
            </>
          )}
        </p>
      </div>
    </div>
  );

  return (
    <Card title={text()} className="cupItem">
      <Link className="details-btn" to={`/details/race/${id}`}>
        {t('buttons.details')}
      </Link>
      <div className="race-information m-t-10">
        <div className="preview-race__img-container">
          <img src={`${apiUrl}race/preview/${id}?type=2`} alt="preview-race" className="preview-race__img" />
        </div>

        <div className="locale-date__container">
          {raceType === 1 ? (
            <LocaleDate date={new Date(startDate)} />
          ) : (
            <>
              <LocaleDate date={new Date(startDate)} /> <div style={{ fontSize: '15px' }}>/</div>
              <LocaleDate date={new Date(endDate)} />
            </>
          )}
        </div>

        <div className="wrapper">
          <div className="col-1">
            {segments > 0 && (
              <div className="item">
                <div className="item__svg">
                  <UnorderedListOutlined width={20} height={20} />
                </div>
                <div className="item__text">{segments} segments</div>
              </div>
            )}

            <div className="item">
              <div className="item__svg">
                <RouteSvg width={20} height={20} />
              </div>
              <div className="item__text">{Number(distance / 1000).toFixed(2)} km</div>
            </div>
            <div className="item">
              <div className="item__svg">
                <AscendingSvg width={20} height={20} />
              </div>
              <div className="item__text">{elevation.toFixed(0)} m</div>
            </div>
          </div>
          <div className="col-2">
            {totalRacers !== 0 && (
              <>
                <p>
                  {t('racers')}: {totalRacers}
                </p>
                {topRaces?.map((racer) => {
                  return (
                    <div className="racer__item">
                      <p className="m-r-5">
                        {racer?.rank}: {racer?.user.firstName} {racer?.user.lastName}
                      </p>

                      <b className="display-team__text">
                        <Link to={`/team/details/${racer.user?.currentTeam?.id}`}>
                          {racer.user?.currentTeam?.shortName && racer.user?.currentTeam?.shortName}
                        </Link>
                      </b>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default SingleRace;
