export const GET_TEAMS_REQUEST = 'GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAILURE = 'GET_TEAMS_FAILURE';

export const GET_MY_TEAM_REQUEST = 'GET_MY_TEAM_REQUEST';
export const GET_MY_TEAM_SUCCESS = 'GET_MY_TEAM_SUCCESS';
export const GET_MY_TEAM_FAILURE = 'GET_MY_TEAM_FAILURE';

export const GET_TEAM_ID_REQUEST = 'GET_TEAM_ID_REQUEST';
export const GET_TEAM_ID_SUCCESS = 'GET_TEAM_ID_SUCCESS';
export const GET_TEAM_ID_FAILURE = 'GET_TEAM_ID_FAILURE';

export const GET_USER_INVITE_REQUEST = 'GET_USER_INVITE_REQUEST';
export const GET_USER_INVITE_SUCCESS = 'GET_USER_INVITE_SUCCESS';
export const GET_USER_INVITE_FAILURE = 'GET_USER_INVITE_FAILURE';
