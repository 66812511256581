import { Button, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { approveCircle, getCircleByID, rejectCircle } from '../../../store/actions/circle/circleActions';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const CircleMembers = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.circle?.singleCircleLoading);
  const circle = useSelector((state) => state.circle?.singleCircle);
  const [members, setMembers] = useState([]);

  const approveHandler = async (id, userId) => {
    const response = await dispatch(approveCircle(id, userId));

    if (response) dispatch(getCircleByID(id));
  };
  const rejectHandler = async (id, userId) => {
    const response = await dispatch(rejectCircle(id, userId));
    if (response) dispatch(getCircleByID(id));
  };

  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '220px',
      render: (_, record) => (
        <div>
          {record.user.firstName} {record.user.lastName}
        </div>
      ),
    },
    {
      title: t('tableColumns.team'),
      dataIndex: 'team',
      key: 'team',
      width: '220px',
      render: (_, record) => {
        return (
          <>
            {record?.user?.currentTeam ? (
              <Link to={`/team/details/${record?.user?.currentTeam.id}`}>{record?.user?.currentTeam?.shortName}</Link>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: t('tableColumns.role'),
      dataIndex: 'Role',
      key: 'description',
      width: '220px',
      render: (_, record) => <div>{record.role.localizationKey}</div>,
    },
    {
      title: t('tableColumns.actions'),
      dataIndex: 'Actions',
      key: 'Actions',
      render: (_, record) => (
        <div className="circle-table__actions">
          {record.role.localizationKey !== 'OWNER' && (
            <>
              <Button className="approve-btn" onClick={() => approveHandler(id, record.user.id)}>
                {t('buttons.approve')}
              </Button>
              <Button className="reject-btn" onClick={() => rejectHandler(id, record.user.id)}>
                {t('buttons.reject')}
              </Button>
            </>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getCircleByID(id));
  }, [dispatch, id]);
  useEffect(() => {
    if (circle) {
      setMembers(circle?.members);
    }
  }, [circle]);
  return (
    <div className="content">
      <Spin spinning={loading}>
        <h2 className="title">{circle.name}</h2>
        <div className="m-t-100">
          <Table columns={columns} dataSource={members} rowKey={'stravaId'} />
        </div>
      </Spin>
    </div>
  );
};

export default CircleMembers;
