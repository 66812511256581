import { Button, Checkbox, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createTeam } from '../../../store/actions/team/teamActions';
import './styles.scss';
const { TextArea } = Input;

const CreateTeam = () => {
  const { t } = useTranslation();
  const [teamName, setTeamName] = useState(null);
  const [shortName, setShortName] = useState(null);
  const [description, setDescription] = useState(null);
  const [isRecruiting, setIsRecruiting] = useState(true);
  const dispatch = useDispatch();

  const createTeamHandler = async () => {
    const response = await dispatch(createTeam({ name: teamName, shortName, description, isRecruiting }));
    if (response) {
      setTeamName(null);
      setShortName(null);
    }
  };
  return (
    <div className="content">
      <h2 className="title m-b-30">{t('createTeam')}</h2>
      <div className="create-team__container">
        <Form onFinish={createTeamHandler}>
          <div className="m-b-10">
            <Form.Item
              name="teamName"
              className="m-b-15"
              rules={[
                {
                  required: true,
                  message: t('errors.inputTeam'),
                },
              ]}
            >
              <Input
                size="large"
                placeholder={t('teamName')}
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
              />
            </Form.Item>
          </div>

          <div className="m-b-10">
            <Form.Item
              name="shortName"
              className="m-b-15"
              rules={[
                {
                  required: true,
                  message: t('errors.inputShortName'),
                },
                {
                  max: 10,
                  message: t('errors.maxChars'),
                },
              ]}
            >
              <Input
                size="large"
                placeholder={t('shortName')}
                value={shortName}
                onChange={(e) => setShortName(e.target.value)}
              />
            </Form.Item>
          </div>

          <div className="m-b-10">
            <Form.Item
              name="description"
              className="m-b-15"
              rules={[
                {
                  required: true,
                  message: t('errors.inputDescription'),
                },
              ]}
            >
              <TextArea
                size="large"
                autoSize
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={4}
              />
            </Form.Item>
          </div>
          <div>
            <Checkbox
              style={{ fontSize: '13px', textTransform: 'uppercase' }}
              value={isRecruiting}
              checked={isRecruiting}
              onChange={() => setIsRecruiting((prev) => !prev)}
            >
              Is Recruiting
            </Checkbox>
          </div>

          <div style={{ textAlign: 'right' }}>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              {/* <Button onClick={createTeamHandler} type="primary" size="large">
                Create Team
              </Button> */}
              <Button htmlType="submit" type="primary" size="large">
                {t('buttons.createTeamButton')}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateTeam;
