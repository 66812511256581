export const GET_RACES_REQUEST = 'GET_RACES_REQUEST';
export const GET_RACES_SUCCESS = 'GET_RACES_SUCCESS';
export const GET_RACES_FAILURE = 'GET_RACES_FAILURE';

export const GET_SINGLE_RACE_REQUEST = 'GET_SINGLE_RACE_REQUEST';
export const GET_SINGLE_RACE_SUCCESS = 'GET_SINGLE_RACE_SUCCESS';
export const GET_SINGLE_RACE_FAILURE = 'GET_SINGLE_RACE_FAILURE';

export const GET_ACTIVITY_REQUEST = 'GET_ACTIVITY_REQUEST';
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
export const GET_ACTIVITY_FAILURE = 'GET_ACTIVITY_FAILURE';

export const GET_SEGMENT_REQUEST = 'GET_SEGMENT_REQUEST';
export const GET_SEGMENT_SUCCESS = 'GET_SEGMENT_SUCCESS';
export const GET_SEGMENT_FAILURE = 'GET_SEGMENT_FAILURE';

export const GET_ACTIVITY_BY_ID_REQUEST = 'GET_ACTIVITY_BY_ID_REQUEST';
export const GET_ACTIVITY_BY_ID_SUCCESS = 'GET_ACTIVITY_BY_ID_SUCCESS';
export const GET_ACTIVITY_BY_ID_FAILURE = 'GET_ACTIVITY_BY_ID_FAILURE';

export const COMPLETE_RACE_REQUEST = 'COMPLETE_RACE_REQUEST';
export const COMPLETE_RACE_SUCCESS = 'COMPLETE_RACE_SUCCESS';
export const COMPLETE_RACE_FAILURE = 'COMPLETE_RACE_FAILURE';

export const GET_ROUTE_REQUEST = 'GET_ROUTE_REQUEST';
export const GET_ROUTE_SUCCESS = 'GET_ROUTE_SUCCESS';
export const GET_ROUTE_FAILURE = 'GET_ROUTE_FAILURE';

export const GET_SINGLE_ROUTE_REQUEST = 'GET_SINGLE_ROUTE_REQUEST';
export const GET_SINGLE_ROUTE_SUCCESS = 'GET_SINGLE_ROUTE_SUCCESS';
export const GET_SINGLE_ROUTE_FAILURE = 'GET_SINGLE_ROUTE_FAILURE';
