import { Button, Checkbox, Input, InputNumber, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCircleByID, updateCircle } from '../../store/actions/circle/circleActions';
import './styles.scss';

const EditCircle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.circle?.singleCircleLoading);
  const circle = useSelector((state) => state.circle?.singleCircle);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isPublic, setIsPublic] = useState(null);
  const [members, setMembers] = useState('');
  const [parentId, setParentId] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');

  const updateCircleHandler = () => {
    dispatch(
      updateCircle({
        id,
        name,
        description,
        isPublic,
        location: {
          latitude,
          longitude,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getCircleByID(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (circle) {
      setName(circle?.name);
      setDescription(circle?.description);
      setIsPublic(circle?.isPublic);
      setMembers(circle?.members);
      setParentId(circle?.parent);
      setLongitude(circle?.location?.longitude);
      setLatitude(circle?.location?.latitude);
    }
  }, [circle]);
  return (
    <div className="content">
      <Spin spinning={loading}>
        {circle && (
          <div className="circle-form">
            <h2 className="title">Edit circle</h2>
            <Input value={name} placeholder="Circle name" onChange={(e) => setName(e.target.value)} />
            <Input value={description} placeholder="Description" onChange={(e) => setDescription(e.target.value)} />
            <InputNumber
              size="large"
              placeholder="longitude"
              onChange={(e) => setLongitude(e)}
              value={longitude}
              controls={false}
              style={{ width: '100%' }}
            />
            <InputNumber
              size="large"
              placeholder="latitude"
              onChange={(e) => setLatitude(e)}
              value={latitude}
              controls={false}
              style={{ width: '100%' }}
            />
            <Checkbox checked={!isPublic} onChange={() => setIsPublic((prev) => !prev)} value={isPublic}>
              Private circle
            </Checkbox>
            <Button className="btn" onClick={updateCircleHandler}>
              Save
            </Button>
            {/* <Input value={parentId} placeholder="Parent id" onChange={(e) => setParentId(e.target.value)} /> */}
          </div>
        )}
      </Spin>
    </div>
  );
};

export default EditCircle;
