import { Button, Spin, Table } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCircles, deleteCircles } from '../../../store/actions/circle/circleActions';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { AppContext } from '../../../appContext';
import { useTranslation } from 'react-i18next';
import '../styles.scss';

const ManageCircles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAdmin, isRaceOrganazier } = useContext(AppContext);
  const circles = useSelector((state) => state.circle?.managedCircles?.items);
  const loading = useSelector((state) => state.circle.managedCirclesLoading);

  const del = async (id) => {
    const res = await dispatch(deleteCircles(id));
    if (res) dispatch(getCircles());
  };

  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '220px',
      render: (_, record) => <>{record?.name}</>,
    },
    {
      title: t('tableColumns.description'),
      dataIndex: 'description',
      key: 'description',
      width: '220px',
      render: (_, record) => <>{record?.description}</>,
    },
    {
      title: t('tableColumns.members'),
      dataIndex: 'membersCount',
      key: 'membersCount',
      width: '100px',
      render: (_, record) => <>{record?.membersCount}</>,
    },
    {
      title: t('tableColumns.races'),
      dataIndex: 'racesCount',
      key: 'racesCount',
      width: '100px',
      render: (_, record) => <>{record?.racesCount}</>,
    },
    {
      title: t('tableColumns.status'),
      dataIndex: 'Status',
      key: 'Status',
      render: (_, record) => <>{record?.isPublic ? 'Public' : 'Private'}</>,
    },
    {
      title: `${isAdmin ? t('tableColumns.owner') : ''}`,
      dataIndex: 'owner',
      key: 'owner',
      render: (_, record) => <>{isAdmin && record.owner}</>,
    },
    {
      title: t('tableColumns.actions'),
      dataIndex: 'Actions',
      key: 'Actions',
      width: '80px',
      render: (_, record) => (
        <div className="circle-table__actions">
          <Link style={{ color: '#000' }} to={`/circle/edit/${record.id}`}>
            <EditOutlined className="ic-antd edit" />
          </Link>
          <Link to={`/circle/members/${record.id}`}>{t('tableColumns.members')}</Link>
          {record?.role?.localizationKey === 'OWNER' && (
            <>
              <DeleteOutlined className="ic-antd delete" onClick={() => del(record.id)} />
            </>
          )}
        </div>
      ),
    },
  ];
  useEffect(() => {
    dispatch(getCircles());
  }, [dispatch]);

  return (
    <div className="content">
      <h2 className="title">{t('navigation.manageCircle')}</h2>
      <div className="m-t-30">
        {(isAdmin || isRaceOrganazier) && (
          <Button>
            <Link to="/circles/new">{t('buttons.createCircle')}</Link>
          </Button>
        )}
      </div>

      <Spin spinning={loading}>
        <div className="m-t-40">
          <Table columns={columns} dataSource={circles} rowKey={'id'} />
        </div>
      </Spin>
    </div>
  );
};

export default ManageCircles;
