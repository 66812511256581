import { Button, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { chooseRaceRoute, getSingleRace } from '../../store/actions/manageRaces/manageRaces';
import './styles.scss';

const RoutesTab = ({ routes, id, allowToChooseRoute }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const chooseRaceRouteHandler = async (routeId) => {
    const response = await dispatch(chooseRaceRoute({ raceId: id, routeId }));
    if (response) dispatch(getSingleRace(id));
  };
  const columns = [
    {
      title: t('tableColumns.route'),
      dataIndex: 'route',
      key: 'route',
      render: (_, record) => {
        return (
          <>
            {record?.route ? (
              <a
                href={`https://www.strava.com/routes/${record?.route?.stravaId}`}
                target="_blank"
                style={{ fontSize: '14px' }}
                rel="noreferrer"
              >
                {record?.route?.name}
              </a>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Laps',
      dataIndex: 'laps',
      key: 'laps',
      render: (_, record) => <>{record?.lapsCount}</>,
    },
    {
      title: t('tableColumns.distance'),
      dataIndex: 'distance',
      key: 'distance',
      render: (_, record) => <>{Number(record?.route?.distance / 1000).toFixed(2)} km</>,
    },
    {
      title: t('tableColumns.elevation'),
      dataIndex: 'elevation',
      key: 'elevation',
      render: (_, record) => <>{Number(record?.route?.elevation).toFixed(0)} km</>,
    },
    {
      title: t('tableColumns.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => {
        return (
          allowToChooseRoute && (
            <Button onClick={() => chooseRaceRouteHandler(record?.route.id)}>{t('buttons.apply')}</Button>
          )
        );
      },
    },
  ];
  return <Table columns={columns} dataSource={routes} />;
};

export default RoutesTab;
