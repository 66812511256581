import { Button, Checkbox, Input, InputNumber } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createCircle } from '../../../store/actions/circle/circleActions';
import { useTranslation } from 'react-i18next';

const CreateCircle = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const createCircleHandler = async () => {
    const response = await dispatch(
      createCircle({
        name,
        description,
        isPublic,
        parentId: null /*временно стоит позже убрать*/,
        location: {
          latitude: latitude === '' ? null : latitude,
          longitude: longitude === '' ? null : longitude,
        },
      })
    );
    if (response) {
      setName('');
      setDescription('');
      setIsPublic((prev) => !prev);
    }
  };
  return (
    <div className="content">
      <div className="circle-form">
        <h2 className="title">{t('buttons.createCircle')}</h2>
        <Input size="large" value={name} placeholder="Circle name" onChange={(e) => setName(e.target.value)} />
        <Input
          size="large"
          value={description}
          placeholder="Description"
          onChange={(e) => setDescription(e.target.value)}
        />
        <InputNumber
          size="large"
          placeholder="longitude"
          onChange={(e) => setLongitude(e)}
          value={longitude}
          controls={false}
          style={{ width: '100%' }}
        />
        <InputNumber
          size="large"
          placeholder="latitude"
          onChange={(e) => setLatitude(e)}
          value={latitude}
          controls={false}
          style={{ width: '100%' }}
        />
        <Checkbox onChange={() => setIsPublic((prev) => !prev)} value={isPublic} checked={!isPublic}>
          {t('private')} circle
        </Checkbox>
        <Button className="btn" onClick={createCircleHandler}>
          {t('buttons.create')}
        </Button>
        {/* <Input value={parentId} placeholder="Parent id" onChange={(e) => setParentId(e.target.value)} /> */}
      </div>
    </div>
  );
};

export default CreateCircle;
