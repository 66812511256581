import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTeamId,
  joinTeam,
  leaveTeam,
  teamInviteAccept,
  teamInviteReject,
} from '../../store/actions/team/teamActions';
import { useParams } from 'react-router-dom';
import { Button, Modal, Spin } from 'antd';
import Members from '../Admin/TeamMembers/Tables/Members';
import NoResult from '../../components/NoResult/NoResult';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import InviteUsers from './InviteUsers';

const TeamDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const detail = useSelector((state) => state.team.teamId);
  const loading = useSelector((state) => state.team.teamIdLoading);
  const userId = useSelector((state) => state.user?.profile?.profile?.nameid);
  const [inviteModal, setInviteModal] = useState(false);

  const openModalHandler = () => {
    setInviteModal((prev) => !prev);
  };

  const joinTeamHandler = () => {
    dispatch(joinTeam(id));
  };

  const acceptInviteHandler = async () => {
    const response = await dispatch(teamInviteAccept(id));
    if (response) dispatch(getTeamId(id));
  };
  const rejecttInviteHandler = async () => {
    const response = await dispatch(teamInviteReject(id));
    if (response) dispatch(getTeamId(id));
  };
  const leaveTeamHandler = async () => {
    const response = await dispatch(leaveTeam());
    if (response) dispatch(getTeamId(id));
  };
  useEffect(() => {
    if (id) {
      dispatch(getTeamId(id));
    }
  }, [dispatch, id]);

  return (
    <div className="content">
      <Spin size="large" spinning={loading}>
        <h1 className="title edit-text__title">{detail?.name}</h1>
        <h2 className="title edit-text__title">{detail?.shortName}</h2>
        <div className="buttons-group__wrapper">
          {detail?.allowToAcceptInvite && (
            <div className="m-t-10">
              <Button className="sub m-r-5" onClick={acceptInviteHandler}>
                Accept Invite
              </Button>
              <Button className="unsub" onClick={rejecttInviteHandler}>
                Reject Invite
              </Button>
            </div>
          )}
          {detail?.allowToJoin && !detail?.allowToAcceptInvite && (
            <Button className="sub join__btn" onClick={joinTeamHandler}>
              Join
            </Button>
          )}
          {detail?.allowToLeave && !detail?.allowToAcceptInvite && (
            <Button className="unsub join__btn" onClick={leaveTeamHandler}>
              Leave
            </Button>
          )}
          {detail?.owner?.id === userId && <Button onClick={openModalHandler}>{t('buttons.inviteUsers')}</Button>}
        </div>
        <div className="race-oncircle__info m-b-55">
          <div className="table-element">
            <div className="table__item" style={{ width: '100%' }}>
              <h3 style={{ fontSize: '22px' }}>{t('tableColumns.owner')}</h3>
              <span>
                {detail?.owner?.firstName} {detail?.owner?.lastName}
              </span>
            </div>
          </div>
        </div>
        {detail?.members?.length !== 0 ? (
          <Members id={id} members={detail?.members} ownerId={detail?.owner?.id} />
        ) : (
          <NoResult text="No Members" />
        )}
      </Spin>
      <Modal open={inviteModal} onCancel={openModalHandler} footer={null} style={{ height: '500px' }}>
        <InviteUsers />
      </Modal>
    </div>
  );
};

export default TeamDetail;
