export const GET_CIRCLES_REQUEST = 'GET_CIRCLES_REQUEST';
export const GET_CIRCLES_SUCCESS = 'GET_CIRCLES_SUCCESS';
export const GET_CIRCLES_FAILURE = 'GET_CIRCLES_FAILURE';

export const GET_SINGLE_CIRCLE_REQUEST = 'GET_SINGLE_CIRCLE_REQUEST';
export const GET_SINGLE_CIRCLE_SUCCESS = 'GET_SINGLE_CIRCLE_SUCCESS';
export const GET_SINGLE_CIRCLE_FAILURE = 'GET_SINGLE_CIRCLE_FAILURE';

export const GET_ALL_CIRCLE_REQUEST = 'GET_ALL_CIRCLE_REQUEST';
export const GET_ALL_CIRCLE_SUCCESS = 'GET_ALL_CIRCLE_SUCCESS';
export const GET_ALL_CIRCLE_FAILURE = 'GET_ALL_CIRCLE_FAILURE';
