import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Select, Spin, Table } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getCircles } from '../../../store/actions/circle/circleActions';
import { getCupByID, updateCupByID } from '../../../store/actions/cup/cupActions';
import { deleteRace } from '../../../store/actions/manageRaces/manageRaces';
import './styles.scss';
import moment from 'moment';
const { TextArea } = Input;
const { Option } = Select;

const EditCup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const cupById = useSelector((state) => state.cup?.cupByID);
  const loading = useSelector((state) => state.cup?.cupByIDLoading);
  const circles = useSelector((state) => state.circle?.managedCircles?.items);
  const circlesLoading = useSelector((state) => state.circle.managedCirclesLoading);
  //
  const [cupName, setCupName] = useState(null);
  const [description, setDescription] = useState(null);
  const [circleId, setCircleId] = useState(null);
  const [teamMembersForRanking, setTeamMembersForRanking] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [close, setClose] = useState('');

  const removeRaceFromCup = (id) => {
    dispatch({ type: 'EDIT_CUP_RACES', id });
    dispatch(deleteRace(id));
  };

  const addExtraRace = () => {
    navigate(`/add-race/${id}`);
  };

  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <>{record?.name}</>,
    },
    {
      title: t('tableColumns.segments'),
      dataIndex: 'segments',
      key: 'segments',
      render: (_, record) => <>{record?.segmentsCount}</>,
    },
    {
      title: t('tableColumns.cupCoefficient'),
      dataIndex: 'cupCoefficient',
      key: 'cupCoefficient',
      render: (_, record) => <>{record.cupCoefficient}</>,
    },
    {
      title: t('tableColumns.bonusParticipating'),
      dataIndex: 'completedBonus',
      key: 'completedBonus',
      render: (_, record) => <>{record.cupCompletedBonus}</>,
    },
    {
      title: t('tableColumns.bonusWinner'),
      dataIndex: 'winnerBonus',
      key: 'winnerBonus',
      render: (_, record) => <>{record.cupWinnerBonus}</>,
    },
    {
      title: t('tableColumns.actions'),
      dataIndex: 'action',
      key: 'actions',
      render: (_, record) => (
        <div className="table-actions">
          <Link style={{ color: '#000' }} className="edit-btn" to={`/edit/race/${record.id}`}>
            <EditOutlined className="ic-antd edit m-r-5" />
          </Link>
          <DeleteOutlined onClick={() => removeRaceFromCup(record?.id)} className="ic-antd delete" />
        </div>
      ),
    },
  ];
  //
  const updateCupHandler = () => {
    dispatch(updateCupByID({ name: cupName, description, circleId: circleId, isPublished, id, teamMembersForRanking }));
  };

  useEffect(() => {
    dispatch(getCupByID(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getCircles(true));
  }, [dispatch]);

  useEffect(() => {
    if (cupById) {
      setCupName(cupById?.name);
      setDescription(cupById?.description);
      setCircleId(cupById?.circle?.id);
      setIsPublished(cupById?.isPublished);
      setTeamMembersForRanking(cupById?.teamMembersForRanking);
      setStart(cupById?.defaultStartDate);
      setEnd(cupById?.defaultEndDate);
      setClose(cupById?.defaultCloseDate);
    }
  }, [cupById]);

  return (
    <div className="content">
      <div className="addrace-form">
        <Spin spinning={loading} size="large">
          <h2 className="title m-b-15">{t('titles.editCup')}</h2>
          {cupById && (
            <Form onFinish={updateCupHandler}>
              <div className="main-information">
                <div className="race-name__block">
                  <Form.Item
                    name="cupName"
                    className="m-b-15"
                    initialValue={cupById?.name}
                    rules={[
                      {
                        required: true,
                        message: t('errors.inputCupName'),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t('cupName')}
                      value={cupName}
                      onChange={(e) => setCupName(e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="race-name__block">
                  <Form.Item
                    name="description"
                    className="m-b-15"
                    initialValue={cupById?.description}
                    rules={[
                      {
                        required: true,
                        message: t('errors.inputDescription'),
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder={t('Description')}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      rows={4}
                    />
                  </Form.Item>
                </div>
                <div className="first-row cup-row__wrapper">
                  <InputNumber
                    className="strava-input route-input"
                    placeholder={t('buttons.teamRanking')}
                    style={{ width: '33%' }}
                    size="large"
                    value={teamMembersForRanking}
                    onChange={(value) => setTeamMembersForRanking(value)}
                    min={0}
                    max={100}
                  />
                  <Checkbox onChange={() => setIsPublished((prev) => !prev)} value={isPublished} checked={isPublished}>
                    {t('buttons.publishCup')}
                  </Checkbox>
                  <div>
                    <Select
                      allowClear
                      style={{ width: '250px', height: '43px !important' }}
                      onChange={(e) => setCircleId(e)}
                      placeholder={t('filterRace.circle')}
                      size="large"
                      value={circleId}
                    >
                      {circles?.map((elem) => (
                        <>{!circlesLoading && <Option key={elem.id}>{elem.name}</Option>}</>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="first-row m-b-20">
                  <DatePicker
                    size="large"
                    format="YYYY-MM-DD"
                    placeholder="start activity date"
                    defaultValue={null}
                    value={start !== '' ? moment(start) : ''}
                    onChange={(date, dateString) => setStart(dateString)}
                    style={{ width: '33%' }}
                  />
                  <DatePicker
                    size="large"
                    format="YYYY-MM-DD"
                    placeholder="end activity date"
                    defaultValue={null}
                    value={end !== '' ? moment(end) : ''}
                    onChange={(date, dateString) => setEnd(dateString)}
                    style={{ width: '33%' }}
                  />
                  <DatePicker
                    size="large"
                    format="YYYY-MM-DD"
                    placeholder="close activity date"
                    defaultValue={null}
                    value={close !== '' ? moment(close) : ''}
                    onChange={(date, dateString) => setClose(dateString)}
                    style={{ width: '33%' }}
                  />
                </div>
              </div>
              {!_.isEmpty(cupById?.races) && <Table columns={columns} dataSource={cupById?.races} rowKey={'id'} />}
              <div className="edit-cup__footer">
                <Button type="primary" size="large" onClick={addExtraRace}>
                  {t('buttons.addRace')}
                </Button>
                <Button type="primary" htmlType="submit" size="large">
                  {t('buttons.save')}
                </Button>
              </div>
            </Form>
          )}
        </Spin>
      </div>
    </div>
  );
};

export default EditCup;
