import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../appContext';
import './styles.scss';

const AboutUs = () => {
  const url = process.env.REACT_APP_REDIRECT_URL;
  const key = process.env.REACT_APP_STRAVA_KEY;
  const { isLoggedIn } = useContext(AppContext);
  const handleLogin = () => {
    window.location = `http://www.strava.com/oauth/authorize?client_id=${key}&response_type=code&redirect_uri=${url}/exchange_token&approval_prompt=auto&scope=read,activity:read_all,activity:write`;
  };
  const { t } = useTranslation();
  return (
    <div className="content">
      {isLoggedIn ? (
        <div className="connect-strava__btn"></div>
      ) : (
        <div className="connect-strava__btn" onClick={handleLogin}></div>
      )}

      <h2 className="title m-b-40">{t('aboutUs.title')}</h2>
      <div className="m-b-5 aboutUs__text">
        <p>
          <b>{t('aboutUs.question1')}</b>
        </p>
        <p className="aboutUs__answer">{t('aboutUs.answer1')}</p>
      </div>
      <div className="m-t-45 aboutUs__text">
        <p className="aboutUs__answer">
          <b>{t('aboutUs.subTitle')}</b>
        </p>
      </div>
      <div className="m-b-5 aboutUs__text">
        <p>
          <b>{t('aboutUs.question2')}</b>
        </p>
        <p className="aboutUs__answer">{t('aboutUs.answer2')}</p>
      </div>
      <div className="m-b-5 aboutUs__text">
        <p>
          <b>{t('aboutUs.question3')}</b>
        </p>
        <p className="aboutUs__answer">{t('aboutUs.answer3')}</p>
      </div>
      <div className="aboutUs__text">
        <p>
          <b>{t('aboutUs.question4')}</b>
        </p>
        <p className="aboutUs__answer">{t('aboutUs.answer4')}</p>
      </div>
    </div>
  );
};

export default AboutUs;
