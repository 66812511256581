import React, { useEffect } from 'react';
import { Radio, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getUserRaces } from '../../../store/actions/user/userActions';

const UserRacesTab = () => {
  const { t } = useTranslation();
  const myRaces = useSelector((state) => state.user?.myRaces);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserRaces(true, null, null, null, null));
  }, [dispatch]);

  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '50%',
      render: (_, record) => <Link to={`/details/race/${record.id}`}>{record?.name}</Link>,
    },
  ];
  return (
    <>
      <div className="filter-races-block m-b-30">
        <Radio.Group onChange={(e) => ''} defaultValue={null}>
          <Radio value={null} onClick={(e) => dispatch(getUserRaces(true, null, null, null, null))}>
            All types
          </Radio>
          <Radio value={1} onClick={(e) => dispatch(getUserRaces(null, null, null, null, true))}>
            Done
          </Radio>
          <Radio
            value={2}
            onClick={(e) => dispatch(getUserRaces(true, null, new Date(Date.now() + 3600 * 1000 * 24), null, null))}
          >
            Coming
          </Radio>
          <Radio
            value={3}
            onClick={(e) => dispatch(getUserRaces(true, null, new Date(Date.now()), new Date(Date.now()), null))}
          >
            In progress
          </Radio>
          <Radio value={4} onClick={(e) => dispatch(getUserRaces(true, 0, null, null, null))}>
            RacePresso
          </Radio>
          <Radio value={5} onClick={(e) => dispatch(getUserRaces(true, 1, null, null, null))}>
            Cyclosportive
          </Radio>
        </Radio.Group>
      </div>
      <Table dataSource={_.sortBy(myRaces, 'rank')} columns={columns} />
    </>
  );
};

export default UserRacesTab;
