import { Button, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NoResult from '../../components/NoResult/NoResult';
import { getMyTeam, leaveTeam } from '../../store/actions/team/teamActions';
import Members from '../Admin/TeamMembers/Tables/Members';
import NoTeam from './NoTeam';
import './styles.scss';

const MyTeam = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myTeam = useSelector((state) => state.team.myTeam);
  const loading = useSelector((state) => state.team.myTeamLoading);

  const leaveTeamHandler = () => {
    dispatch(leaveTeam());
  };
  useEffect(() => {
    dispatch(getMyTeam());
  }, [dispatch]);
  return (
    <div className="content">
      <Spin size="large" spinning={loading}>
        {myTeam && !loading ? (
          <>
            <h1 className="title edit-text__title">{myTeam?.name}</h1>
            <h2 className="title edit-text__title">{myTeam?.shortName}</h2>
            <div className="buttons-group__wrapper">
              {myTeam?.allowToLeave && (
                <Button className="unsub join__btn" onClick={leaveTeamHandler}>
                  Leave
                </Button>
              )}
            </div>
            <div className="race-oncircle__info m-b-55">
              <div className="table-element">
                <div className="table__item" style={{ width: '100%' }}>
                  <h3 style={{ fontSize: '22px' }}>{t('tableColumns.owner')}</h3>
                  <span>
                    {myTeam?.owner?.firstName} {myTeam?.owner?.lastName}
                  </span>
                </div>
              </div>
            </div>
            {myTeam?.members?.length !== 0 ? (
              <Members id={myTeam?.id} members={myTeam?.members} />
            ) : (
              <NoResult text="No Members" />
            )}
          </>
        ) : (
          <NoTeam />
        )}
      </Spin>
    </div>
  );
};

export default MyTeam;
