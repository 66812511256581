import { StarTwoTone } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import './styles.scss';

const DetailedInfo = ({ id, detail }) => {
  // let t = detail?.raceSegments.find((item) => item.segment.stravaId === id);

  const [information, setInformation] = useState(null);
  let users = [];
  const detailedInfo = useCallback(() => {
    let allUsers = detail?.raceUsers.filter((item) => item.finalResult !== null);
    if (!_.isEmpty(allUsers)) {
      let segmentInformation = detail?.raceSegments.find((item) => item.segment.stravaId === id);
      return segmentInformation?.raceUsers;
    } else {
      setInformation(null);
    }

    return users;
  }, [id, detail]);

  useEffect(() => {
    const info = detailedInfo();
    if (!_.isEmpty(info)) {
      const sorted = _.sortBy(info, 'rank');
      setInformation(sorted);
    }
  }, [detailedInfo]);

  return (
    <div>
      {information && (
        <>
          {information.map((elem) => {
            const fullName = `${elem.user?.firstName} ${elem.user?.lastName}`;
            let measuredTime = new Date(null);
            measuredTime.setSeconds(elem.elapsedTime);
            let MHSTime = measuredTime.toISOString().substr(11, 8);
            return (
              <div className="additional-details__container">
                <span>{fullName}</span>
                <span>{Number(elem.averageSpeed).toFixed(1)} km/h</span>
                <span>{MHSTime}</span>
                <span>{elem.rank}</span>
                <span className="segment-winner">
                  {elem.rank === 1 && (
                    <>
                      winner
                      <StarTwoTone style={{ width: '45px' }} />
                    </>
                  )}
                </span>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default DetailedInfo;
