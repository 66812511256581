import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';

const RacesOnCupInfo = ({ userId, racesArr }) => {
  const tmp = racesArr?.races;

  const [data, setData] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);

  const findAllRegisteredRaces = useCallback(() => {
    let registerdUsers = 0;
    const arr = [];
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].raceUsers.filter((item) => {
        if (item.user.id === userId && item.activity !== null) {
          let a = tmp[i].raceUsers.filter((item) => item.activity !== null);
          const obj = {
            id: tmp[i].id,
            raceName: tmp[i].name,
            coefficient: tmp[i].cupCoefficient,
            point: item.points,
            rank: item.rank,
            usersOnrace: a.length,
          };
          arr.push(obj);
        }
      });
    }

    return arr;
  }, [tmp, userId]);

  useEffect(() => {
    const info = findAllRegisteredRaces();
    if (info) {
      setData(_.sortBy(info, 'rank'));
    }
  }, [findAllRegisteredRaces]);

  return (
    <div>
      {data?.length ? (
        <>
          {data.map((item, idx) => {
            return (
              <div className="raceOnCup__container" key={idx}>
                <p>{item.raceName}</p>
                <p>
                  {item.rank ? item.rank : '- '}/ {item.usersOnrace}
                </p>
                <p>{item.coefficient}</p>
                <p>{item.point}</p>
              </div>
            );
          })}
        </>
      ) : (
        <h3 className="title">user have no result on this race</h3>
      )}
    </div>
  );
};

export default RacesOnCupInfo;
