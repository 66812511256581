import { Modal, Table } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TeamOnCupInfo from './TeamOnCupInfo';

const CupTeamsTab = ({ teams, data }) => {
  const { t } = useTranslation();
  const [teamId, setTeamId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const openModalHandler = (id) => {
    setTeamId(id);
    setShowModal((prev) => !prev);
  };

  const closeModalHandler = () => {
    setTeamId(null);
    setShowModal(false);
  };

  const columns = [
    {
      title: t('tableColumns.rank'),
      dataIndex: 'rank',
      key: 'rank',
      width: '120px',
      render: (_, record) => <>{record?.rank}</>,
    },
    {
      title: t('teamName'),
      dataIndex: 'teamName',
      key: 'teamName',
      render: (_, record) => <Link to={`/team/details/${record?.team?.id}`}>{record?.team?.shortName}</Link>,
    },
    {
      title: t('tableColumns.totalPoints'),
      dataIndex: 'totalPoints',
      key: 'totalPoints',
      render: (_, record) => (
        <p className="points__text" onClick={() => openModalHandler(record?.team.id)}>
          {record?.totalPoints}
        </p>
      ),
    },
  ];
  return (
    <>
      <Table columns={columns} dataSource={_.sortBy(teams, 'rank')} rowKey={'id'} />
      <Modal open={showModal} onCancel={closeModalHandler} footer={null} destroyOnClose={true}>
        {showModal && <TeamOnCupInfo data={data} teamId={teamId} />}
      </Modal>
    </>
  );
};

export default CupTeamsTab;
