import {
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_FAILURE,
  GET_MY_TEAM_REQUEST,
  GET_MY_TEAM_SUCCESS,
  GET_MY_TEAM_FAILURE,
  GET_TEAM_ID_REQUEST,
  GET_TEAM_ID_SUCCESS,
  GET_TEAM_ID_FAILURE,
  GET_USER_INVITE_REQUEST,
  GET_USER_INVITE_SUCCESS,
  GET_USER_INVITE_FAILURE,
} from '../actions/team/actionTypes';

const initialState = {
  teams: null,
  teamsLoading: false,
  myTeam: null,
  myTeamLoading: false,
  teamId: null,
  teamIdLoading: false,
  usersInvited: null,
  usersInvitedLoading: false,
};

const teamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEAMS_REQUEST:
      return { ...state, teamsLoading: true };
    case GET_TEAMS_SUCCESS:
      return { ...state, teamsLoading: false, teams: action.teams };
    case GET_TEAMS_FAILURE:
      return { ...state, teamsLoading: false };
    case GET_MY_TEAM_REQUEST:
      return { ...state, myTeamLoading: true };
    case GET_MY_TEAM_SUCCESS:
      return { ...state, myTeamLoading: false, myTeam: action.myTeam };
    case GET_MY_TEAM_FAILURE:
      return { ...state, myTeamLoading: false };
    case GET_TEAM_ID_REQUEST:
      return { ...state, teamIdLoading: true, teamId: null };
    case GET_TEAM_ID_SUCCESS:
      return { ...state, teamIdLoading: false, teamId: action.teamId };
    case GET_TEAM_ID_FAILURE:
      return { ...state, teamIdLoading: false };

    case GET_USER_INVITE_REQUEST:
      return { ...state, usersInvitedLoading: false };
    case GET_USER_INVITE_SUCCESS:
      return { ...state, usersInvitedLoading: false, usersInvited: action.usersInvited };
    case GET_USER_INVITE_FAILURE:
      return { ...state, usersInvitedLoading: false };
    default:
      return { ...state };
  }
};

export default teamsReducer;
