import { Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const RacesTab = ({ races }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <Link to={`/details/race/${record.id}`}>{record.name}</Link>,
    },
    {
      title: t('tableColumns.creator'),
      dataIndex: 'creator',
      key: 'creator',
      render: (_, record) => <>{record.creator}</>,
    },
    {
      title: t('tableColumns.distance'),
      dataIndex: 'distance',
      key: 'distance',
      render: (_, record) => <>{Number(record.distance / 1000).toFixed(2)}km</>,
    },
    {
      title: t('tableColumns.elevation'),
      dataIndex: 'elevation',
      key: 'elevation',
      render: (_, record) => <>{Number(record.elevation).toFixed(2)} m </>,
    },
    {
      title: t('tableColumns.registeredCompleted'),
      dataIndex: 'registeredCount',
      key: 'registeredCount',
      width: '100px',
      render: (_, record) => (
        <>
          {record.registeredCount} / {record.completedCount}
        </>
      ),
    },
    {
      title: t('tableColumns.segments'),
      dataIndex: 'segments',
      key: 'segments',
      render: (_, record) => <>{record.segmentsCount}</>,
    },
  ];
  return <Table columns={columns} dataSource={races} rowKey={'id'} />;
};

export default RacesTab;
