import React, { useState } from 'react';
import { Modal, Table } from 'antd';

import './styles.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import RacesOnCupInfo from './RacesOnCupInfo';

const CupUsersTab = ({ users, details }) => {
  const { t } = useTranslation();

  const [userId, setUserId] = useState(null);
  const [raceInfo, setRaceInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const openModalHandler = (id) => {
    setUserId(id);
    setRaceInfo(details);
    setShowModal((prev) => !prev);
  };

  const closeModalHandler = () => {
    setUserId(null);
    setRaceInfo(null);
    setShowModal(false);
  };

  const columns = [
    {
      title: t('tableColumns.rank'),
      dataIndex: 'rank',
      key: 'rank',
      render: (_, record) => <>{record?.rank}</>,
    },
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <>
          {record?.user.firstName} {record?.user.lastName}
        </>
      ),
    },
    // {
    //   title: t('tableColumns.team'),
    //   dataIndex: 'team',
    //   key: 'team',
    //   render: (_, record) => {
    //     return (
    //       <>
    //         {record?.user?.currentTeam ? (
    //           <Link to={`/team/details/${record?.user?.currentTeam.id}`}>{record?.user?.currentTeam?.shortName}</Link>
    //         ) : (
    //           '-'
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      title: 'Points',
      dataIndex: 'Points',
      key: 'Points',
      render: (_, record) => {
        return (
          <p onClick={() => openModalHandler(record?.user.id)} className="points__text">
            {record?.totalPoints}
          </p>
        );
      },
    },
  ];
  return (
    <>
      {users && (
        <Table
          columns={columns}
          dataSource={_.sortBy(users, 'rank')}
          pagination={users?.length >= 20}
          rowKey={'stravaId'}
        />
      )}
      <Modal open={showModal} footer={null} onCancel={closeModalHandler} width={'70%'}>
        <div className="raceOnCup__container header-cupinfo">
          <p>{t('tableColumns.segment')}</p>
          <p>{t('tableColumns.ranking')}</p>
          <p>{t('tableColumns.coefficient')}</p>
          <p>points</p>
        </div>
        <RacesOnCupInfo userId={userId} racesArr={raceInfo} />
      </Modal>
    </>
  );
};

export default CupUsersTab;
