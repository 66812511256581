import { Card, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { daysRemaining } from '../../utils/functions';
import { ReactComponent as CupSVG } from '../../assets/ranking-cup.svg';
import { apiUrl } from '../../utils/config';
import './styles.scss';

const SingleCup = ({ id, name, endDate, finished, creator, description, myRank, usersCount, showImg }) => {
  const { t } = useTranslation();

  const text = () => (
    <div className="race-header__info">
      {/* {!published && 'Not Published'} */}
      <div className="col-1">
        <div className="names">
          {name?.length >= 34 ? (
            <Tooltip placement="bottom" title={name}>
              <Link className="m-b-10 race-name" to={`/details/cup/${id}`}>
                {name}
              </Link>
            </Tooltip>
          ) : (
            <Link className="m-b-10 race-name" to={`/details/cup/${id}`}>
              {name}
            </Link>
          )}
        </div>
        <CupSVG width={45} />
      </div>
      <div className="col-2">
        <p>
          {endDate && finished ? (
            t('finishedRace')
          ) : (
            <>
              {endDate && (
                <>
                  {t('remaining')} {daysRemaining(new Date(endDate), t('days'), t('hours'))}
                </>
              )}
            </>
          )}
        </p>
      </div>
    </div>
  );
  return (
    <Card title={text()} className="cupItem">
      <Link className="details-btn" to={`/details/cup/${id}`}>
        {t('buttons.details')}
      </Link>
      <div style={{ display: 'flex', gap: '5px' }} className="m-t-15">
        {t('titles.cupOwner')}: <p className="owner__text">{creator}</p>
      </div>
      <p>{description}</p>
      <div className="race-information m-t-10">
        {showImg && (
          <div className="preview-race__img-container" style={{ width: '80%' }}>
            <img src={`${apiUrl}cup/preview/${id}?type=2`} alt="preview-race" className="preview-race__img" />
          </div>
        )}

        <div className="wrapper">
          <div className="col-1">
            {myRank !== null && (
              <div className="item">
                <div className="item__text m-l-0">
                  {t('tableColumns.myRank')} :{myRank}
                </div>
              </div>
            )}
          </div>
          <div className="col-2">
            {usersCount !== 0 && (
              <>
                <p>
                  {t('racers')}: {usersCount}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default SingleCup;
