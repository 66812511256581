import React, { useEffect, useState } from 'react';
import SingleRace from '../../components/SingleRace/SingleRace';
import { useDispatch, useSelector } from 'react-redux';
import { getRaces, getRacesByCircleId, getRacesByCriteria } from '../../store/actions/manageRaces/manageRaces';
import { Radio, Select, Spin } from 'antd';
import _ from 'lodash';
import NoResult from '../../components/NoResult/NoResult';
import './styles.scss';
import moment from 'moment';
import { getMyCircles } from '../../store/actions/circle/circleActions';
import { useTranslation } from 'react-i18next';
const { Option } = Select;

const Races = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(1);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.managedRaces?.managedRacesLoading);
  const races = useSelector((state) => state.managedRaces?.managedRaces?.data?.items);
  const circles = useSelector((state) => state.circle?.managedCircles?.items);
  const circlesLoading = useSelector((state) => state.circle.managedCirclesLoading);
  const [myCircle, setMyCircle] = useState(false);
  const [circleId, setCircleId] = useState(null);

  useEffect(() => {
    dispatch(getMyCircles());
  }, [dispatch]);

  const getRacesByCircleHandler = (id) => {
    setCircleId(id);
    dispatch(getRacesByCircleId(id));
  };

  const getAllRacesHandler = () => {
    dispatch(getRaces());
  };

  const getFinishedRacesHandler = () => {
    dispatch(getRacesByCriteria(true, null, null, null));
  };

  const getRegisteredRacesHandler = () => {
    dispatch(getRacesByCriteria(null, true, null, null));
  };
  const getCompletedRacesHandler = () => {
    dispatch(getRacesByCriteria(null, null, true, null));
  };

  const getMyCirclesHandler = () => {
    dispatch(getRacesByCriteria(null, null, null, true));
  };

  useEffect(() => {
    dispatch(getRacesByCriteria(null, null, null, true));
  }, [dispatch]);

  useEffect(() => {
    if (circles) {
      const sorted = _.sortBy(circles, 'name');
      setMyCircle(sorted);
    }
  }, [circles]);

  return (
    <div className="content">
      <h2 className="title">{t('navigation.races')}</h2>
      <Spin spinning={loading}>
        <div className="filter-races-block m-b-30">
          <Radio.Group onChange={(e) => setValue(e.target.value)} value={value} defaultValue={1}>
            <Radio value={1} onClick={getMyCirclesHandler}>
              {t('filterRace.myCircles')}
            </Radio>
            <Radio value={2} onClick={getRegisteredRacesHandler}>
              {t('filterRace.registered')}
            </Radio>
            <Radio value={3} onClick={getCompletedRacesHandler}>
              {t('filterRace.completed')}
            </Radio>
            <Radio value={4} onClick={getFinishedRacesHandler}>
              {t('filterRace.finished')}
            </Radio>
            <Radio value={5} onClick={getAllRacesHandler}>
              {t('filterRace.all')}
            </Radio>
          </Radio.Group>
        </div>

        <div className="filter-races-block m-b-30">
          <Select
            allowClear
            style={{ width: '350px', height: '35px !important' }}
            onChange={(e) => getRacesByCircleHandler(e)}
            placeholder={t('filterRace.circle')}
            size="large"
            value={circleId}
          >
            {myCircle && (
              <>
                {myCircle?.map((elem) => (
                  <>{!circlesLoading && <Option key={elem.id}>{elem.name}</Option>}</>
                ))}
              </>
            )}
          </Select>
        </div>

        <div className="race-item">
          {!_.isEmpty(races) ? (
            <>
              {races?.map((race) => {
                const today = moment(new Date()).format('YYYY-MM-DD');
                const end = moment(race?.endDate).format('YYYY-MM-DD');
                return (
                  <SingleRace
                    key={race.id}
                    elevation={race?.elevation}
                    distance={race?.distance}
                    startDate={race?.startDate}
                    endDate={race?.endDate}
                    name={race?.name}
                    segments={race?.segmentsCount}
                    topRaces={race?.top3}
                    totalRacers={race?.registeredCount}
                    id={race?.id}
                    finished={today > end}
                    circleName={race?.circle?.name}
                    circleId={race?.circle?.id}
                    published={race.isPublished}
                    isCupRace={race?.cup}
                    raceType={race?.type.value}
                  />
                );
              })}
            </>
          ) : (
            <>{!loading && <NoResult text="Not found races" />}</>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default Races;
