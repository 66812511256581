import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../appContext';
import UpdateEmail from '../../components/UpdateEmail/UpdateEmail';
import AboutUs from '../AboutUs/AboutUs';
import Dashboard from '../Dashboard/Dashboard';

import './styles.scss';

const MainPage = () => {
  const { isLoggedIn, hasRole, hasEmail } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <>
      {isLoggedIn && !hasEmail ? (
        <UpdateEmail />
      ) : (
        <div className="main-page">
          {!hasRole && isLoggedIn ? (
            <p className="welcome__text">{t('titles.adminActivate')}</p>
          ) : (
            <>
              {!isLoggedIn ? (
                <div className="m-t-100">
                  <AboutUs />
                </div>
              ) : (
                <Dashboard />
              )}
            </>
            //
          )}
        </div>
      )}
    </>
  );
};

export default MainPage;
