import {
  GET_ROLES_FAILURE,
  GET_ROLES_REQUEST,
  GET_ROLES_SUCCESS,
  GET_ATHLETES_FAILURE,
  GET_ATHLETES_REQUEST,
  GET_ATHLETES_SUCCESS,
  GET_SINGLE_ATHLETE_FAILURE,
  GET_SINGLE_ATHLETE_REQUEST,
  GET_SINGLE_ATHLETE_SUCCESS,
} from '../actions/athletes/actionTypes';

const initialState = {
  athletes: [],
  athletsLoading: false,
  singleAthlete: [],
  singleAthletLoading: false,
  roles: [],
  rolesLoading: false,
  pages: 1,
};

const athletesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ATHLETES_REQUEST:
      return { ...state, athletsLoading: true };
    case GET_ATHLETES_SUCCESS:
      return { ...state, athletsLoading: false, athletes: action.athletes };
    case GET_ATHLETES_FAILURE:
      return { ...state, athletsLoading: false };
    case GET_ROLES_REQUEST:
      return { ...state, rolesLoading: true };
    case GET_ROLES_SUCCESS:
      return { ...state, rolesLoading: false, roles: action.roles };
    case GET_ROLES_FAILURE:
      return { ...state, rolesLoading: false };
    case GET_SINGLE_ATHLETE_REQUEST:
      return { ...state, singleAthletLoading: true };
    case GET_SINGLE_ATHLETE_SUCCESS:
      return { ...state, singleAthletLoading: false, singleAthlete: action.singleAthlete };
    case GET_SINGLE_ATHLETE_FAILURE:
      return { ...state, singleAthletLoading: false };
    default:
      return { ...state };
  }
};
export default athletesReducer;
