import React from 'react';
import { Link } from 'react-router-dom';

const NoTeam = () => {
  return (
    <div style={{ textAlign: 'center' }} className="m-t-40">
      <h2 className="title">You have no team</h2>
      <Link to="/teams">Can find yourself a team</Link>
    </div>
  );
};

export default NoTeam;
