import React, { useContext } from 'react';
import { Button, Table } from 'antd';
import moment from 'moment';
import { AppContext } from '../../../appContext';
import { useDispatch } from 'react-redux';
import { adminRaceUnregister, getSingleRace } from '../../../store/actions/manageRaces/manageRaces';
import { useParams } from 'react-router-dom';
import '../styles.scss';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '../../../utils/constants/constants';
import _ from 'lodash';
import StravaLink from '../../StravaLink/StravaLink';

const RacePressoUsersTab = ({ users, isAllowed = true }) => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(AppContext);
  const { id } = useParams();
  const dispatch = useDispatch();

  const unregisterOnRaceHandler = async (raceID, userId) => {
    const response = await dispatch(adminRaceUnregister(raceID, userId));
    if (response) dispatch(getSingleRace(raceID));
  };

  const columns = [
    {
      title: t('tableColumns.rank'),
      dataIndex: 'rank',
      key: 'rank',
      render: (_, record) => <>{record?.rank ? record?.rank : '-'}</>,
    },
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <>
          {record?.user.firstName} {record?.user.lastName}
        </>
      ),
    },
    {
      title: t('tableColumns.elapsedTime'),
      dataIndex: 'elapsedTime',
      key: 'elapsedTime',
      render: (_, record) => {
        let MHSTime;
        if (record.finalResult) {
          let measuredTime = new Date(null);
          measuredTime.setSeconds(record.finalResult);
          MHSTime = measuredTime.toISOString().substr(11, 8);
        }

        return <>{record.finalResult ? MHSTime : '-'}</>;
      },
    },
    {
      title: t('tableColumns.registeredDate'),
      dataIndex: 'registeredDate',
      key: 'registeredDate',
      render: (_, record) => <>{moment(record?.registeredAt).format(dateFormat)}</>,
      responsive: ['lg'],
    },
    {
      title: t('tableColumns.completedDate'),
      dataIndex: 'completedDate',
      key: 'completedDate',
      render: (_, record) => <>{record?.completedAt ? moment(record?.completedAt).format(dateFormat) : '-'}</>,
      responsive: ['lg'],
    },
    {
      title: t('tableColumns.activity'),
      dataIndex: 'activity',
      key: 'activity',
      render: (_, record) => (
        <>
          {record?.activity?.stravaId ? (
            <StravaLink type="activities" id={record?.activity?.stravaId}>
              {record?.activity?.name}
            </StravaLink>
          ) : (
            '-'
          )}
        </>
      ),
      responsive: ['lg'],
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <>
            {isAdmin && isAllowed && (
              <>
                <Button onClick={() => unregisterOnRaceHandler(id, record?.user?.id)}>Unregister</Button>
              </>
            )}
          </>
        );
      },
      responsive: ['lg'],
    },
  ];
  return (
    <>
      {users && (
        <Table
          columns={columns}
          dataSource={_.sortBy(users, 'rank')}
          pagination={users?.length >= 20}
          rowKey={'stravaId'}
        />
      )}
    </>
  );
};

export default RacePressoUsersTab;
