import React, { useCallback, useEffect, useState } from 'react';
import { Button, DatePicker, Input, Modal, Table, Select, InputNumber, Checkbox } from 'antd';
import { getAllCups, getCupByID } from '../../store/actions/cup/cupActions';
import { useDispatch, useSelector } from 'react-redux';
import { addNewRace, getActivity, getRoute, getStravaSegment } from '../../store/actions/manageRaces/manageRaces';
import { getCircles } from '../../store/actions/circle/circleActions';
import { showNotification } from '../../utils/showNotification';
import { DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';
import ActivityItem from '../ActivityItem/ActivityItem';
import StravaLink from '../StravaLink/StravaLink';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
const { Option } = Select;

const CreateRace = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  let d = new Date();
  const dispatch = useDispatch();
  const loadedSegments = useSelector((state) => state.managedRaces?.segment);
  const activityLoading = useSelector((state) => state.managedRaces?.activityLoading);
  const activity = useSelector((state) => state.managedRaces?.activity);
  const circles = useSelector((state) => state.circle?.managedCircles?.items);
  const circlesLoading = useSelector((state) => state.circle.managedCirclesLoading);
  const cups = useSelector((state) => state.cup?.cups);
  const cupsLoading = useSelector((state) => state.cup?.cupsLoading);
  const route = useSelector((state) => state.managedRaces?.route);
  const cupById = useSelector((state) => state.cup?.cupByID);

  // states
  const [stravaId, setStravaId] = useState(null);

  const [name, setName] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [close, setClose] = useState('');
  const [from, setFrom] = useState(moment(d.setMonth(d.getMonth() - 6)).format('YYYY-MM-DD'));
  const [to, setTo] = useState(null);
  const [segments, setSegments] = useState([]);
  const [circleId, setCircleId] = useState(null);
  const [segmentsForRequest, setSegmentsForRequest] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activityId, setActivityId] = useState(null);
  const [cupId, setCupId] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [raceType, setRaceType] = useState(0);

  const [coefficient, setCoefficient] = useState(null);
  const [bonusPoint, setBonusPoint] = useState(null);
  const [bonusWinner, setBonusWinner] = useState(null);

  const [routeId, setRouteId] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [defaultRouteStravaId, setDefaultRouteStravaId] = useState(null);

  const loadStravaSegment = async () => {
    if (stravaId) {
      const response = await dispatch(getStravaSegment(stravaId));
      if (response) {
        setStravaId('');
        showNotification('success', 'Successfully added race segment');
      }
    }
  };

  const addRoutes = async () => {
    if (routeId) {
      const response = await dispatch(getRoute(routeId));
      if (response) {
        setRouteId(null);
      }
    }
  };

  const addRaceHandler = async () => {
    const response = await dispatch(
      addNewRace({
        name,
        startDate: start,
        endDate: raceType === 0 ? end : null,
        closeDate: raceType === 0 ? close : null,
        raceSegments: segmentsForRequest,
        circleId: !cupId ? circleId : null,
        cupId: raceType === 0 ? cupId : null,
        cupCoefficient: cupId && coefficient,
        cupWinnerBonus: cupId && bonusPoint,
        cupCompletedBonus: cupId && bonusWinner,
        raceRoutes: _.isEmpty(route) ? null : routes,
        defaultRouteStravaId,
        isPublished,
        type: raceType,
      })
    );
    if (response) {
      setName('');
      setStart('');
      setEnd('');
      setClose('');
      setSegmentsForRequest(null);
      setSegments(null);
      setCircleId(null);
      setCoefficient(null);
      setCupId(null);
      setBonusPoint(null);
      setBonusWinner(null);
      setRoutes(null);
      if (id || cupId) {
        setTimeout(() => {
          navigate(`/cup/edit/${id || cupId}`);
        }, 1000);
      }
    }
  };

  const updateCoefficients = (stravaId) => (e) => {
    const newArray = segmentsForRequest.map((item) => {
      if (stravaId === item.stravaId) {
        return { ...item, [e.target.name]: Number(e.target.value) };
      } else {
        return item;
      }
    });
    const newArrayToDisplay = segments.map((item) => {
      if (stravaId === item.stravaId) {
        return { ...item, [e.target.name]: Number(e.target.value) };
      } else {
        return item;
      }
    });
    setSegments(newArrayToDisplay);
    setSegmentsForRequest(newArray);
  };

  const updateLapsCount = (id) => (e) => {
    const newArray = routes.map((item) => {
      if (id === item.stravaId) {
        return { ...item, [e.target.name]: Number(e.target.value) };
      } else {
        return item;
      }
    });
    setRoutes(newArray);
  };

  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <>{record?.name}</>,
    },
    {
      title: 'Coefficient',
      dataIndex: 'coefficient',
      key: 'coefficient',
      render: (_, record) => (
        <input
          className="coefficient__input"
          type="number"
          value={record?.coefficient}
          name="coefficient"
          onChange={updateCoefficients(record.stravaId)}
          step={0.1}
        />
      ),
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
      render: (_, record) => <>{Number(record?.distance / 1000).toFixed(2)} km</>,
    },
    {
      title: 'Elevation',
      dataIndex: 'elevation',
      key: 'distance',
      render: (_, record) => <>{Number(record?.elevation).toFixed(0)} m</>,
    },
    {
      title: 'StravaId',
      dataIndex: 'distance',
      key: 'distance',
      render: (_, record) => <StravaLink id={record?.stravaId} type="segments" />,
    },

    {
      title: 'action',
      dataIndex: 'action',
      key: 'actions',
      render: (_, record) => (
        <>
          <DeleteOutlined onClick={() => removeHandler(record?.stravaId)} />
        </>
      ),
    },
  ];
  const routescolumns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <>
          <a
            href={`https://www.strava.com/routes/${record?.stravaId}`}
            target="_blank"
            style={{ fontSize: '19px' }}
            rel="noreferrer"
          >
            {record.name}
          </a>
        </>
      ),
    },

    {
      title: 'Laps',
      dataIndex: 'laps',
      key: 'laps',
      render: (_, record) => (
        <input
          className="coefficient__input"
          type="number"
          value={record?.lapsCount}
          name="lapsCount"
          min={1}
          onChange={updateLapsCount(record?.stravaId)}
          step={1}
        />
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => <>{record?.stravaId}</>,
    },
    {
      title: t('tableColumns.distance'),
      dataIndex: 'elevation',
      key: 'elevation',
      render: (_, record) => <>{Number(record.distance / 1000).toFixed(2)} km</>,
    },
    {
      title: t('tableColumns.elevation'),
      dataIndex: 'elevation',
      key: 'elevation',
      render: (_, record) => {
        return <>{Number(record.elevation).toFixed(0)} m </>;
      },
    },
    {
      title: 'action',
      dataIndex: 'action',
      key: 'actions',
      render: (_, record) => (
        <>
          <Checkbox
            onClick={() => setDefaultRouteStravaId(record?.stravaId)}
            checked={record?.stravaId === defaultRouteStravaId}
            style={{ marginRight: '10px' }}
          >
            Set default
          </Checkbox>
          <DeleteOutlined onClick={() => removeRotues(record?.stravaId)} />
        </>
      ),
    },
  ];

  const convertRaceSegment = useCallback((seg) => {
    let arr = [];
    if (!seg) return;
    seg?.map((elem) => {
      arr.push({ coefficient: 1, stravaId: elem.stravaId });
      setSegmentsForRequest(arr);
      return arr;
    });
  }, []);

  const removeHandler = (id) => {
    convertRaceSegment(segments);
    setSegments((element) => element.filter((item) => item.stravaId !== id));
    setSegmentsForRequest((element) => element.filter((item) => item.stravaId !== id));
    dispatch({ type: 'REMOVE_SEGMENT', id });
  };

  const removeRotues = (id) => {
    setRoutes((element) => element.filter((item) => item.stravaId !== id));
    dispatch({ type: 'REMOVE_ROUTE', id });
  };

  const openModalHandler = (id) => {
    setActivityId(id);
    setShowModal((prev) => !prev);
  };
  const closeModalHandler = () => {
    setActivityId(null);
    setShowModal(false);
  };

  useEffect(() => {
    dispatch({ type: 'CLEAR_SEGMENTS' });
    dispatch({ type: 'CLEAR_ROUTES' });
  }, [dispatch]);

  useEffect(() => {
    if (loadedSegments) {
      setSegments(loadedSegments);
      convertRaceSegment(loadedSegments);
    }
  }, [loadedSegments, convertRaceSegment]);

  useEffect(() => {
    if (!_.isEmpty(route)) {
      setRoutes(route);
    }
  }, [route]);

  useEffect(() => {
    if (route.length) {
      if (defaultRouteStravaId === null) {
        setDefaultRouteStravaId(route[0]?.stravaId);
      }
    }
  }, [route, defaultRouteStravaId]);

  useEffect(() => {
    dispatch(getCircles(true));
    dispatch(getAllCups());
  }, [dispatch]);

  useEffect(() => {
    if (cupId) {
      setCircleId(null);
    }
  }, [cupId]);

  useEffect(() => {
    dispatch(getActivity(from, to));
  }, [dispatch, from, to]);

  useEffect(() => {
    if (id || cupId) {
      dispatch(getCupByID(id || cupId));
    }
  }, [dispatch, id, cupId]);

  useEffect(() => {
    if (id || cupId) {
      setStart(cupById?.defaultStartDate);
      setEnd(cupById?.defaultEndDate);
      setClose(cupById?.defaultCloseDate);
      if (id) setCupId(id);
    }
  }, [id, cupById, cupId]);

  return (
    <div className="content">
      <div className="addrace-form">
        <h2 className="title">{t('buttons.createRace')}</h2>
        <Select
          style={{ width: '33%', height: '43px !important' }}
          size="large"
          className="m-b-20"
          placeholder="Type of race"
          defaultValue={raceType}
          onChange={(value) => setRaceType(value)}
          options={[
            {
              value: 0,
              label: 'RacePresso',
            },
            {
              value: 1,
              label: 'Cyclosportive',
            },
          ]}
        />
        <div className="main-information">
          <div className="race-name__block">
            <Input size="large" placeholder="race name" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="first-row">
            <Input
              className="strava-input route-input"
              placeholder="route id"
              value={routeId}
              onChange={(e) => setRouteId(e.target.value)}
              style={{ width: '33%' }}
              size="large"
            />
            <Button onClick={addRoutes} size="large">
              add
            </Button>
            <Select
              disabled={cupId}
              allowClear
              style={{ width: '33%', height: '43px !important' }}
              onChange={(e) => setCircleId(e)}
              placeholder={t('filterRace.circle')}
              size="large"
              value={circleId}
            >
              {circles?.map((elem) => (
                <>{!circlesLoading && <Option key={elem.id}>{elem.name}</Option>}</>
              ))}
            </Select>
            {raceType === 0 && (
              <Select
                disabled={circleId || id}
                allowClear
                style={{ width: '33%', height: '43px !important' }}
                onChange={(e) => setCupId(e)}
                placeholder={t('cupName')}
                size="large"
                value={cupId}
              >
                {cups?.map((elem) => (
                  <>{!cupsLoading && <Option key={elem.id}>{elem.name}</Option>}</>
                ))}
              </Select>
            )}
          </div>
          <div className="first-row">
            <DatePicker
              size="large"
              format="YYYY-MM-DD"
              placeholder="start activity date"
              defaultValue={null}
              value={start !== '' ? moment(start) : ''}
              onChange={(date, dateString) => setStart(dateString)}
              style={{ width: '33%' }}
              disabled={cupId}
            />
            {raceType === 0 && (
              <>
                <DatePicker
                  size="large"
                  format="YYYY-MM-DD"
                  placeholder="end activity date"
                  defaultValue={null}
                  value={end !== '' ? moment(end) : ''}
                  onChange={(date, dateString) => setEnd(dateString)}
                  style={{ width: '33%' }}
                  disabled={cupId}
                />
                <DatePicker
                  size="large"
                  format="YYYY-MM-DD"
                  placeholder="close date"
                  defaultValue={null}
                  value={close !== '' ? moment(close) : ''}
                  onChange={(date, dateString) => setClose(dateString)}
                  style={{ width: '33%' }}
                  disabled={cupId}
                />
              </>
            )}
          </div>
          <Checkbox onChange={() => setIsPublished((prev) => !prev)} value={isPublished} checked={isPublished}>
            Publish Race
          </Checkbox>
          {cupId && (
            <div className="first-row">
              <InputNumber
                className="strava-input route-input"
                placeholder={t('tableColumns.coefficient')}
                style={{ width: '33%' }}
                size="large"
                value={coefficient}
                onChange={(value) => setCoefficient(value)}
                defaultValue={coefficient}
                min={1}
                max={10}
              />
              <InputNumber
                className="strava-input route-input"
                placeholder="Bonus point"
                style={{ width: '33%' }}
                value={bonusPoint}
                onChange={(value) => setBonusPoint(value)}
                size="large"
                defaultValue={bonusPoint}
                min={1}
                max={10}
              />
              <InputNumber
                className="strava-input route-input"
                placeholder="Bonus winner"
                style={{ width: '33%' }}
                value={bonusWinner}
                onChange={(value) => setBonusWinner(value)}
                size="large"
                defaultValue={bonusWinner}
                min={1}
                max={10}
              />
            </div>
          )}
        </div>
        {raceType === 0 && (
          <>
            <h3>{t('tableColumns.segments')}</h3>
            <div className="choose-type">
              <div className="choose-type-item strava-type">
                <div className="second-row">
                  <div>
                    <p className="custom__label">Load segments from strava</p>
                    <Input
                      className="strava-input"
                      placeholder="strava id"
                      value={stravaId}
                      onChange={(e) => setStravaId(e.target.value)}
                      size="large"
                    />
                    <Button onClick={loadStravaSegment} size="large">
                      add
                    </Button>
                  </div>
                </div>
              </div>
              <div className="choose-type-item activity-type">
                <div>
                  <p className="custom__label">From</p>
                  <DatePicker
                    size="large"
                    format="YYYY-MM-DD"
                    placeholder="from"
                    onChange={(date, dateString) => setFrom(dateString)}
                    defaultValue={moment(from)}
                  />
                </div>

                <div>
                  <p className="custom__label">To</p>
                  <DatePicker
                    size="large"
                    format="YYYY-MM-DD"
                    placeholder="to"
                    onChange={(date, dateString) => setTo(dateString)}
                  />
                </div>

                <div>
                  <p className="custom__label">Select a Strava activity</p>
                  <Select
                    size="large"
                    allowClear
                    style={{ width: '250px', height: '43px !important' }}
                    onChange={(e) => openModalHandler(e)}
                    placeholder="Activities"
                  >
                    {activity.map((elem) => (
                      <>{!activityLoading && <Option key={elem.stravaId}>{elem.name}</Option>}</>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </>
        )}

        {!_.isEmpty(segments) && (
          <Table columns={columns} dataSource={segments} key={segments.stravaId} rowKey={'stravaId'} />
        )}
        {!_.isEmpty(route) && (
          <Table columns={routescolumns} dataSource={routes} key={routes?.stravaId} rowKey={'id'} />
        )}

        <div className="addrace-footer">
          <Button type="primary" onClick={addRaceHandler} size="large">
            {t('buttons.createRace')}
          </Button>
        </div>
      </div>
      {showModal && (
        <Modal open={showModal} onCancel={closeModalHandler} width={'990px'} footer={null} destroyOnClose={true}>
          <ActivityItem id={activityId} close={closeModalHandler} />
        </Modal>
      )}
    </div>
  );
};

export default CreateRace;
