import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Input, Typography, Radio, Space, Spin, DatePicker, Divider, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import UserCirclesTab from '../../../components/Tabs/UsersTab/UserCirclesTab';
import UserCupsTab from '../../../components/Tabs/UsersTab/UserCupsTab';
import UserRacesTab from '../../../components/Tabs/UsersTab/UserRacesTab';
import moment from 'moment';
import { getUserByID } from '../../../store/actions/user/userActions';
import { useParams } from 'react-router-dom';
import './styles.scss';
const { Panel } = Collapse;
const { Title } = Typography;

const UserDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userByID = useSelector((state) => state.user?.userByID);
  const loading = useSelector((state) => state.user?.userByIDLoading);

  useEffect(() => {
    dispatch(getUserByID(id));
  }, [dispatch]);

  return (
    <div className="content">
      <Spin spinning={loading} size="large">
        <div className="profile__container">
          <h2 className="title m-b-40 m-t-20">User Details</h2>
          <div className="change__info-row">
            <div className="change__info-row__item user-info__item">
              <Title level={4}>
                {' '}
                {t('titles.firstName')} : {userByID?.firstName}
              </Title>
            </div>
          </div>
          <div className="change__info-row">
            <div className="change__info-row__item user-info__item">
              <Title level={4}>
                {' '}
                {t('titles.lastName')} : {userByID?.lastName}
              </Title>
            </div>
          </div>
          <div className="change__info-row">
            <div className="change__info-row__item user-info__item">
              <Title level={4}>Email : {userByID?.email}</Title>
            </div>
          </div>
          {/* <div className="change__info-row__item"></div> */}
          <div className="change__info-row">
            <div className="change__info-row__item user-info__item">
              <Title level={4}>
                {t('titles.birthDay')} :{' '}
                {userByID?.birthday ? moment(userByID?.birthday).format('YYYY-MM-DD') : t('titles.notBirthDay')}
              </Title>
            </div>
          </div>

          <div className="change__info-row m-b-50">
            <div className="change__info-row__item user-info__item">
              <Title level={4}>
                {t('titles.sex')} :{' '}
                {userByID?.sex?.value === 0 ? t('notRespond') : userByID?.sex?.value === 1 ? t('man') : t('woman')}
              </Title>
            </div>
          </div>

          <Divider />
          <div className="userInformation__container">
            <h2 className="title m-b-30">
              {userByID?.currentTeam !== null ? (
                <>
                  {t('myTeam')}{' '}
                  <Link to={`/team/details/${userByID?.currentTeam?.id}`}>{userByID?.currentTeam.name}</Link>
                </>
              ) : (
                <>{t('titles.noTeam')}</>
              )}
            </h2>
            <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
              <Panel header={t('titles.myCircles')} key={1}>
                <UserCirclesTab myCircles={userByID?.circles} />
              </Panel>
            </Collapse>
            <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
              <Panel header={t('titles.myRaces')} key={1}>
                <UserRacesTab myRaces={userByID?.races} />
              </Panel>
            </Collapse>
            <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
              <Panel header={t('titles.myCups')} key={1}>
                <UserCupsTab myCups={userByID?.cups} />
              </Panel>
            </Collapse>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default UserDetail;
