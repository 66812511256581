import React, { useContext } from 'react';
import { Button, Table } from 'antd';
import { AppContext } from '../../../appContext';
import { useDispatch } from 'react-redux';
import { adminRaceUnregister, getSingleRace } from '../../../store/actions/manageRaces/manageRaces';
import { useParams } from 'react-router-dom';
import '../styles.scss';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const CyclosportiveUsersTab = ({ users, isAllowed = true }) => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(AppContext);
  const { id } = useParams();
  const dispatch = useDispatch();

  const unregisterOnRaceHandler = async (raceID, userId) => {
    const response = await dispatch(adminRaceUnregister(raceID, userId));
    if (response) dispatch(getSingleRace(raceID));
  };

  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <>
          {record?.user.firstName} {record?.user.lastName}
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <>
            {isAdmin && isAllowed && (
              <>
                <Button onClick={() => unregisterOnRaceHandler(id, record.user.id)}>Unregister</Button>
              </>
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      {users && (
        <Table
          columns={columns}
          dataSource={_.sortBy(users, 'rank')}
          pagination={users?.length >= 20}
          rowKey={'stravaId'}
        />
      )}
    </>
  );
};

export default CyclosportiveUsersTab;
