import React from 'react';

const StravaLink = ({ id, type }) => {
  return (
    <a href={`https://www.strava.com/${type}/${id}`} target="_blank">
      {id}
    </a>
  );
};

export default StravaLink;
