import axiosApi from '../../../utils/axiosApi';
import { showNotification } from '../../../utils/showNotification';
import {
  GET_RACES_REQUEST,
  GET_RACES_SUCCESS,
  GET_RACES_FAILURE,
  GET_SINGLE_RACE_REQUEST,
  GET_SINGLE_RACE_SUCCESS,
  GET_SINGLE_RACE_FAILURE,
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_FAILURE,
  GET_SEGMENT_REQUEST,
  GET_SEGMENT_SUCCESS,
  GET_SEGMENT_FAILURE,
  GET_ACTIVITY_BY_ID_REQUEST,
  GET_ACTIVITY_BY_ID_SUCCESS,
  GET_ACTIVITY_BY_ID_FAILURE,
  COMPLETE_RACE_REQUEST,
  COMPLETE_RACE_SUCCESS,
  COMPLETE_RACE_FAILURE,
  GET_ROUTE_REQUEST,
  GET_ROUTE_SUCCESS,
  GET_ROUTE_FAILURE,
  GET_SINGLE_ROUTE_REQUEST,
  GET_SINGLE_ROUTE_SUCCESS,
  GET_SINGLE_ROUTE_FAILURE,
} from './actionTypes';

const getRacesRequest = () => ({ type: GET_RACES_REQUEST });
const getRacesSuccess = (managedRaces) => ({ type: GET_RACES_SUCCESS, managedRaces });
const getRacesFailure = () => ({ type: GET_RACES_FAILURE });

export const getRaces = () => {
  return async (dispatch) => {
    try {
      dispatch(getRacesRequest());
      const response = await axiosApi.get('/race');
      dispatch(getRacesSuccess(response.data));
    } catch (error) {
      dispatch(getRacesFailure());
    }
  };
};

export const getAllRaces = (name, from, to) => {
  return async (dispatch) => {
    try {
      dispatch(getRacesRequest());
      const response = await axiosApi.get('/race', {
        params: {
          Name: name,
          From: from,
          To: to,
        },
      });
      dispatch(getRacesSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(getRacesFailure());
      return false;
    }
  };
};

export const getRacesByCriteria = (finished = null, registered = null, completed = null, subscribed = null) => {
  return async (dispatch) => {
    try {
      dispatch(getRacesRequest());
      const response = await axiosApi.get('/race', {
        params: {
          RegisteredOnly: registered,
          FinishedOnly: finished,
          CompletedOnly: completed,
          SubscribedCirclesOnly: subscribed,
        },
      });
      dispatch(getRacesSuccess(response.data));
    } catch (error) {
      dispatch(getRacesFailure());
    }
  };
};

export const getRacesByCircleId = (id = null) => {
  return async (dispatch) => {
    try {
      dispatch(getRacesRequest());
      const response = await axiosApi.get('/race', {
        params: {
          CircleId: id,
        },
      });
      dispatch(getRacesSuccess(response.data));
    } catch (error) {
      dispatch(getRacesFailure());
    }
  };
};

// export const getRaces = (registered = false, subscribed = false, finished = false, completed = false) => {
//   return async (dispatch) => {
//     try {
//       dispatch(getRacesRequest());
//       const response = await axiosApi.get(
//         `/race?RegisteredOnly=${registered}&SubscribedCirclesOnly=${subscribed}&FinishedOnly=${finished}&CompletedOnly=${completed}`
//       );
//       dispatch(getRacesSuccess(response.data));
//     } catch (error) {
//       dispatch(getRacesFailure());
//     }
//   };
// };
export const addNewRace = (raceData) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.post('/race', raceData);
      showNotification('success', response.data?.message);
      dispatch({ type: 'CLEAR_SEGMENTS' });
      dispatch({ type: 'CLEAR_ROUTES' });
      return true;
    } catch (error) {
      showNotification('error', error.response?.data?.error ? error.response?.data?.error : 'All fields are required');

      return false;
    }
  };
};

const getSingleRaceRequest = () => ({ type: GET_SINGLE_RACE_REQUEST });
const getSingleRaceSuccess = (singleRace) => ({ type: GET_SINGLE_RACE_SUCCESS, singleRace });
const getSingleRaceFailure = () => ({ type: GET_SINGLE_RACE_FAILURE });

export const getSingleRace = (id) => {
  return async (dispatch) => {
    dispatch(getSingleRaceRequest());
    const response = await axiosApi.get(`/race/${id}`);
    dispatch(getSingleRaceSuccess(response.data?.data));
    try {
    } catch (error) {
      dispatch(getSingleRaceFailure());
    }
  };
};

export const editRace = (data) => {
  return async () => {
    try {
      const response = await axiosApi.put('/race', data);
      showNotification('success', response?.data?.message);
      return true;
    } catch (error) {}
  };
};

const getActivityRequest = () => ({ type: GET_ACTIVITY_REQUEST });
const getActivitySuccess = (activity) => ({ type: GET_ACTIVITY_SUCCESS, activity });
const getActivityFailure = () => ({ type: GET_ACTIVITY_FAILURE });

export const getActivity = (from = null, to = null) => {
  return async (dispatch) => {
    try {
      dispatch(getActivityRequest());
      const response = await axiosApi.get('/activity', {
        params: {
          from,
          to,
        },
      });
      dispatch(getActivitySuccess(response.data?.data));
    } catch (error) {
      dispatch(getActivityFailure());
    }
  };
};

export const registerRace = (id) => {
  return async () => {
    try {
      const response = await axiosApi.post(`/race/register/${id}`);
      response && showNotification('success', 'Succesfully registered on race');
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
    }
  };
};
export const unregisterRace = (id) => {
  return async () => {
    try {
      const response = await axiosApi.post(`/race/unregister/${id}`);
      response && showNotification('success', 'Succesfully unregistered on race');
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
    }
  };
};

const getSegmentRequest = () => ({ type: GET_SEGMENT_REQUEST });
const getSegmentSuccess = (segment) => ({ type: GET_SEGMENT_SUCCESS, segment });
const getSegmentFailure = () => ({ type: GET_SEGMENT_FAILURE });

export const getStravaSegment = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getSegmentRequest());
      const response = await axiosApi.get(`/segment/${id}`);
      dispatch(getSegmentSuccess(response.data?.data));
      return true;
    } catch (error) {
      dispatch(getSegmentFailure());
    }
  };
};

export const addNewStravaSegment = (id) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.get(`/segment/${id}`);

      return response?.data?.data;
    } catch (error) {
      dispatch(getSegmentFailure());
    }
  };
};

const getRouteRequest = () => ({ type: GET_ROUTE_REQUEST });
const getRouteSuccess = (route) => ({ type: GET_ROUTE_SUCCESS, route });
const getRouteFailure = () => ({ type: GET_ROUTE_FAILURE });

export const getRoute = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getRouteRequest());
      const response = await axiosApi.get(`/route/${id}`);
      showNotification('success', response.data?.message);
      dispatch(getRouteSuccess(response.data?.data));
      return true;
    } catch (error) {
      dispatch(getRouteFailure());
      showNotification('error', error.response?.data.error);
    }
  };
};

const getSingleRouteRequest = () => ({ type: GET_SINGLE_ROUTE_REQUEST });
const getSingleRouteSuccess = (singleRoute) => ({ type: GET_SINGLE_ROUTE_SUCCESS, singleRoute });
const getSingleRouteFailure = () => ({ type: GET_SINGLE_ROUTE_FAILURE });

export const getSingleRoute = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getSingleRouteRequest());
      const response = await axiosApi.get(`/route/${id}`);
      showNotification('success', response.data?.message);
      dispatch(getSingleRouteSuccess(response.data?.data));
      return true;
    } catch (error) {
      dispatch(getSingleRouteFailure());
      showNotification('error', error.response?.data.error);
    }
  };
};

const addNewSegmentSuccess = (segment) => ({ type: 'ADD_SEGMENTS_TO_RACE', segment });

export const addNewSegment = (segment) => {
  return (dispatch) => {
    dispatch(addNewSegmentSuccess(segment));
    try {
      dispatch();
    } catch (error) {
      showNotification('error', error.response?.data.error);
    }
  };
};

const getActivityByIdRequest = () => ({ type: GET_ACTIVITY_BY_ID_REQUEST });
const getActivityByIdSuccess = (activitybyId) => ({ type: GET_ACTIVITY_BY_ID_SUCCESS, activitybyId });
const getActivityByIdFailure = () => ({ type: GET_ACTIVITY_BY_ID_FAILURE });

export const getActivityById = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getActivityByIdRequest());
      const response = await axiosApi.get(`/activity/${id}`);

      dispatch(getActivityByIdSuccess(response.data?.data));
    } catch (error) {
      dispatch(getActivityByIdFailure());
    }
  };
};

//

export const deleteRace = (id) => {
  return async () => {
    try {
      const response = await axiosApi.delete('/race', { params: { id } });
      showNotification('success', response.data?.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return true;
    }
  };
};

const completeRaceRequest = () => ({ type: COMPLETE_RACE_REQUEST });
const completeRaceSuccess = () => ({ type: COMPLETE_RACE_SUCCESS });
const completeRaceFailure = (missingSegment) => ({ type: COMPLETE_RACE_FAILURE, missingSegment });

export const completeRace = (id, activityId) => {
  return async (dispatch) => {
    try {
      dispatch(completeRaceRequest());
      const response = await axiosApi.post(`/race/complete?id=${id}&activityId=${activityId}`);
      dispatch(completeRaceSuccess());
      showNotification('success', response.data?.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      if (error.response?.data.error === 'RACE_SEGMENT_MISSED') {
        const missingSegmentsError = error.response?.data?.details;
        dispatch(completeRaceFailure(missingSegmentsError));
      }
      return false;
    }
  };
};

export const adminRaceUnregister = (raceId, userId) => {
  return async () => {
    try {
      const response = await axiosApi.post(`/admin/race/unregister?raceId=${raceId}&userId=${userId}`);
      showNotification('success', response.data?.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};

export const chooseRaceRoute = (data) => {
  return async () => {
    try {
      const response = await axiosApi.post('/race/choose-route', data);
      showNotification('success', response.data?.message);
    } catch (error) {
      showNotification('error', error.response?.data.error);
    }
  };
};

export const calculateRace = (id) => {
  return async () => {
    try {
      await axiosApi.post(`/race/calculate/${id}`);
      showNotification('success', 'Successfully calculated race results');
      return true;
    } catch (error) {
      return false;
    }
  };
};
