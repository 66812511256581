import { Dropdown } from 'antd';
import React from 'react';
import { ReactComponent as Globe } from '../../assets/globe-ic.svg';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import classNames from 'classnames';
import './styles.scss';

const ChangeLanguage = () => {
  const { i18n } = useTranslation();
  const currentLanguageCode = cookies.get('i18next') || 'en';

  const changeLangHandler = (lang) => {
    i18n.changeLanguage(lang);
  };

  const items = [
    {
      key: '1',
      label: (
        <span onClick={() => changeLangHandler('fr')} className={classNames({ active: currentLanguageCode === 'fr' })}>
          <span className="fi fi-fr" style={{ marginRight: '5px' }}></span>
          Français
        </span>
      ),
    },
    {
      key: '2',
      label: (
        <span onClick={() => changeLangHandler('en')} className={classNames({ active: currentLanguageCode === 'en' })}>
          <span className="fi fi-gb" style={{ marginRight: '5px' }}></span>
          English
        </span>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={['click']}
    >
      <Globe className="globe-ic" />
    </Dropdown>
  );
};

export default ChangeLanguage;
