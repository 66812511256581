import React, { useContext, useEffect, useState } from 'react';
import { deleteUser, getAthletes, getRoles } from '../../../store/actions/athletes/athletesActions';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Select, Spin, Table } from 'antd';
import Input from 'antd/lib/input/Input';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../appContext';

import '../styles.scss';
const { Option } = Select;

const ManageUsers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAdmin } = useContext(AppContext);
  const currentUserId = useSelector((state) => state.user?.profile?.profile?.nameid);
  const loading = useSelector((state) => state.athletes?.athletsLoading);
  const athletes = useSelector((state) => state.athletes?.athletes?.items);
  const roles = useSelector((state) => state.athletes?.roles);

  const validated = [
    { id: '', description: t('all') },
    { id: true, description: t('validated') },
    { id: false, description: t('unvalidated') },
  ];

  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [isValidated, setIsValidated] = useState('');

  const resetSearchHandler = () => {
    setName('');
    setRole('');
    dispatch(getAthletes());
  };
  const searchUserHandler = () => {
    dispatch(getAthletes(name, role, isValidated));
  };

  const deleteCostumerHandler = async (id) => {
    const response = await dispatch(deleteUser(id));
    if (response) dispatch(getAthletes());
  };
  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <>
          {isAdmin ? (
            <Link to={`/details/user/${record.id}`}>
              {record.firstName} {record.lastName}
            </Link>
          ) : (
            <>
              {record.firstName} {record.lastName}
            </>
          )}
        </>
      ),
    },

    {
      title: t('tableColumns.email'),
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => <>{record.email ? record.email : 'not specified'}</>,
    },
    {
      title: t('tableColumns.team'),
      dataIndex: 'team',
      key: 'name',
      render: (_, record) => (
        <>
          {record.currentTeam ? (
            <Link to={`/team/details/${record.currentTeam.id}`}>{record.currentTeam.name}</Link>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: t('tableColumns.role'),
      dataIndex: 'role',
      key: 'role',
      render: (_, record) => <>{record.role?.description ? record.role?.description : 'not specified'}</>,
    },
    {
      title: t('tableColumns.races'),
      dataIndex: 'races',
      key: 'races',
      render: (_, record) => (
        <>
          {record.completedRacesCount}/{record.registeredRacesCount}
        </>
      ),
    },
    {
      title: t('tableColumns.cups'),
      dataIndex: 'cups',
      key: 'cups',
      render: (_, record) => <>{record.registeredCupsCount}</>,
    },
    {
      title: t('tableColumns.circles'),
      dataIndex: 'circles',
      key: 'circles',
      render: (_, record) => <>{record.circlesCount}</>,
    },
    {
      title: t('tableColumns.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <div className="table-actions">
          {record.id !== currentUserId ? (
            <>
              <Link className="edit-btn" to={`/edit/user/${record.id}`} style={{ color: '#000 ' }}>
                <EditOutlined className="ic-antd edit m-r-5" />
              </Link>
              <DeleteOutlined className="ic-antd delete" onClick={() => deleteCostumerHandler(record.id)} />
            </>
          ) : null}
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAthletes());
  }, [dispatch]);

  return (
    <div className="content">
      <h2 className="title">{t('navigation.manageUsers')}</h2>
      <div className="search-block">
        <div className="input-section">
          <Input
            placeholder={t('buttons.name')}
            className="input-field"
            value={name}
            onChange={(e) => setName(e.target.value)}
            allowClear
            size="large"
          />

          <Select allowClear style={{ width: '170px' }} defaultValue={'All'} onChange={(e) => setRole(e)} size="large">
            {roles.map((elem) => (
              <>{!loading && <Option key={elem.id}>{elem.description}</Option>}</>
            ))}
          </Select>
          <Select
            allowClear
            style={{ width: '170px' }}
            defaultValue={validated[0].id}
            onChange={(e) => setIsValidated(e)}
            size="large"
          >
            {validated.map((elem) => (
              <>{!loading && <Option key={elem.id}>{elem.description}</Option>}</>
            ))}
          </Select>

          <Button className="search-btn" onClick={searchUserHandler} size="large">
            <SearchOutlined />
            {t('buttons.search')}
          </Button>
          <Button className="search-btn" onClick={resetSearchHandler} size="large">
            {t('buttons.reset')}
          </Button>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="races-table">
          <Table dataSource={athletes} columns={columns} />
        </div>
      </Spin>
    </div>
  );
};

export default ManageUsers;
