import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserProfile, updateEmail, updateUserProfile } from '../../store/actions/user/userActions';
import { Button, Input, Typography, Radio, Space, Spin, DatePicker, Divider, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import UserCirclesTab from '../../components/Tabs/UsersTab/UserCirclesTab';
import UserCupsTab from '../../components/Tabs/UsersTab/UserCupsTab';
import UserRacesTab from '../../components/Tabs/UsersTab/UserRacesTab';
import moment from 'moment';
import './styles.scss';
import _ from 'lodash';
const { Panel } = Collapse;
const { Title } = Typography;

const Profile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userId = useSelector((state) => state?.user?.profile?.profile?.nameid);
  const userProfile = useSelector((state) => state?.user?.userInformation);
  const loading = useSelector((state) => state?.user?.userInformationLoading);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [birthday, setBirthday] = useState('');
  const [sex, setSex] = useState(null);
  const [sexDisplayValue, setSexDisplayValue] = useState(null);

  const [changeName, setChangeName] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [changeBirthday, setChangeBirthday] = useState(false);
  const [changeSex, setChangeSex] = useState(false);
  const [sorted, setSorted] = useState(userProfile?.races);
  const [test, setTest] = useState(false);

  const SEX_MOCK = [
    {
      value: 1,
      name: t('man'),
    },
    {
      value: 2,
      name: t('woman'),
    },
    {
      value: 0,
      name: t('notRespond'),
    },
  ];

  const updateUserHandler = async () => {
    if (email) {
      await dispatch(updateEmail(email));
    }

    const response = await dispatch(
      updateUserProfile({ id: userId, firstName, lastName, email, birthDate: birthday, sex })
    );
    if (response) {
      setChangeName(false);
      setChangeEmail(false);
      setChangeBirthday(false);
      setChangeSex(false);
      dispatch(getUserProfile());
    }
  };

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (userProfile) {
      setFirstName(userProfile?.firstName);
      setLastName(userProfile?.lastName);
      setEmail(userProfile?.email);
      setBirthday(userProfile?.birthDate);
      setSex(userProfile?.sex?.value);
      setSorted(userProfile?.races);
    }
  }, [userProfile]);

  useEffect(() => {
    if (sex === 0) {
      setSexDisplayValue(t('notRespond'));
    } else if (sex === 1) {
      setSexDisplayValue(t(t('man')));
    } else {
      setSexDisplayValue(t('womanman'));
    }
  }, [userProfile, sex]);

  const sortRacesByName = () => {
    setTest((prev) => !prev);
    if (test) {
      setSorted(_.orderBy(userProfile?.races, ['name', 'asc']));
    } else {
      setSorted(_.orderBy(userProfile?.races, [['name', 'desc']]));
    }
  };

  return (
    <div className="content">
      <Spin spinning={loading} size="large">
        <div className="profile__container">
          <h2 className="title m-b-50">{t('myProfile')}</h2>
          <div className="change__info-row">
            {changeName ? (
              <div className="change__info-row__item">
                <Input size="large" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              </div>
            ) : (
              <div className="change__info-row__item">
                <Title level={4}>
                  {t('titles.firstName')} : {firstName}
                </Title>
              </div>
            )}
            <div className="change__info-row__item">
              {/* <Button size="large" onClick={() => setChangeName((prev) => !prev)}>
                {changeName ? 'close' :<>{t('buttons.change')}</>}
              </Button> */}
            </div>
          </div>
          <div className="change__info-row">
            {changeName ? (
              <div className="change__info-row__item">
                <Input size="large" value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </div>
            ) : (
              <div className="change__info-row__item">
                <Title level={4}>
                  {' '}
                  {t('titles.lastName')} : {lastName}
                </Title>
              </div>
            )}
            <div className="change__info-row__item">
              {/* <Button size="large" onClick={() => setChangeName((prev) => !prev)}>
              {changeName ? 'close' :<>{t('buttons.change')}</>}
            </Button> */}
            </div>
          </div>
          <div className="change__info-row">
            {changeEmail ? (
              <div className="change__info-row__item">
                <Input size="large" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
            ) : (
              <div className="change__info-row__item">
                <Title level={4}>Email : {email}</Title>
              </div>
            )}

            <div className="change__info-row__item">
              <Button size="large" onClick={() => setChangeEmail((prev) => !prev)}>
                {changeEmail ? 'close' : <>{t('buttons.change')}</>}
              </Button>
            </div>
          </div>
          {/* <div className="change__info-row__item"></div> */}
          <div className="change__info-row">
            {changeBirthday ? (
              <div className="change__info-row__item">
                <DatePicker
                  size="large"
                  format="YYYY-MM-DD"
                  placeholder="Birthday"
                  value={birthday !== null ? moment(birthday) : ''}
                  onChange={(date, dateString) => setBirthday(dateString)}
                  style={{ width: '100%' }}
                />
              </div>
            ) : (
              <div className="change__info-row__item">
                <Title level={4}>
                  {t('titles.birthDay')} : {birthday ? moment(birthday).format('YYYY-MM-DD') : t('titles.notBirthDay')}
                </Title>
              </div>
            )}
            <div className="change__info-row__item">
              <Button size="large" onClick={() => setChangeBirthday((prev) => !prev)}>
                {changeBirthday ? 'close' : <>{t('buttons.change')}</>}
              </Button>
            </div>
          </div>

          <div className="change__info-row">
            {changeSex ? (
              <div className="change__info-row__item">
                <Radio.Group onChange={(e) => setSex(e.target.value)} defaultValue={sex}>
                  <Space direction="vertical">
                    {SEX_MOCK.map((item) => (
                      <Radio value={item.value}>{item.name}</Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </div>
            ) : (
              <div className="change__info-row__item">
                <Title level={4}>
                  {' '}
                  {t('titles.sex')} : {sexDisplayValue}
                </Title>
              </div>
            )}
            <div className="change__info-row__item">
              <Button size="large" onClick={() => setChangeSex((prev) => !prev)}>
                {changeSex ? 'close' : <>{t('buttons.change')}</>}
              </Button>
            </div>
          </div>

          <div className="change__info-row m-b-50">
            <div className="change__info-row__item"></div>
            <div className="change__info-row__item">
              <Button size="large" onClick={updateUserHandler} type="primary">
                {t('buttons.save')}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="userInformation__container">
            <h2 className="title m-b-30">
              {userProfile?.currentTeam !== null ? (
                <>
                  {t('myTeam')}{' '}
                  <Link to={`/team/details/${userProfile?.currentTeam?.id}`}>{userProfile?.currentTeam.name}</Link>
                </>
              ) : (
                <>{t('titles.noTeam')}</>
              )}
            </h2>
            <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
              <Panel header={t('titles.myCircles')} key={1}>
                <UserCirclesTab myCircles={userProfile?.circles} />
              </Panel>
            </Collapse>

            <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
              <Panel header={t('titles.myRaces')} key={1}>
                <UserRacesTab />
              </Panel>
            </Collapse>
            {userProfile?.cups?.length > 1 && (
              <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
                <Panel header={t('titles.myCups')} key={1}>
                  <UserCupsTab myCups={userProfile?.cups} />
                </Panel>
              </Collapse>
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default Profile;
