import React from 'react';
import './styles.scss';

const DataProtectionPage = () => {
  return (
    <div className="content">
      <div className="rgpd__container">
        <h2 className="title">Protection des données</h2>
        <h4>
          Le responsable au sens des lois sur la protection des données, en particulier le règlement général de l'UE sur
          la protection des données (RGPD), est:
        </h4>
        <h4>Stéphan Brachet (Racepresso)</h4>
        <h3>Vos droits en tant que personne concernée</h3>
        <div className="rgpd-clauses">
          <p>
            <b>
              Vous pouvez à tout moment exercer les droits suivants en utilisant les coordonnées fournies par notre
              délégué à la protection des données :
            </b>
          </p>
          <ul>
            <li>Informations sur vos données stockées par nous et leur traitement (article 15 RGPD),</li>
            <li>Correction des données personnelles erronées (article 16 RGPD)</li>
            <li>Suppression de vos données stockées par nos soins (article 17 RGPD)</li>
            <li>
              Restriction du traitement des données si nous ne sommes pas encore autorisés à supprimer vos données en
              raison d'obligations légales (article 18 RGPD)
            </li>
            <li>Opposition au traitement de vos données par nos soins (article 21 RGPD) et</li>
            <li>
              Transférabilité des données si vous avez consenti au traitement des données ou si vous avez conclu un
              contrat avec nous (article 20 RGPD)
            </li>
          </ul>
        </div>
        <div className="rgpd-clauses">
          <p>
            Si vous nous avez donné votre consentement, vous pouvez le révoquer à tout moment avec effet pour l'avenir.
            Vous pouvez à tout moment vous adresser à une autorité de surveillance en cas de réclamation, par ex. B. à
            l'autorité de surveillance compétente de l'État fédéral de votre lieu de résidence ou à l'autorité
            responsable de nous en tant qu'organisme responsable.
          </p>
          <p>
            Vous trouverez une liste des autorités de surveillance (pour le domaine non public) avec leurs adresses sur
            :
            <a
              href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html ."
              target="_blank"
              rel="noreferrer"
              className="p-l-10"
            >
              Learn more
            </a>
          </p>
        </div>
        <div className="rgpd-clauses">
          <h3 className="subtitle">Collecte d'informations générales lors de la visite de notre site Web</h3>
          <h2 className="title">Nature et finalité du traitement :</h2>
          <span>
            Si vous accédez à notre site Web, c'est-à-dire si vous ne vous enregistrez pas ou ne fournissez pas
            d'informations, des informations de nature générale sont automatiquement enregistrées. Ces informations
            (fichiers journaux du serveur) comprennent, par exemple, le type de navigateur Web, le système
            d'exploitation utilisé, le nom de domaine de votre fournisseur d'accès Internet, votre adresse IP, etc.
          </span>
          <span>En particulier, elles sont traitées pour les finalités suivantes :</span>
          <ul>
            <li>Assurer une connexion sans problème au site Web</li>
            <li>garantir une utilisation fluide de notre site Web</li>
            <li>Évaluation de la sécurité et de la stabilité du système ainsi</li>
            <li>à d'autres fins administratives.</li>
          </ul>
          <p>
            Nous n'utilisons pas vos données pour tirer des conclusions sur votre personne. Les informations de ce type
            peuvent être évaluées statistiquement par nous afin d'optimiser notre site Web et la technologie qui le
            sous-tend.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Base légale:</h2>
          <p>
            Le traitement a lieu conformément à l'article 6, paragraphe 1, lettre f du RGPD sur la base de notre intérêt
            légitime à améliorer la stabilité et la fonctionnalité de notre site Web.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Destinataire:</h2>
          <p>
            Les destinataires des données peuvent être des prestataires de services techniques qui agissent en tant que
            sous-traitants pour l'exploitation et la maintenance de notre site Web.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Durée de stockage :</h2>
          <p>
            Les données seront supprimées dès qu'elles ne seront plus nécessaires aux fins de la collecte. C'est
            généralement le cas pour les données utilisées pour fournir le site Web lorsque la session respective est
            terminée.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Disposition prescrite ou requise :</h2>
          <p>
            La fourniture des données personnelles susmentionnées n'est requise ni par la loi ni par contrat. Sans
            l'adresse IP, cependant, le service et la fonctionnalité de notre site Web ne peuvent pas être garantis. De
            plus, certains services peuvent ne pas être disponibles ou être limités. Pour cette raison, une objection
            est exclue
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Nature et finalité du traitement :</h2>
          <p>
            Lors de l'enregistrement pour utiliser nos services personnalisés, certaines données personnelles sont
            collectées, telles que le nom, l'adresse, les données de contact et de communication (par exemple, le numéro
            de téléphone et l'adresse e-mail). Si vous êtes enregistré chez nous, vous pouvez accéder au contenu et aux
            services que nous proposons uniquement aux utilisateurs enregistrés. Les utilisateurs enregistrés ont
            également la possibilité de modifier ou de supprimer à tout moment les données fournies lors de
            l'inscription. Bien entendu, nous vous fournirons également à tout moment des informations sur les données
            personnelles que nous avons stockées à votre sujet.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Base légale:</h2>
          <p>
            Les données saisies lors de l'inscription sont traitées sur la base du consentement de l'utilisateur
            (article 6, paragraphe 1, point a) du RGPD). Si l'enregistrement sert à exécuter un contrat auquel la
            personne concernée est partie ou à exécuter des mesures précontractuelles, la base juridique supplémentaire
            pour le traitement des données est l'article 6, paragraphe 1, point b), du RGPD.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Destinataire:</h2>
          <p>
            Les destinataires des données peuvent être des prestataires de services techniques qui agissent en tant que
            sous-traitants pour l'exploitation et la maintenance de notre site Web.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Durée de stockage :</h2>
          <p>
            Les données ne seront traitées dans ce contexte que si le consentement correspondant a été donné. Ils seront
            alors supprimés, à condition qu'il n'y ait pas d'exigences légales de stockage contraires. Pour nous
            contacter dans ce contexte, veuillez utiliser les coordonnées indiquées à la fin de cette déclaration de
            protection des données.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Disposition prescrite ou requise :</h2>
          <p>
            La fourniture de vos données personnelles est volontaire, basée uniquement sur votre consentement. Sans
            fournir vos données personnelles, nous ne pouvons pas vous donner accès au contenu et aux services que nous
            proposons.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h3 className="subtitle">Prestation de services payants</h3>
          <h2 className="title">Nature et finalité du traitement :</h2>
          <p>
            Afin de fournir des services payants, nous demanderons des données supplémentaires, telles que les détails
            de paiement, afin de pouvoir exécuter votre commande.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Base légale:</h2>
          <p>
            Le traitement des données nécessaires à la conclusion du contrat est basé sur l'article 6, paragraphe 1,
            point b), du RGPD.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Destinataire:</h2>
          <p>Les destinataires des données peuvent être des sous-traitants.</p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Durée de stockage : </h2>
          <p>
            Nous stockons ces données dans nos systèmes jusqu'à l'expiration des délais de conservation légaux.
            Celles-ci sont généralement de 6 ou 10 ans pour des raisons de bonne comptabilité et d'exigences fiscales.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Disposition prescrite ou requise :</h2>
          <p>
            La fourniture de vos données personnelles est volontaire. Sans fournir vos données personnelles, nous ne
            pouvons pas vous donner accès au contenu et aux services que nous proposons.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h3 className="subtitle">fonction de commentaire</h3>
          <h2 className="title">Nature et finalité du traitement :</h2>
          <p>
            Si les utilisateurs laissent des commentaires sur notre site Web, l'heure de leur création et le nom
            d'utilisateur préalablement sélectionné par le visiteur du site Web sont enregistrés en plus de ces
            informations. Ceci est pour notre sécurité, car nous pouvons être poursuivis pour contenu illégal sur notre
            site Web, même s'il a été créé par des utilisateurs.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Base légale:</h2>
          <p>
            Les données saisies en tant que commentaire sont traitées sur la base d'un intérêt légitime (article 6,
            paragraphe 1, point f) du RGPD). En fournissant la fonction de commentaire, nous souhaitons vous permettre
            d'interagir de manière simple. Les informations que vous fournissez seront stockées dans le but de traiter
            la demande et pour d'éventuelles questions de suivi.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Destinataire:</h2>
          <p>Les destinataires des données peuvent être des sous-traitants.</p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Durée de stockage :</h2>
          <p>Les données seront supprimées au plus tard 6 mois après le traitement de la demande.</p>
          <p>
            S'il existe une relation contractuelle, nous sommes soumis aux délais de conservation légaux conformément au
            code de commerce Français et supprimons vos données après l'expiration de ces délais
          </p>
        </div>
        <div className="rgpd-clauses">
          <h2 className="title">Disposition prescrite ou requise :</h2>
          <p>
            La fourniture de vos données personnelles est volontaire. Cependant, nous ne pouvons traiter votre demande
            que si vous nous fournissez votre nom, votre adresse e-mail et la raison de la demande.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h3 className="subtitle">Cryptage SSL</h3>

          <p>
            Afin de protéger la sécurité de vos données lors de la transmission, nous utilisons des méthodes de cryptage
            de pointe (par exemple SSL) via HTTPS.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h3 className="subtitle">Modification de notre politique de confidentialité</h3>
          <p>
            Nous nous réservons le droit d'adapter cette déclaration de protection des données afin qu'elle corresponde
            toujours aux exigences légales en vigueur ou d'apporter des modifications à nos services dans la déclaration
            de protection des données, par exemple lors de l'introduction de nouveaux services. La nouvelle déclaration
            de protection des données s'appliquera alors à votre prochaine visite.
          </p>
        </div>
        <div className="rgpd-clauses">
          <h3 className="subtitle">Questions au délégué à la protection des données</h3>
          <p>
            Si vous avez des questions sur la protection des données, veuillez nous envoyer un e-mail ou contacter
            directement la personne responsable de la protection des données au sein de notre organisation :
            <span>Stéphan Brachet (RacePresso.cc)</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DataProtectionPage;
