import {
  GET_CIRCLES_REQUEST,
  GET_CIRCLES_SUCCESS,
  GET_CIRCLES_FAILURE,
  GET_SINGLE_CIRCLE_REQUEST,
  GET_SINGLE_CIRCLE_SUCCESS,
  GET_SINGLE_CIRCLE_FAILURE,
  GET_ALL_CIRCLE_REQUEST,
  GET_ALL_CIRCLE_SUCCESS,
  GET_ALL_CIRCLE_FAILURE,
} from '../actions/circle/actionTypes';

const initialState = {
  managedCircles: [],
  managedCirclesLoading: [],
  singleCircle: [],
  singleCircleLoading: false,
  allCircles: [],
  allCirclesLoading: false,
};

const circleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CIRCLES_REQUEST:
      return { ...state, managedCirclesLoading: true };
    case GET_CIRCLES_SUCCESS:
      return { ...state, managedCirclesLoading: false, managedCircles: action.managedCircles };
    case GET_CIRCLES_FAILURE:
      return { ...state, managedCirclesLoading: false };
    case GET_SINGLE_CIRCLE_REQUEST:
      return { ...state, singleCircleLoading: true };
    case GET_SINGLE_CIRCLE_SUCCESS:
      return { ...state, singleCircleLoading: false, singleCircle: action.singleCircle };
    case GET_SINGLE_CIRCLE_FAILURE:
      return { ...state, singleCircleLoading: false };
    case GET_ALL_CIRCLE_REQUEST:
      return { ...state, allCirclesLoading: true };
    case GET_ALL_CIRCLE_SUCCESS:
      return { ...state, allCirclesLoading: false, allCircles: action.allCircles };
    case GET_ALL_CIRCLE_FAILURE:
      return { ...state, allCirclesLoading: false };
    default:
      return { ...state };
  }
};

export default circleReducer;
