import axiosApi from '../../../utils/axiosApi';
import { showNotification } from '../../../utils/showNotification';
import {
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_FAILURE,
  GET_MY_TEAM_REQUEST,
  GET_MY_TEAM_SUCCESS,
  GET_MY_TEAM_FAILURE,
  GET_TEAM_ID_REQUEST,
  GET_TEAM_ID_SUCCESS,
  GET_TEAM_ID_FAILURE,
  GET_USER_INVITE_REQUEST,
  GET_USER_INVITE_SUCCESS,
  GET_USER_INVITE_FAILURE,
} from './actionTypes';

const getTeamsRequest = () => ({ type: GET_TEAMS_REQUEST });
const getTeamsSuccess = (teams) => ({ type: GET_TEAMS_SUCCESS, teams });
const getTeamsFailure = () => ({ type: GET_TEAMS_FAILURE });

export const getTeams = (name = null) => {
  return async (dispatch) => {
    try {
      dispatch(getTeamsRequest());
      const response = await axiosApi.get('/team', {
        params: {
          Name: name,
        },
      });
      dispatch(getTeamsSuccess(response.data?.data?.items));
    } catch (error) {
      dispatch(getTeamsFailure());
    }
  };
};

const getMyTeamRequest = () => ({ type: GET_MY_TEAM_REQUEST });
const getMyTeamSuccess = (myTeam) => ({ type: GET_MY_TEAM_SUCCESS, myTeam });
const getMyTeamFailure = () => ({ type: GET_MY_TEAM_FAILURE });

export const getMyTeam = () => {
  return async (dispatch) => {
    try {
      dispatch(getMyTeamRequest());
      const response = await axiosApi.get('/team/my');
      dispatch(getMyTeamSuccess(response.data?.data));
    } catch (error) {
      dispatch(getMyTeamFailure());
    }
  };
};

const getTeamIdRequest = () => ({ type: GET_TEAM_ID_REQUEST });
const getTeamIdSuccess = (teamId) => ({ type: GET_TEAM_ID_SUCCESS, teamId });
const getTeamIdFailure = () => ({ type: GET_TEAM_ID_FAILURE });

export const getTeamId = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getTeamIdRequest());
      const response = await axiosApi.get(`/team/${id}`);
      dispatch(getTeamIdSuccess(response.data?.data));
    } catch (error) {
      dispatch(getTeamIdFailure());
    }
  };
};

export const createTeam = (teamData) => {
  return async () => {
    try {
      const response = await axiosApi.post('/team', teamData);
      showNotification('success', response?.data?.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};

export const updateTeam = (teamData) => {
  return async () => {
    try {
      const response = await axiosApi.put('/team', teamData);
      showNotification('success', response?.data.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};

export const deleteTeam = (id) => {
  return async () => {
    try {
      const response = await axiosApi.delete('/team', {
        params: {
          id: id,
        },
      });
      showNotification('success', response?.data?.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};

export const joinTeamAccept = (id) => {
  return async () => {
    try {
      const response = await axiosApi.post(`/team/join/accept?userId=${id}`);
      showNotification('success', response?.data.message);
      return true;
    } catch (error) {
      return false;
    }
  };
};

export const joinTeamReject = (id) => {
  return async () => {
    try {
      const response = await axiosApi.post(`/team/join/reject?userId=${id}`);
      showNotification('success', response?.data.message);
      return true;
    } catch (error) {
      return false;
    }
  };
};

export const joinTeamCancel = () => {
  return async () => {
    try {
      const response = await axiosApi.post('/team/join/cancel');
      showNotification('success', response?.data.message);
      return true;
    } catch (error) {
      return false;
    }
  };
};

export const joinTeam = (id) => {
  return async () => {
    try {
      const response = await axiosApi.post(`/team/join?teamId=${id}`);
      showNotification('success', response?.data.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};

export const leaveTeam = () => {
  return async () => {
    try {
      const response = await axiosApi.post('/team/leave');
      showNotification('success', response?.data.message);
      return true;
    } catch (error) {
      return false;
    }
  };
};

export const teamInvite = (id) => {
  return async () => {
    try {
      const response = await axiosApi.post(`/team/invite?userId=${id}`);
      showNotification('success', response?.data.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};

export const teamInviteAccept = (id) => {
  return async () => {
    try {
      const response = await axiosApi.post(`/team/invite/accept?teamId=${id}`);
      showNotification('success', response?.data.message);
      return true;
    } catch (error) {
      return false;
    }
  };
};
export const teamInviteReject = (id) => {
  return async () => {
    try {
      const response = await axiosApi.post(`/team/invite/reject?teamId=${id}`);
      showNotification('success', response?.data.message);
      return true;
    } catch (error) {
      return false;
    }
  };
};

const getUsersForInviteRequest = () => ({ type: GET_USER_INVITE_REQUEST });
const getUsersForInviteSuccess = (usersInvited) => ({ type: GET_USER_INVITE_SUCCESS, usersInvited });
const getUsersForInviteFailure = () => ({ type: GET_USER_INVITE_FAILURE });

export const getUsersForInvite = () => {
  return async (dispatch) => {
    try {
      dispatch(getUsersForInviteRequest());
      const response = await axiosApi.get('/team/invite/users');
      dispatch(getUsersForInviteSuccess(response?.data?.data?.items));
    } catch (error) {
      dispatch(getUsersForInviteFailure());
    }
  };
};
