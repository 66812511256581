import { Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const CupRacesTab = ({ races }) => {
  const { t } = useTranslation();
  const userId = useSelector((state) => state.user?.profile?.profile?.nameid);

  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <Link to={`/details/race/${record.id}`}>{record.name}</Link>,
    },
    {
      title: t('tableColumns.distance'),
      dataIndex: 'distance',
      key: 'distance',
      render: (_, record) => <>{Number(record.distance / 1000).toFixed(2)}km</>,
    },
    {
      title: t('tableColumns.elevation'),
      dataIndex: 'elevation',
      key: 'elevation',
      render: (_, record) => <>{Number(record.elevation).toFixed(2)} m </>,
      responsive: ['lg'],
    },
    {
      title: t('tableColumns.cupCoefficient'),
      dataIndex: 'cupCoefficient',
      key: 'cupCoefficient',
      render: (_, record) => <>{record.cupCoefficient}</>,
      responsive: ['lg'],
    },
    {
      title: t('tableColumns.bonusParticipating'),
      dataIndex: 'completedBonus',
      key: 'completedBonus',
      render: (_, record) => <>{record.cupCompletedBonus}</>,
      responsive: ['lg'],
    },
    {
      title: t('tableColumns.bonusWinner'),
      dataIndex: 'winnerBonus',
      key: 'winnerBonus',
      render: (_, record) => <>{record.cupWinnerBonus}</>,
      responsive: ['lg'],
    },
    {
      title: t('tableColumns.myRank'),
      dataIndex: 'myRank',
      key: 'myRank',
      render: (_, record) => {
        const myRank = record?.raceUsers.find((item) => item.user?.id === userId);
        const allUsers = record?.raceUsers.length;
        let a = record?.raceUsers.filter((item) => item.activity !== null);

        return <div>{myRank?.activity ? `${myRank?.rank} / ${a.length}` : '-'}</div>;
      },
    },
    {
      title: t('tableColumns.myPoints'),
      dataIndex: 'myPoints',
      key: 'myPoints',
      render: (_, record) => {
        const myPoints = record?.raceUsers.find((item) => item.user?.id === userId);
        return <div>{myPoints?.points ? myPoints?.points : '0'}</div>;
      },
    },
  ];
  return <Table columns={columns} dataSource={races} rowKey={'id'} />;
};

export default CupRacesTab;
