import { Button, Collapse, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MembersTab from '../../components/Tabs/MembersTab';
import RacesTab from '../../components/Tabs/RacesTab';
import { getCircleByID, subscribeCircle, unsubscribeCircle } from '../../store/actions/circle/circleActions';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import CircleCupsTab from '../../components/Tabs/CircleCupsTab';
import Map, { Marker, NavigationControl, Popup, FullscreenControl, GeolocateControl } from 'react-map-gl';
const { Panel } = Collapse;

const CircleDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const key = 'pk.eyJ1IjoiY3ljbG9jY2lubyIsImEiOiJjbGI0cWhjbHQwY3YwM3Zxc2EyNjA5OTJnIn0.5RM8MEXQQLl6iNTpSeqokg';
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.circle.singleCircleLoading);
  const singleCircle = useSelector((state) => state.circle.singleCircle);

  const subscribeHandler = async () => {
    const response = await dispatch(subscribeCircle(id));
    if (response) dispatch(getCircleByID(id));
  };

  const unsubscribeHandler = async () => {
    const response = await dispatch(unsubscribeCircle(id));
    if (response) dispatch(getCircleByID(id));
  };

  useEffect(() => {
    dispatch(getCircleByID(id));
  }, [dispatch, id]);

  return (
    <div className="content">
      <Spin spinning={loading}>
        {!loading && (
          <>
            <div className="detail-circle">
              <h1 className="title edit-text__title">
                {singleCircle.name} - {singleCircle?.isPublic ? t('public') : t('private')}
              </h1>

              <p className="date-text" style={{ textAlign: 'center' }}>
                {t('owner')}: {singleCircle.owner}
              </p>
              <p className="circle__description">
                {t('desciption')} : {singleCircle.description}
              </p>

              <div className="circle-item_btn-group">
                {singleCircle?.allowToSubscribe && singleCircle?.isPublic && (
                  <Button className="sub" onClick={subscribeHandler}>
                    {t('buttons.subscribe')}
                  </Button>
                )}

                {singleCircle?.allowToSubscribe && !singleCircle?.isPublic && (
                  <Button className="sub" onClick={subscribeHandler}>
                    {t('buttons.requestForSub')}
                  </Button>
                )}

                {singleCircle?.allowToUnsubscribe && singleCircle?.isPublic && (
                  <Button className="unsub" onClick={unsubscribeHandler}>
                    {t('buttons.unsubscribe')}
                  </Button>
                )}

                {singleCircle?.allowToUnsubscribe && !singleCircle?.isPublic && (
                  <Button className="unsub" onClick={unsubscribeHandler}>
                    {t('buttons.cancelRequest')}
                  </Button>
                )}
              </div>

              {/* {singleCircle?.location && (
                <div className="map__container">
                  <Map
                    mapboxAccessToken="pk.eyJ1IjoiY3ljbG9jY2lubyIsImEiOiJjbGI0cWhjbHQwY3YwM3Zxc2EyNjA5OTJnIn0.5RM8MEXQQLl6iNTpSeqokg"
                    style={{
                      width: '100%',
                      height: 'inherit',
                    }}
                    initialViewState={{
                      longitude: singleCircle?.location.longitude,
                      latitude: singleCircle?.location.latitude,
                      zoom: 8.5,
                    }}
                    mapStyle="mapbox://styles/cycloccino/clbnd7ulk001g14nwetcitvcp"
                  >
                    <Marker longitude={singleCircle?.location.longitude} latitude={singleCircle?.location.latitude} />
                    <NavigationControl position="bottom-right" />
                    <FullscreenControl />
                    <GeolocateControl />
                  </Map>
                </div>
              )} */}

              <Collapse bordered={false} defaultActiveKey={1} className="m-b-10">
                <Panel header={t('membersOnCircle')} key={1}>
                  <MembersTab members={singleCircle?.members} />
                </Panel>
              </Collapse>
              <Collapse bordered={false} defaultActiveKey={1} className="m-b-10">
                <Panel header={t('racesOnCircle')} key={1}>
                  <RacesTab races={singleCircle.races} />
                </Panel>
              </Collapse>

              {singleCircle?.cups?.length > 0 && (
                <Collapse bordered={false} defaultActiveKey={1} className="m-b-10">
                  <Panel header={t('titles.cupsCircleTab')} key={1}>
                    <CircleCupsTab circleCups={singleCircle?.cups} />
                  </Panel>
                </Collapse>
              )}
            </div>
          </>
        )}
      </Spin>
    </div>
  );
};

export default CircleDetails;
