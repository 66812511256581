import axiosApi from '../../../utils/axiosApi';
import { showNotification } from '../../../utils/showNotification';
import {
  GET_ALL_CUPS_REQUEST,
  GET_ALL_CUPS_SUCCESS,
  GET_ALL_CUPS_FAILURE,
  GET_CUP_BYID_REQUEST,
  GET_CUP_BYID_SUCCESS,
  GET_CUP_BYID_FAILURE,
} from './actionTypes';

const getAllCupsRequest = () => ({ type: GET_ALL_CUPS_REQUEST });
const getAllCupsSuccess = (cups) => ({ type: GET_ALL_CUPS_SUCCESS, cups });
const getAllCupsFailure = () => ({ type: GET_ALL_CUPS_FAILURE });

export const getAllCups = () => {
  return async (dispatch) => {
    try {
      dispatch(getAllCupsRequest());
      const response = await axiosApi.get('/cup');
      dispatch(getAllCupsSuccess(response.data?.data?.items));
    } catch (error) {
      dispatch(getAllCupsFailure());
    }
  };
};

export const deleteCup = (id) => {
  return async () => {
    try {
      axiosApi.delete(`/cup?id=${id}`);
      showNotification('success', 'Successfully cup deleted');
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};

export const createCup = (data) => {
  return async () => {
    try {
      axiosApi.post('/cup', data);
      showNotification('success', 'Successfully created Cup');
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};

const getCupByIDRequest = () => ({ type: GET_CUP_BYID_REQUEST });
const getCupByIDSuccess = (cupByID) => ({ type: GET_CUP_BYID_SUCCESS, cupByID });
const getCupByIDFailure = () => ({ type: GET_CUP_BYID_FAILURE });

export const getCupByID = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getCupByIDRequest());
      const response = await axiosApi.get(`/cup/${id}`);
      dispatch(getCupByIDSuccess(response?.data?.data));
    } catch (error) {
      dispatch(getCupByIDFailure());
    }
  };
};

export const updateCupByID = (data) => {
  return async () => {
    try {
      axiosApi.put('/cup', data);
      showNotification('success', 'Successfully updated cup');
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};

export const registerOnCup = (id) => {
  return async () => {
    try {
      await axiosApi.post(`/cup/register/${id}`);
      showNotification('success', 'Successfully registered on cup');
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};

export const unregisterOnCup = (id) => {
  return async () => {
    try {
      await axiosApi.post(`/cup/unregister/${id}`);
      showNotification('success', 'Successfully unregistered from  cup');
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};

export const calculateCup = (id) => {
  return async () => {
    try {
      await axiosApi.post(`/cup/calculate/${id}`);
      showNotification('success', 'Successfully calculated cup results');
      return true;
    } catch (error) {
      return false;
    }
  };
};
