import React, { useMemo, useState } from 'react';
import Cookies from 'js-cookie';

const LocaleDate = ({ date }) => {
  const [dateLocale, setDateLocale] = useState('en-US');
  let localFromCookie = Cookies.get('i18next');

  useMemo(() => {
    if (Cookies.get('i18next') === 'en') setDateLocale('en-US');
    else if (Cookies.get('i18next') === 'fr') setDateLocale('fr-FR');
  }, [localFromCookie]);

  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  const localizationDate = date.toLocaleDateString(dateLocale, options);

  return <div className="single-race__date-title">{localizationDate}</div>;
};

export default LocaleDate;
