import {
  GET_RACES_FAILURE,
  GET_RACES_REQUEST,
  GET_RACES_SUCCESS,
  GET_SINGLE_RACE_REQUEST,
  GET_SINGLE_RACE_SUCCESS,
  GET_SINGLE_RACE_FAILURE,
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_FAILURE,
  GET_SEGMENT_REQUEST,
  GET_SEGMENT_SUCCESS,
  GET_SEGMENT_FAILURE,
  GET_ACTIVITY_BY_ID_REQUEST,
  GET_ACTIVITY_BY_ID_SUCCESS,
  GET_ACTIVITY_BY_ID_FAILURE,
  COMPLETE_RACE_REQUEST,
  COMPLETE_RACE_SUCCESS,
  COMPLETE_RACE_FAILURE,
  GET_ROUTE_REQUEST,
  GET_ROUTE_SUCCESS,
  GET_ROUTE_FAILURE,
  GET_SINGLE_ROUTE_REQUEST,
  GET_SINGLE_ROUTE_SUCCESS,
  GET_SINGLE_ROUTE_FAILURE,
} from '../actions/manageRaces/actionTypes';

const initialState = {
  managedRaces: [],
  activity: [],
  segment: [],
  activitybyId: [],
  activitybyIdLoading: false,
  segmentLoading: false,
  activityLoading: false,
  singleRace: null,
  singleRaceLoading: false,
  managedRacesLoading: false,
  missingSegment: [],
  missingSegmentLoading: [],
  route: [],
  singleRoute: {},
  routeLoading: false,
};

const managedRacesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RACES_REQUEST:
      return { ...state, managedRacesLoading: true, managedRaces: false };
    case GET_RACES_SUCCESS:
      return { ...state, managedRacesLoading: false, managedRaces: action.managedRaces };
    case GET_RACES_FAILURE:
      return { ...state, managedRacesLoading: false };
    case GET_SINGLE_RACE_REQUEST:
      return { ...state, singleRaceLoading: true, singleRace: null };
    case GET_SINGLE_RACE_SUCCESS:
      return { ...state, singleRaceLoading: false, singleRace: action.singleRace };
    case GET_SINGLE_RACE_FAILURE:
      return { ...state, singleRaceLoading: false };
    case GET_ACTIVITY_REQUEST:
      return { ...state, activityLoading: false };
    case GET_ACTIVITY_SUCCESS:
      return { ...state, activityLoading: false, activity: action.activity };
    case GET_ACTIVITY_FAILURE:
      return { ...state, activityLoading: false };
    case GET_SEGMENT_REQUEST:
      return { ...state, segmentLoading: true };
    case GET_SEGMENT_SUCCESS:
      return { ...state, segmentLoading: false, segment: [...state.segment, action.segment] };
    case GET_ROUTE_REQUEST:
      return { ...state, routeLoading: true };
    case GET_ROUTE_SUCCESS:
      return { ...state, routeLoading: false, route: [...state.route, action.route] };
    case GET_ROUTE_FAILURE:
      return { ...state, routeLoading: false };

    case GET_SINGLE_ROUTE_REQUEST:
      return { ...state, routeLoading: true, singleRoute: [] };
    case GET_SINGLE_ROUTE_SUCCESS:
      return { ...state, routeLoading: false, singleRoute: action.singleRoute };
    case GET_SINGLE_ROUTE_FAILURE:
      return { ...state, routeLoading: false };

    case 'ADD_SEGMENTS_TO_RACE':
      return { ...state, segment: [...state.segment, ...action.segment] };
    case 'EDIT_SEGMENTS_TO_RACE':
      return {
        ...state,
        singleRace: {
          ...state.singleRace,
          raceSegments: [...state.singleRace.raceSegments, ...action.segment],
        },
      };
    case GET_SEGMENT_FAILURE:
      return { ...state, segmentLoading: false };
    case 'CLEAR_SEGMENTS':
      return { ...state, segment: [] };
    case 'CLEAR_ROUTES':
      return { ...state, route: [] };
    case 'CLEAR_SINGLE_ROUTES':
      return { ...state, singleRoute: {} };
    case 'REMOVE_ROUTE':
      return { ...state, route: [...state.route.filter((item) => item.stravaId !== action.id)] };
    case 'REMOVE_SEGMENT':
      return { ...state, segment: [...state.segment.filter((item) => item.stravaId !== action.id)] };
    case 'EDIT_SEGMENT':
      return {
        ...state,
        singleRace: {
          ...state.singleRace,
          raceSegments: state.singleRace.raceSegments.filter((item) => item.segment.stravaId !== action.id),
        },
      };
    case GET_ACTIVITY_BY_ID_REQUEST:
      return { ...state, activitybyIdLoading: true, activitybyId: [] };
    case GET_ACTIVITY_BY_ID_SUCCESS:
      return { ...state, activitybyIdLoading: false, activitybyId: action.activitybyId };
    case GET_ACTIVITY_BY_ID_FAILURE:
      return { ...state, activitybyIdLoading: false };

    case COMPLETE_RACE_REQUEST:
      return { ...state, missingSegmentLoading: true, missingSegment: [] };
    case COMPLETE_RACE_SUCCESS:
      return { ...state, missingSegmentLoading: false };
    case COMPLETE_RACE_FAILURE:
      return { ...state, missingSegmentLoading: false, missingSegment: action.missingSegment };

    default:
      return { ...state };
  }
};
export default managedRacesReducer;
