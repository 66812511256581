import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  GET_USER_EMAIL_REQUEST,
  GET_USER_EMAIL_SUCCESS,
  GET_USER_EMAIL_FAILURE,
  GET_USER_INFORMATION_REQUEST,
  GET_USER_INFORMATION_SUCCESS,
  GET_USER_INFORMATION_FAILURE,
  GET_USER_BYID_REQUEST,
  GET_USER_BYID_SUCCESS,
  GET_USER_BYID_FAILURE,
  GET_USER_RACES_REQUEST,
  GET_USER_RACES_SUCCESS,
  GET_USER_RACES_FAILURE,
} from '../actions/user/actionTypes';
const initialState = {
  profile: null,
  email: null,
  loading: false,
  error: null,
  userInformation: null,
  userInformationLoading: false,
  userByID: null,
  userByIDLoading: false,
  myRaces: [],
  myRacesLoading: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, profile: action.profile };
    case LOGIN_USER_FAILURE:
      return { ...state, loading: false };
    case GET_USER_EMAIL_REQUEST:
      return { ...state, loading: true };
    case GET_USER_EMAIL_SUCCESS:
      return { ...state, loading: false, email: action.email };
    case GET_USER_EMAIL_FAILURE:
      return { ...state, loading: false };
    case GET_USER_INFORMATION_REQUEST:
      return { ...state, userInformationLoading: true };
    case GET_USER_INFORMATION_SUCCESS:
      return { ...state, userInformationLoading: false, userInformation: action.userInformation };
    case GET_USER_INFORMATION_FAILURE:
      return { ...state, userInformationLoading: false };

    case GET_USER_RACES_REQUEST:
      return { ...state, myRacesLoading: true };
    case GET_USER_RACES_SUCCESS:
      return { ...state, myRacesLoading: false, myRaces: action.myRaces };
    case GET_USER_RACES_FAILURE:
      return { ...state, myRacesLoading: false };

    case GET_USER_BYID_REQUEST:
      return { ...state, userByIDLoading: true };
    case GET_USER_BYID_SUCCESS:
      return { ...state, userByIDLoading: false, userByID: action.userByID };
    case GET_USER_BYID_FAILURE:
      return { ...state, userByIDLoading: false };
    case LOGOUT_USER:
      return initialState;
    default:
      return { ...state };
  }
};

export default userReducer;
