import { Modal, Table } from 'antd';
import DetailedInfo from './DetailedInfo';
import React, { useState } from 'react';
import StravaLink from '../StravaLink/StravaLink';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const SegmentsTab = ({ segments, detail }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [detailId, setDetailId] = useState(null);

  const openModalHandler = (id) => {
    setDetailId(id);
    setShowModal((prev) => !prev);
  };
  const closeModalHandler = (id) => {
    setDetailId(null);
    setShowModal((prev) => !prev);
  };
  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '300px',
      render: (_, record) => {
        return <>{record?.segment.name}</>;
      },
    },

    {
      title: t('tableColumns.distance'),
      dataIndex: 'distance',
      key: 'distance',
      render: (_, record) => <>{Number(record?.segment.distance / 1000).toFixed(2)} km</>,
      responsive: ['md'],
    },
    {
      title: t('tableColumns.elevation'),
      dataIndex: 'elevation',
      key: 'distance',
      render: (_, record) => <>{record?.segment.elevation.toFixed(0)} m</>,
      responsive: ['lg'],
    },
    {
      title: t('tableColumns.stravaId'),
      dataIndex: 'distance',
      key: 'distance',
      render: (_, record) => <StravaLink id={record?.segment.stravaId} type="segments" />,
    },
    {
      title: t('buttons.details'),
      dataIndex: 'details',
      key: 'details',

      render: (_, record) => {
        return (
          <>
            {detail.raceUsers.some((item) => item.finalResult !== null) && (
              <>
                <button className="details-btn" onClick={() => openModalHandler(record?.segment.stravaId)}>
                  {t('buttons.details')}
                </button>
              </>
            )}
          </>
        );
      },
      responsive: ['lg', 'md'],
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={segments} pagination={segments?.length >= 20} rowKey={'stravaId'} />
      <Modal open={showModal} onCancel={closeModalHandler} destroyOnClose={true} width={'700px'} footer={null}>
        <div className="segments-details-header">
          <span>{t('tableColumns.name')}</span>
          <span>{t('tableColumns.speed')}</span>
          <span>{t('time')}</span>
          <span>{t('tableColumns.rank')}</span>
          <span>{t('tableColumns.winner')}</span>
        </div>
        <DetailedInfo id={detailId} detail={detail} />
      </Modal>
    </>
  );
};

export default SegmentsTab;
