import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Spin, Table } from 'antd';
import Input from 'antd/lib/input/Input';
import { useTranslation } from 'react-i18next';
import '../../../utils/i18next';
import '../styles.scss';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRace, getAllRaces } from '../../../store/actions/manageRaces/manageRaces';
import moment from 'moment';
import './styles.scss';

const ManageRaces = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.managedRaces?.managedRacesLoading);
  const races = useSelector((state) => state.managedRaces?.managedRaces?.data?.items);

  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const deleteRaceHandler = async (id) => {
    const res = await dispatch(deleteRace(id));
    if (res) dispatch(getAllRaces());
  };

  const columns = [
    {
      title: `${t('tableColumns.name')}`,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: `${t('tableColumns.creator')}`,
      dataIndex: 'creator',
      key: 'creator',
    },
    {
      title: `${t('tableColumns.startActivity')}`,
      dataIndex: 'startDate',
      key: 'startDate',
      width: '145px',
      render: (_, record) => <>{moment(record.startDate).format('DD-MM-YY')}</>,
    },
    {
      title: `${t('tableColumns.endActivity')}`,
      dataIndex: 'endDate',
      key: 'endDate',
      render: (_, record) => {
        return <>{Boolean(record.endDate) ? moment(record.endDate).format('DD-MM-YY') : '-'}</>;
      },
    },
    {
      title: `${t('tableColumns.closeDate')}`,
      dataIndex: 'closeDate',
      key: 'closeDate',
      render: (_, record) => {
        return <>{Boolean(record.closeDate) ? moment(record.closeDate).format('DD-MM-YY') : '-'}</>;
      },
    },
    {
      title: `${t('tableColumns.distance')}`,
      dataIndex: 'distance',
      key: 'distance',
      render: (_, record) => <>{Number(record.distance / 1000).toFixed(2)} km </>,
    },
    {
      title: `${t('tableColumns.elevation')}`,
      dataIndex: 'elevation',
      key: 'elevation',
      render: (_, record) => <>{record.elevation.toFixed(0)} m </>,
    },
    {
      title: `${t('tableColumns.segments')}`,
      dataIndex: 'segmentsCount',
      key: 'segments',
    },
    {
      title: `${t('tableColumns.racers')}`,
      dataIndex: 'racers',
      key: 'racers',
      render: (_, record) => (
        <>
          {record.completedCount} / {record.registeredCount}
        </>
      ),
    },
    {
      title: `${t('tableColumns.actions')}`,
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => (
        <div className="table-actions">
          <Link className="edit-btn" to={`/edit/race/${record.id}`} style={{ color: '#000' }}>
            <EditOutlined className="ic-antd edit" />
          </Link>
          <DeleteOutlined className="ic-antd delete" onClick={() => deleteRaceHandler(record.id)} />
        </div>
      ),
    },
  ];

  const onChangeFrom = (_, dateString) => {
    if (_) setFrom(dateString);
    else setFrom(null);
  };
  const onChangeTo = (_, dateString) => {
    if (_) {
      setTo(dateString);
    } else setTo(null);
  };

  const searchHandler = async () => {
    const res = await dispatch(getAllRaces(name, from, to));

    if (res) {
      setName('');
      setFrom(null);
      setTo(null);
    }
  };

  const resetHandler = () => {
    setName(null);
    setFrom(null);
    setTo(null);
    dispatch(getAllRaces(name, from, to));
  };

  useEffect(() => {
    dispatch(getAllRaces());
  }, [dispatch]);
  return (
    <div className="content">
      <h2 className="title">{t('navigation.manageRaces')}</h2>
      <div className="search-block">
        <div className="input-section">
          <Input
            placeholder="Name"
            className="input-field"
            value={name}
            onChange={(e) => setName(e.target.value)}
            size="large"
          />
          <DatePicker placeholder="From" className="date-field" onChange={onChangeFrom} size="large" />
          <DatePicker placeholder="to" className="date-field" onChange={onChangeTo} size="large" />
          <Button className="search-btn" onClick={searchHandler} size="large">
            <SearchOutlined />
            {t('buttons.search')}
          </Button>
          <Button className="search-btn" onClick={resetHandler} size="large">
            {t('buttons.reset')}
          </Button>
        </div>
        <div>
          <Button size="large">
            <Link to="/races/new">{t('buttons.createRace')}</Link>
          </Button>
        </div>
      </div>

      <Spin spinning={loading}>
        <div className="races-table">{races && <Table dataSource={races} columns={columns} rowKey={'id'} />}</div>
      </Spin>
    </div>
  );
};

export default ManageRaces;
