import React, { useEffect } from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin } from 'antd';
import SingleCup from '../../components/SingleCup/SingleCup';
import { getMyCups } from '../../store/actions/dashboard/dashboardActions';
import moment from 'moment';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import NoResult from '../../components/NoResult/NoResult';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const myCups = useSelector((state) => state.dashboard?.myCups);
  const loading = useSelector((state) => state.dashboard?.myCupsLoading);
  useEffect(() => {
    dispatch(getMyCups(true));
  }, [dispatch]);

  return (
    <div className="content">
      <Spin size="large" style={{ textAlign: 'center' }} spinning={loading}>
        <div className="search-cups__section">
          <Button size="large" onClick={() => dispatch(getMyCups(null))}>
            {t('buttons.searchCups')}
          </Button>
          <Button size="large" onClick={() => dispatch(getMyCups(true))}>
            <ReloadOutlined />
            {t('titles.myCups')}
          </Button>
        </div>

        <div className="all-cups__container">
          {myCups.length ? (
            <>
              {myCups.map((item) => {
                let today = '';
                let end = '';
                if (item?.endDate) {
                  today = moment(new Date()).format('YYYY-MM-DD');
                  end = moment(item?.endDate).format('YYYY-MM-DD');
                }

                return (
                  <SingleCup
                    key={item?.id}
                    name={item?.name}
                    creator={item?.creator}
                    description={item?.description}
                    startDate={item?.startDate}
                    endDate={item?.endDate}
                    closeDate={item?.closeDate}
                    totalRacers={item?.registeredCount}
                    registeredCount={item?.registeredCount}
                    id={item?.id}
                    myRank={item?.myRank}
                    finished={today > end}
                    circleId={item?.circle?.id}
                    circleName={item?.circle?.name}
                    racesCount={item?.racesCount}
                    usersCount={item?.registeredCount}
                    published={item?.isPublished}
                    showImg={item.racesCount !== 0}
                  />
                );
              })}
            </>
          ) : (
            <> {!loading && <NoResult text={t('titles.noCupsFound')} />}</>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default Dashboard;
