import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const UserCirclesTab = ({ myCircles }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <Link to={`/details/circle/${record.id}`}>{record?.name}</Link>,
    },
    {
      title: t('tableColumns.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <>
          {record?.role?.localizationKey === 'REQUESTER'
            ? t('requester')
            : record?.role?.localizationKey === 'SUBSCRIBER'
            ? t('subscriber')
            : t('owner')}
        </>
      ),
    },
  ];
  return <Table dataSource={myCircles} columns={columns} />;
};

export default UserCirclesTab;
