import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Card, Tooltip } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  deleteTeam,
  getTeams,
  joinTeam,
  leaveTeam,
  teamInviteAccept,
  teamInviteReject,
} from '../../store/actions/team/teamActions';
import './styles.scss';
import { showNotification } from '../../utils/showNotification';
import { useTranslation } from 'react-i18next';

const TeamItem = ({
  id,
  name,
  shortName,
  membersCount,
  allowJoin,
  allowLeave,
  firstName,
  lastName,
  allowtoInvite,
  showEditGroup,
}) => {
  const { t } = useTranslation();
  const text = () => (
    <div className="race-header__info">
      <div className="col-1">
        <div className="names">
          {name?.length >= 20 ? (
            <Tooltip placement="bottom" title={name}>
              <span className="m-b-10 race-name">{name}</span>
            </Tooltip>
          ) : (
            <>
              <span className="m-b-10 race-name">{name}</span>
            </>
          )}

          <p className="circle-name">
            {t('owner')} {firstName} {lastName}
          </p>
        </div>

        <div className="team-buttons">
          <CopyToClipboard
            text={`${window.location.hostname}/team/details/${id}`}
            onCopy={() => showNotification('success', 'copied team url')}
          >
            <CopyOutlined className="m-r-10" />
          </CopyToClipboard>
          <Link className="details-btn" to={`/team/details/${id}`}>
            {t('buttons.details')}
          </Link>
        </div>
      </div>
    </div>
  );
  const dispatch = useDispatch();
  const joinTeamHandler = async () => {
    const response = await dispatch(joinTeam(id));
    if (response) {
      dispatch(getTeams());
    }
  };
  const leaveTeamHandler = async () => {
    const response = await dispatch(leaveTeam());
    if (response) {
      dispatch(getTeams());
    }
  };

  const acceptInviteHandler = async () => {
    const response = await dispatch(teamInviteAccept(id));
    if (response) {
      dispatch(getTeams());
    }
  };
  const rejecttInviteHandler = async () => {
    const response = await dispatch(teamInviteReject(id));
    if (response) {
      dispatch(getTeams());
    }
  };

  const deleteTeamHandler = async (id) => {
    const response = await dispatch(deleteTeam(id));
    if (response) dispatch(getTeams());
  };
  return (
    <Card style={{ width: '380px' }} title={text()}>
      <div className="card-content">
        <div>
          <p className="m-b-15">{shortName}</p>
          <p className="m-b-15">
            {t('subscriber')} : {membersCount}
          </p>
        </div>

        <div className="item__btn-group m-b-20">
          {allowtoInvite && (
            <div className="m-t-10">
              <Button className="sub m-r-5" onClick={acceptInviteHandler}>
                Accept Invite
              </Button>
              <Button className="unsub" onClick={rejecttInviteHandler}>
                Reject Invite
              </Button>
            </div>
          )}
          {allowJoin && !allowtoInvite && (
            <Button className="sub" onClick={joinTeamHandler}>
              {t('buttons.join')}
            </Button>
          )}
          {allowLeave && !allowtoInvite && (
            <Button className="unsub" onClick={leaveTeamHandler}>
              {t('buttons.leave')}
            </Button>
          )}

          {showEditGroup && (
            <div className="table-actions">
              <Link className="edit-btn" to={`/team/edit/${id}`} style={{ color: '#000' }}>
                <EditOutlined className="ic-antd edit" />
              </Link>
              <DeleteOutlined className="ic-antd delete" onClick={() => deleteTeamHandler(id)} />
              <Link className="edit-btn" to={`/team/users/${id}`}>
                Users
              </Link>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default TeamItem;
