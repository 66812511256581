import { Button, Input, Select, Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getSingleAthlete, getRoles, editAthlete } from '../../../store/actions/athletes/athletesActions';
import './styles.scss';
const { Option } = Select;

const EditUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const athlete = useSelector((state) => state.athletes?.singleAthlete);
  const roles = useSelector((state) => state.athletes?.roles);
  const loading = useSelector((state) => state.athletes?.singleAthletLoading);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');

  const userRolesSelect = useMemo(() => {
    const filteredRolesDescription = (originalRoles) => {
      const arr = [];
      const filteredRole = originalRoles.filter((item) => athlete?.role?.id.includes(item.id));
      filteredRole.forEach((item) => {
        arr.push(item.id);
      });
      return arr;
    };
    return (
      !loading && (
        <Select
          allowClear
          defaultValue={() => filteredRolesDescription(roles)}
          style={{ width: '100%' }}
          onChange={(e) => setRole(e)}
        >
          {roles.map((elem) => (
            <Option key={elem.id}>{elem.description}</Option>
          ))}
        </Select>
      )
    );
  }, [roles, loading, athlete?.role?.id]);

  const editUserHandler = async () => {
    const reponse = await dispatch(
      editAthlete({
        id,
        firstName,
        lastName,
        roleId: role,
      })
    );
    if (reponse) navigate('/manage/users');
  };

  useEffect(() => {
    dispatch(getSingleAthlete(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  useEffect(() => {
    if (athlete) {
      setFirstName(athlete.firstName);
      setLastName(athlete.lastName);
      setEmail(athlete.email);
      setRole(athlete.role?.id);
    }
  }, [athlete]);

  return (
    <div className="content">
      <Spin spinning={loading}>
        {!loading ? (
          <h2 className="title">
            Edit
            <span className="edit-text__title">
              {athlete?.firstName} {athlete?.lastName}
            </span>
          </h2>
        ) : (
          <h2 className="title">Edit User</h2>
        )}
        <div className="edit-element__form">
          <Input
            value={firstName}
            placeholder="First name"
            onChange={(e) => setFirstName(e.target.value)}
            size="large"
          />
          <Input value={lastName} placeholder="Last name" onChange={(e) => setLastName(e.target.value)} size="large" />
          <Input disabled value={email} placeholder="Email" size="large" />
          {userRolesSelect}
          <div className="edit-element__form-buttons">
            <Button color="primary" onClick={editUserHandler}>
              Save
            </Button>
            <Link to="/manage/users">
              <Button danger>Cancel</Button>
            </Link>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default EditUser;
