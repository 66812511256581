import { DeleteOutlined, EditOutlined } from '@ant-design/icons/lib/icons';
import { Button, Spin, Table } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../appContext';
import { deleteCup, getAllCups } from '../../../store/actions/cup/cupActions';
import { dateFormat } from '../../../utils/constants/constants';

const ManageCups = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAdmin, isRaceOrganazier } = useContext(AppContext);
  const cups = useSelector((state) => state.cup?.cups);
  const loading = useSelector((state) => state.cup?.cupsLoading);

  const deleteCupHandler = async (id) => {
    const response = await dispatch(deleteCup(id));
    if (response) dispatch(getAllCups());
  };

  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '180px',
      render: (_, record) => <>{record?.name}</>,
    },
    {
      title: t('tableColumns.description'),
      dataIndex: 'description',
      key: 'description',
      width: '180px',
      render: (_, record) => <>{record?.description}</>,
    },
    {
      title: `${isAdmin ? t('tableColumns.owner') : ''}`,
      dataIndex: 'owner',
      key: 'owner',
      render: (_, record) => <>{isAdmin && record.creator}</>,
    },
    {
      title: 'Registered Count',
      dataIndex: 'name',
      key: 'name',
      width: '80px',
      render: (_, record) => <>{record?.registeredCount}</>,
    },
    {
      title: 'Race Count',
      dataIndex: 'name',
      key: 'name',
      width: '80px',
      render: (_, record) => <>{record?.racesCount}</>,
    },
    {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
      width: '120px',
      render: (_, record) => <>{record?.startDate ? moment(record?.startDate).format(dateFormat) : '-'}</>,
    },
    {
      title: 'End',
      dataIndex: 'end',
      key: 'end',
      width: '120px',
      render: (_, record) => <>{record?.endDate ? moment(record?.endDate).format(dateFormat) : '-'}</>,
    },
    {
      title: 'Close',
      dataIndex: 'close',
      key: 'close',
      width: '120px',
      render: (_, record) => <>{record?.closeDate ? moment(record?.closeDate).format(dateFormat) : '-'}</>,
    },
    {
      title: t('tableColumns.actions'),
      dataIndex: 'Actions',
      key: 'Actions',
      width: '80px',
      render: (_, record) => (
        <div className="circle-table__actions">
          <Link style={{ color: '#000' }} to={`/cup/edit/${record.id}`}>
            <EditOutlined className="ic-antd edit" />
          </Link>
          {record?.role?.localizationKey !== 'OWNER' && (
            <>
              <DeleteOutlined className="ic-antd delete" onClick={() => deleteCupHandler(record.id)} />
            </>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAllCups());
  }, [dispatch]);

  return (
    <div className="content">
      <h2 className="title">{t('navigation.manageCups')}</h2>
      <div className="m-t-30">
        {(isAdmin || isRaceOrganazier) && (
          <Button>
            <Link to="/cup/new">{t('buttons.createCup')}</Link>
          </Button>
        )}
      </div>
      <Spin spinning={loading} size="large">
        <div className="m-t-40">
          <Table columns={columns} dataSource={cups} rowKey={'id'} />
        </div>
      </Spin>
    </div>
  );
};

export default ManageCups;
