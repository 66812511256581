import { Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Members = ({ members, ownerId = null }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('tableColumns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '220px',
      render: (_, record) => (
        <div>
          {record.firstName} {record.lastName}
        </div>
      ),
    },
    {
      title: t('tableColumns.members'),
      dataIndex: 'Role',
      key: 'Role',
      width: '220px',
      render: (_, record) => <div>{ownerId && <>{ownerId === record.id ? t('owner') : t('subscriber')}</>}</div>,
    },
  ];
  return <Table columns={columns} dataSource={members} rowKey={'stravaId'} />;
};

export default Members;
