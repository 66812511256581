import { Button, Spin } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppContext } from '../../appContext';
import SingleCup from '../../components/SingleCup/SingleCup';
import { getAllCups } from '../../store/actions/cup/cupActions';
import './styles.scss';

const Cups = () => {
  const { t } = useTranslation();
  const { isAdmin, isRaceOrganazier } = useContext(AppContext);
  const dispatch = useDispatch();
  const cups = useSelector((state) => state.cup?.cups);
  const loading = useSelector((state) => state.cup?.cupsLoading);

  useEffect(() => {
    dispatch(getAllCups());
  }, [dispatch]);
  return (
    <div className="content">
      <h2 className="title m-b-30">{t('navigation.cups')}</h2>
      {(isAdmin || isRaceOrganazier) && (
        <Button className="m-b-30">
          <Link to="/cup/new">{t('buttons.createCup')}</Link>
        </Button>
      )}
      <Spin spinning={loading}>
        {cups && (
          <div className="all-cups__container">
            {cups.map((item) => {
              let today = '';
              let end = '';
              if (cups?.endDate) {
                today = moment(new Date()).format('YYYY-MM-DD');
                end = moment(cups?.endDate).format('YYYY-MM-DD');
              }

              return (
                <SingleCup
                  key={item?.id}
                  name={item?.name}
                  creator={item?.creator}
                  description={item?.description}
                  endDate={item?.endDate}
                  closeDate={item?.closeDate}
                  totalRacers={item?.registeredCount}
                  registeredCount={item?.registeredCount}
                  id={item?.id}
                  myRank={item?.myRank}
                  finished={today > end}
                  circleId={item?.circle?.id}
                  circleName={item?.circle?.name}
                  racesCount={item?.racesCount}
                  usersCount={item?.registeredCount}
                  published={item?.isPublished}
                  showImg={item.racesCount !== 0}
                />
              );
            })}
          </div>
        )}
      </Spin>
    </div>
  );
};

export default Cups;
