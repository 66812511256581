import { Button, Spin, Table, Collapse, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTeamId } from '../../../store/actions/team/teamActions';
import Invites from './Tables/Invites';
import Joiners from './Tables/Joiners';
import Members from './Tables/Members';
import NoResult from '../../../components/NoResult/NoResult';
import './styles.scss';
import InviteUsers from '../../TeamDetail/InviteUsers';
const { Panel } = Collapse;
const TeamMembers = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const singleTeam = useSelector((state) => state.team?.teamId);
  const loading = useSelector((state) => state.team?.teamIdLoading);
  const userId = useSelector((state) => state.user?.profile?.profile?.nameid);
  const dispatch = useDispatch();
  const [inviteModal, setInviteModal] = useState(false);

  const openModalHandler = () => {
    setInviteModal((prev) => !prev);
  };

  const rejectHandler = () => {};
  const approveHandler = () => {};

  useEffect(() => {
    if (id) {
      dispatch(getTeamId(id));
    }
  }, [dispatch, id]);

  return (
    <div className="content">
      <Spin size="large" spinning={loading}>
        {singleTeam && (
          <>
            <h1 className="title edit-text__title">{singleTeam?.name}</h1>
            <h2 className="title edit-text__title">{singleTeam?.shortName}</h2>
            {singleTeam?.owner?.id === userId && <Button onClick={openModalHandler}>Invite Users</Button>}
            <div className="m-t-35">
              <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
                <Panel header={t('Members')} key={1}>
                  {singleTeam?.members?.length !== 0 ? (
                    <Members id={id} members={singleTeam?.members} />
                  ) : (
                    <NoResult text="No Members" />
                  )}
                </Panel>
              </Collapse>
              <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
                <Panel header={'Joiners'} key={1}>
                  {singleTeam?.joiners?.length !== 0 ? (
                    <Joiners teamId={id} joiners={singleTeam?.joiners} />
                  ) : (
                    <NoResult text="No Joiners" />
                  )}
                </Panel>
              </Collapse>
              <Collapse bordered={false} defaultActiveKey={1} className="m-b-40">
                <Panel header={t('Invites')} key={1}>
                  {singleTeam?.invites?.length !== 0 ? (
                    <Invites teamId={id} invites={singleTeam?.invites} />
                  ) : (
                    <NoResult text="No Invites" />
                  )}
                </Panel>
              </Collapse>
            </div>
          </>
        )}
      </Spin>
      <Modal open={inviteModal} onCancel={openModalHandler} footer={null} style={{ height: '500px' }}>
        <InviteUsers />
      </Modal>
    </div>
  );
};

export default TeamMembers;
