import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityById } from '../../../store/actions/manageRaces/manageRaces';
import _ from 'lodash';
import './styles.scss';
import { Spin } from 'antd';
import { showNotification } from '../../../utils/showNotification';

const EditActivityItem = ({ id, close }) => {
  const dispatch = useDispatch();
  const activitySegments = useSelector((state) => state.managedRaces?.activitybyId);
  const activitybyIdLoading = useSelector((state) => state.managedRaces?.activitybyIdLoading);
  const [ascending, setAscending] = useState(0);
  // const [descending, setDescending] = useState(0);
  const [distance, setDistance] = useState(0);
  const [result, setResult] = useState([]);

  const chooseSegment = async (id) => {
    const findedSegment = activitySegments?.activitySegments.find((item) => item.segment.stravaId === id);
    const formattedSegment = {
      segment: {
        name: findedSegment.name,
        stravaId: findedSegment.segment?.stravaId,
        distance: findedSegment.distance,
        elevation: findedSegment.segment.elevation,
      },
      coefficient: 1,
    };
    if (formattedSegment) {
      setResult(() => [...result, formattedSegment]);
    }
  };

  const removeHandler = (id) => {
    setResult((element) => element.filter((item) => item.segment.stravaId !== id));
  };

  useEffect(() => {
    const totalAscending = result.reduce((acc, curr) => acc + curr.segment.elevation, 0);
    // const totalDescengind = result.reduce((acc, curr) => acc + curr, 0);
    const totalDistance = result.reduce((acc, curr) => acc + curr.segment.distance / 1000, 0);
    setAscending(totalAscending);
    setDistance(totalDistance);
  }, [result]);

  const checkboxHandler = (cheked, id) => {
    if (cheked) {
      chooseSegment(id);
    } else {
      removeHandler(id);
    }
  };

  const addSegmentsToRace = () => {
    dispatch({ type: 'EDIT_SEGMENTS_TO_RACE', segment: result });
    showNotification('success', 'Successfully added segments');
    close();
  };

  useEffect(() => {
    id && dispatch(getActivityById(id));
  }, [dispatch, id]);

  return (
    <div className="activity-item-wrapper">
      <Spin spinning={activitybyIdLoading}>
        <div className="activity-item-container">
          {activitybyIdLoading && <h2 className="title">Loading</h2>}
          <h2 className="title">{activitySegments.name}</h2>
          {activitySegments?.activitySegments && (
            <div className="activity-items">
              {activitySegments?.activitySegments.map((item) => {
                return (
                  <div className="item" key={item.stravaId}>
                    <span>{item.name}</span>
                    <span>{Number(item.distance / 1000).toFixed(2)} km</span>
                    <span>{item.segment.elevation.toFixed(0)} m</span>
                    <input type="checkbox" onChange={(e) => checkboxHandler(e.target.checked, item.segment.stravaId)} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Spin>

      {!_.isEmpty(result) && (
        <div className="result-container">
          <span>Segments: {result.length}</span>
          <span>Ascending: {ascending.toFixed(0)} m</span>
          {/* <span>Dscending: {result.desc} m</span> */}
          <span>Distance: {distance.toFixed(1)} km</span>
          <button onClick={addSegmentsToRace}>Select</button>
        </div>
      )}
    </div>
  );
};

export default EditActivityItem;
