import { Button, Input, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CircleItem from '../../components/CircleItem/CircleItem';
import { getAllCircles } from '../../store/actions/circle/circleActions';
const Circles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allCircles = useSelector((state) => state.circle?.allCircles);
  const allCirclesLoading = useSelector((state) => state.circle?.allCirclesLoading);

  const [circleName, setCircleName] = useState('');

  const searchHandler = () => {
    dispatch(getAllCircles(circleName));
  };
  const resetHandler = () => {
    dispatch(getAllCircles());
    setCircleName('');
  };
  useEffect(() => {
    dispatch(getAllCircles());
  }, [dispatch]);

  return (
    <div className="content">
      <Spin spinning={allCirclesLoading}>
        <h2 className="title m-b-40">{t('navigation.circles')}</h2>
        <div className="search-circle__form">
          <Input
            placeholder="Circle name"
            onChange={(e) => setCircleName(e.target.value)}
            value={circleName}
            style={{ width: '70%' }}
          />
          <Button onClick={searchHandler} className="m-l-20">
            Search
          </Button>
          <Button onClick={resetHandler} className="m-l-20">
            Reset
          </Button>
        </div>
        <div className="m-t-30 m-b-30">
          <Link to="/circles-map" style={{ fontSize: '16px' }}>
            {t('navigation.circlesOnMap')}
          </Link>
        </div>
        <div className="m-t-50 circle-items__container">
          {allCircles.map((item) => (
            <CircleItem
              key={item.id}
              name={item.name}
              isPublic={item.isPublic}
              id={item.id}
              membersCount={item.membersCount}
              description={item.description}
              racesCount={item.racesCount}
              allowSub={item.allowToSubscribe}
              allowUnSub={item.allowToUnsubscribe}
              owner={item.owner}
              cupCount={item.cupsCount}
            />
          ))}
        </div>
      </Spin>
    </div>
  );
};

export default Circles;
