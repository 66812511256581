import { Button, Card } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllCircles, subscribeCircle, unsubscribeCircle } from '../../store/actions/circle/circleActions';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const CircleItem = ({
  name,
  description,
  isPublic,
  membersCount,
  racesCount,
  id,
  allowSub,
  allowUnSub,
  owner,
  cupCount,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const subscribeHandler = async () => {
    const response = await dispatch(subscribeCircle(id));
    if (response) dispatch(getAllCircles());
  };

  const unsubscribeHandler = async () => {
    const response = await dispatch(unsubscribeCircle(id));
    if (response) dispatch(getAllCircles());
  };

  const headerInformation = () => (
    <div className="race-header__info">
      <div className="circle-names">
        <p className="m-b-5">{name}</p>
        <>
          {isPublic ? (
            <span style={{ fontWeight: 'bold', color: '#000' }}>Public</span>
          ) : (
            <span style={{ fontWeight: 'bold', color: '#000' }}>Private</span>
          )}
        </>
      </div>
      <div className="col-2">
        <Link to={`/details/circle/${id}`} className="circle-details details-btn">
          {t('buttons.details')}
        </Link>
      </div>
    </div>
  );
  return (
    <Card title={headerInformation()} style={{ width: '350px' }}>
      <div style={{ display: 'flex', gap: '5px' }}>
        {t('tableColumns.owner')}: <p className="owner__text">{owner}</p>
      </div>

      <p>{description}</p>

      {membersCount > 0 && (
        <p>
          {t('tableColumns.members')} : {membersCount}
        </p>
      )}

      <div className="btn-items">
        <div>
          {racesCount > 0 && (
            <p>
              {t('navigation.races')} : {racesCount}
            </p>
          )}

          {cupCount > 0 && (
            <p>
              {t('titles.cups')} : {cupCount}
            </p>
          )}
        </div>

        <div className="circle-item_btn-group m-l-10 m-t-10">
          {allowSub && isPublic && (
            <Button className="sub" onClick={subscribeHandler}>
              {t('buttons.subscribe')}
            </Button>
          )}

          {allowSub && !isPublic && (
            <Button className="sub" onClick={subscribeHandler}>
              {t('buttons.requestForSub')}
            </Button>
          )}

          {allowUnSub && isPublic && (
            <Button className="unsub" onClick={unsubscribeHandler}>
              {t('buttons.unsubscribe')}
            </Button>
          )}

          {allowUnSub && !isPublic && (
            <Button className="unsub" onClick={unsubscribeHandler}>
              {t('buttons.cancelRequest')}
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

export default CircleItem;
