import axiosApi from '../../../utils/axiosApi';
import {
  GET_CIRCLES_REQUEST,
  GET_CIRCLES_SUCCESS,
  GET_CIRCLES_FAILURE,
  GET_SINGLE_CIRCLE_REQUEST,
  GET_SINGLE_CIRCLE_SUCCESS,
  GET_SINGLE_CIRCLE_FAILURE,
  GET_ALL_CIRCLE_REQUEST,
  GET_ALL_CIRCLE_SUCCESS,
  GET_ALL_CIRCLE_FAILURE,
} from './actionTypes';
import { showNotification } from '../../../utils/showNotification';

const getCirclesRequest = () => ({ type: GET_CIRCLES_REQUEST });
const getCirclesSuccess = (managedCircles) => ({ type: GET_CIRCLES_SUCCESS, managedCircles });
const getCirclesFailure = () => ({ type: GET_CIRCLES_FAILURE });

export const getCircles = (managed = true) => {
  return async (dispatch) => {
    try {
      // добавить в парамсы ManagedOnly:TRUE
      dispatch(getCirclesRequest());
      const response = await axiosApi.get(`/circle?ManagedOnly=${managed}`);
      dispatch(getCirclesSuccess(response.data?.data));
      return true;
    } catch (error) {
      dispatch(getCirclesFailure());
      return false;
    }
  };
};

export const getMyCircles = (managed = true) => {
  return async (dispatch) => {
    try {
      // добавить в парамсы ManagedOnly:TRUE
      dispatch(getCirclesRequest());
      const response = await axiosApi.get(`/circle?SubscribedOnly=${managed}`);
      dispatch(getCirclesSuccess(response.data?.data));
      return true;
    } catch (error) {
      dispatch(getCirclesFailure());
      return false;
    }
  };
};

const getSingleCircleRequest = () => ({ type: GET_SINGLE_CIRCLE_REQUEST });
const getSingleCircleSuccess = (singleCircle) => ({ type: GET_SINGLE_CIRCLE_SUCCESS, singleCircle });
const getSingleCircleFailure = () => ({ type: GET_SINGLE_CIRCLE_FAILURE });

export const getCircleByID = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getSingleCircleRequest());
      const response = await axiosApi.get(`/circle/${id}`);
      dispatch(getSingleCircleSuccess(response.data?.data));
    } catch (error) {
      dispatch(getSingleCircleFailure());
    }
  };
};

export const createCircle = (data) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.post('/circle', data);
      showNotification('success', response?.data.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return true;
    }
  };
};

export const addNewRace = (raceData) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.post('/circle', raceData);
      showNotification('success', response.data?.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data?.error ? error.response?.data?.error : 'All fields are required');

      return false;
    }
  };
};

export const updateCircle = (data) => {
  return async () => {
    try {
      const response = await axiosApi.put('/circle', data);
      showNotification('success', response.data?.message);
    } catch (error) {
      showNotification('error', error.response?.data.error);
    }
  };
};

const getAllCircleRequest = () => ({ type: GET_ALL_CIRCLE_REQUEST });
const getAllCircleSuccess = (allCircles) => ({ type: GET_ALL_CIRCLE_SUCCESS, allCircles });
const getAllCircleFailure = () => ({ type: GET_ALL_CIRCLE_FAILURE });

export const getAllCircles = (name = null) => {
  return async (dispatch) => {
    try {
      dispatch(getAllCircleRequest());
      const response = await axiosApi.get('/circle', {
        params: {
          Name: name,
        },
      });
      dispatch(getAllCircleSuccess(response?.data?.data.items));
    } catch (error) {
      dispatch(getAllCircleFailure());
    }
  };
};

export const deleteCircles = (id) => {
  return async () => {
    try {
      const response = await axiosApi.delete('/circle', {
        params: {
          id,
        },
      });
      showNotification('success', response.data?.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);

      return false;
    }
  };
};

export const approveCircle = (id, userId) => {
  return async () => {
    try {
      const response = await axiosApi.post(`/circle/approve?id=${id}&userId=${userId}`);

      showNotification('success', response?.data?.message);
      return true;
    } catch (error) {
      showNotification('error', error?.response?.data?.error);
      return false;
    }
  };
};
export const rejectCircle = (id, userId) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.post(`/circle/reject?id=${id}&userId=${userId}`);
      showNotification('success', response?.data?.message);
      return true;
    } catch (error) {
      showNotification('error', error?.response?.data?.error);
      return false;
    }
  };
};

export const subscribeCircle = (id) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.post(`/circle/subscribe/${id}`);
      showNotification('success', response?.data?.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};
export const unsubscribeCircle = (id) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.post(`/circle/unsubscribe/${id}`);
      showNotification('success', response?.data?.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};
